import styled from 'styled-components'

const StyledImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`

export const Image = ({ channel }: any) => {
  if (!channel) return null
  return <StyledImage src={channel} alt='' />
}
