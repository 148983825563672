import React from 'react'
import styled, { css } from 'styled-components'

const baseStyle = css`
  letter-spacing: 0px;
  font-family: 'Inter', sans-serif;
  margin: 0;
  color: ${({ theme }) => (theme.isDark ? theme.colors.white.core : theme.colors.black.core)};
`

const HeadingOne = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.5rem;
  ${baseStyle}
`
const HeadingTwo = styled.h2`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
  ${baseStyle}
`
const HeadingThree = styled.h3`
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5rem;
  ${baseStyle}
`
const HeadingFour = styled.h4`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25rem;
  ${baseStyle}
`
const SubHeading = styled.p`
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
  ${baseStyle}
  color: ${({ theme }) => (theme.isDark ? theme.colors.black[200] : theme.colors.black[700])};
`

interface HeadingProps {
  children: React.ReactNode
  type: '1' | '2' | '3' | '4' | 'sub'
  className?: string
  title?: string
}

export const Heading = ({ children, type, className, title }: HeadingProps) => {
  switch (type) {
    case '1':
      return (
        <HeadingOne className={className} title={title}>
          {children}
        </HeadingOne>
      )
    case '2':
      return (
        <HeadingTwo className={className} title={title}>
          {children}
        </HeadingTwo>
      )
    case '3':
      return (
        <HeadingThree className={className} title={title}>
          {children}
        </HeadingThree>
      )
    case '4':
      return (
        <HeadingFour className={className} title={title}>
          {children}
        </HeadingFour>
      )
    case 'sub':
      return (
        <SubHeading className={className} title={title}>
          {children}
        </SubHeading>
      )
    default:
      return null
  }
}
