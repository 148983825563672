import { useTheme } from 'styled-components'

import { useFillColor, useSize } from './IconHooks'

interface IClose extends Omit<TiltifyIcon, 'fillType'> {
  fillType?:
    | 'black.50'
    | 'error.core'
    | 'blue.core'
    | 'blue.200'
    | 'black.400'
    | 'white.core'
    | 'black.200'
}

export const Close = ({
  fill,
  fillType = 'black.50',
  height,
  width,
  title = 'Close',
  size = 'small',
}: IClose) => {
  const theme = useTheme()
  const sizeValues = useSize(size, height, width)
  const fillValue = useFillColor(theme, fillType, fill)

  return (
    <svg
      width={sizeValues.width}
      height={sizeValues.height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>{title}</title>
      <path
        d='M5.4073 3.99306C5.01678 3.60254 4.38361 3.60254 3.99309 3.99306C3.60256 4.38359 3.60256 5.01675 3.99309 5.40728L6.58581 8L3.99309 10.5927C3.60256 10.9833 3.60256 11.6164 3.99309 12.0069C4.38361 12.3975 5.01678 12.3975 5.4073 12.0069L8.00003 9.41421L10.5928 12.0069C10.9833 12.3975 11.6164 12.3975 12.007 12.0069C12.3975 11.6164 12.3975 10.9832 12.007 10.5927L9.41424 8L12.007 5.40728C12.3975 5.01675 12.3975 4.38359 12.007 3.99306C11.6164 3.60254 10.9833 3.60254 10.5928 3.99306L8.00003 6.58579L5.4073 3.99306Z'
        fill={fillValue}
      />
    </svg>
  )
}
