import { forwardRef, useImperativeHandle, useRef } from 'react'

import { defineMessages, useIntl } from 'react-intl'
import styled from 'styled-components'
import { rem } from 'polished'

import { useIntlContext } from '../../contexts/IntlContext'
import { StyledSelect } from '../Input/styles'
import { CaretDown } from '../../icons/CaretDown'

const messages = defineMessages({
  label: {
    id: 'LanguageSelect.ChangeLanguage',
    defaultMessage: 'Change Language',
    description: 'The label for the language selection dropdown.',
  },
  english: {
    id: 'LanguageSelect.English',
    defaultMessage: 'English',
    description: 'The language option for English.',
  },
  czech: {
    id: 'LanguageSelect.Czech',
    defaultMessage: 'Czech',
    description: 'The language option for Czech.',
  },
  french: {
    id: 'LanguageSelect.French',
    defaultMessage: 'French',
    description: 'The language option for French.',
  },
  german: {
    id: 'LanguageSelect.German',
    defaultMessage: 'German',
    description: 'The language option for German.',
  },
  greek: {
    id: 'LanguageSelect.Greek',
    defaultMessage: 'Greek',
    description: 'The language option for Greek.',
  },
  hungarian: {
    id: 'LanguageSelect.Hungarian',
    defaultMessage: 'Hungarian',
    description: 'The language option for Hungarian.',
  },
  spanish: {
    id: 'LanguageSelect.Spanish',
    defaultMessage: 'Spanish',
    description: 'The language option for Spanish.',
  },
  italian: {
    id: 'LanguageSelect.Italian',
    defaultMessage: 'Italian',
    description: 'The language option for Italian.',
  },
  danish: {
    id: 'LanguageSelect.Danish',
    defaultMessage: 'Danish',
    description: 'The language option for Danish.',
  },
  dutch: {
    id: 'LanguageSelect.Dutch',
    defaultMessage: 'Dutch',
    description: 'The language option for Dutch.',
  },
  malay: {
    id: 'LanguageSelect.Malay',
    defaultMessage: 'Malay',
    description: 'The language option for Malay.',
  },
  polish: {
    id: 'LanguageSelect.Polish',
    defaultMessage: 'Polish',
    description: 'The language option for Polish.',
  },
  swedish: {
    id: 'LanguageSelect.Swedish',
    defaultMessage: 'Swedish',
    description: 'The language option for Swedish.',
  },
  japanese: {
    id: 'LanguageSelect.Japanese',
    defaultMessage: 'Japanese',
    description: 'The language option for Japanese.',
  },
  korean: {
    id: 'LanguageSelect.Korean',
    defaultMessage: 'Korean',
    description: 'The language option for Korean.',
  },
  portugese: {
    id: 'LanguageSelect.Portugese',
    defaultMessage: 'Portugese',
    description: 'The language option for Portugese.',
  },
})

const StyledLanguageSelect = styled.div`
  width: 55px;
  position: relative;
  & select {
    appearance: none;
    font-family: 'Open Sans', Helvetica, san-serif;
    font-size: ${rem(13)};
    padding: 0.5rem;

    &::-ms-expand {
      display: none;
    }
    & option {
      font-weight: normal;
      font-size: 0.875rem;
    }
  }
`

const StyledCaret = styled(CaretDown)`
  pointer-events: none;
  fill: ${({ theme }) => theme.lightBlue};
  stroke: ${({ theme }) => theme.lightBlue};
  position: absolute;
  right: 10px;
  top: 50%;
`

export const LanguageSelect = forwardRef(({ extraLanguages, ...props }: any, ref) => {
  const { formatMessage } = useIntl()
  const { locale, setLocale } = useIntlContext()
  const localRef = useRef()
  useImperativeHandle(ref, () => localRef.current)

  let options = [
    { description: formatMessage(messages.english), value: 'en', flag: '🇺🇸' },
    { description: formatMessage(messages.french), value: 'fr', flag: '🇫🇷' },
    { description: formatMessage(messages.german), value: 'de', flag: '🇩🇪' },
    { description: formatMessage(messages.spanish), value: 'es', flag: '🇪🇸' },
  ]

  if (extraLanguages)
    options = [
      ...options,
      { description: formatMessage(messages.italian), value: 'it', flag: '🇮🇹' },
      { description: formatMessage(messages.danish), value: 'da', flag: '🇩🇰' },
      { description: formatMessage(messages.dutch), value: 'nl', flag: '🇳🇱' },
      { description: formatMessage(messages.polish), value: 'pl', flag: '🇵🇱' },
      { description: formatMessage(messages.swedish), value: 'sv', flag: '🇸🇪' },
      { description: formatMessage(messages.japanese), value: 'ja', flag: '🇯🇵' },
      { description: formatMessage(messages.korean), value: 'ko', flag: '🇰🇷' },
      { description: formatMessage(messages.portugese), value: 'pt', flag: '🇧🇷' },
      { description: formatMessage(messages.czech), value: 'cs', flag: '🇨🇿' },
      { description: formatMessage(messages.greek), value: 'el', flag: '🇬🇷' },
      { description: formatMessage(messages.hungarian), value: 'hu', flag: '🇭🇺' },
      { description: formatMessage(messages.malay), value: 'ms', flag: '🇲🇾' },
    ]

  const handleChange = (event: any) => {
    setLocale(event.target.value)
  }

  return (
    <StyledLanguageSelect {...props}>
      <StyledSelect
        as='select'
        value={locale}
        onChange={handleChange}
        required={false}
        arialLabel='Language Select'
        default={locale}
        // @ts-expect-error TS(2769): No overload matches this call.
        ref={localRef}
      >
        {options.map((op) => (
          <option key={op.value} value={op.value}>
            {op.value.toUpperCase()}
          </option>
        ))}
      </StyledSelect>
      <StyledCaret height={10} width={10} />
    </StyledLanguageSelect>
  )
})

LanguageSelect.displayName = 'LanguageSelect'

LanguageSelect.defaultProps = {
  extraLanguages: false,
}
