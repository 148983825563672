import { forwardRef, useImperativeHandle, useRef } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { Button } from '@tiltify/ui/components/Button'
import { userMenuMessages } from '@tiltify/ui/translations'
import { baseTiltifyUrl } from '@tiltify/ui/utils/tiltifyUrl'

import { useAuthenticationState } from '../../../../../../contexts/AuthenticationContext'

const StyledButton = styled(Button)`
  display: flex;
  font-size: 0.8125rem !important;
  font-weight: normal !important;
  padding: 0.625rem 1.0625rem;
  border: none;
  color: ${({ theme }) => theme.textColor} !important;
`

export const FrontendUserProfile = forwardRef((props, ref) => {
  const { formatMessage } = useIntl()
  const { authenticatedUser: user } = useAuthenticationState()
  const localRef = useRef()
  useImperativeHandle(ref, () => localRef.current)

  const baseURL = baseTiltifyUrl()

  return (
    <StyledButton forwardedAs='a' href={`${baseURL}/@${user?.slug}/profile`} ref={localRef}>
      {formatMessage(userMenuMessages.profile)}
    </StyledButton>
  )
})

FrontendUserProfile.displayName = 'FrontendUserProfile'
