import styled, { DefaultTheme, css, useTheme } from 'styled-components'
import { rem } from 'polished'

import { Paragraph } from '../Paragraph'
import { Success } from '../../icons/Success'
import { Close } from '../../icons/Close'
import { Information } from '../../icons/Information'
import { Warning } from '../../icons/Warning'

const successStyles = css`
  background: ${({ theme }) => theme.colors.green[50]};
  border-color: ${({ theme }) => theme.colors.green.core};
`

const errorStyles = css`
  background: ${({ theme }) => theme.colors.error[50]};
  border-color: ${({ theme }) => theme.colors.error.core};
`

const warningStyles = css`
  background: ${({ theme }) => theme.colors.warning[50]};
  border-color: ${({ theme }) => theme.colors.warning.core};
`

const normalStyles = css`
  background: ${({ theme }) => theme.colors.blue[50]};
  border-color: ${({ theme }) => theme.colors.blue.core};
`

const NotificationWrapper = styled.div<{ type: string }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px;
  gap: 4px;
  width: 100%;
  border: 1px solid;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;

  ${({ type }) => {
    switch (type) {
      case 'success':
        return successStyles
      case 'error':
        return errorStyles
      case 'warning':
        return warningStyles
      default:
        return normalStyles
    }
  }}
`

const NotificationLeft = styled.div`
  box-sizing: border-box;
  padding: 4px;
  display: flex;
  align-items: center;
  order: 0;

  width: 32px;
  align-self: flex-start;
`

const NotificationContent = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 4px 8px;

  flex: 1;
  order: 1;
  align-self: stretch;
`

const NotificationRight = styled.div`
  box-sizing: border-box;
  padding: 4px;
  margin-left: auto;
  display: flex;
  align-items: center;
  order: 2;

  width: 32px;
  height: 32px;
  align-self: flex-start;
  justify-self: flex-end;
`

const StyledMessage = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.black[800]};
  max-width: none;
`

const NotificationText = styled.div`
  flex: 1;
`

const CloseButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
`

const ActionButton = styled.button`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: ${rem(14)};
  line-height: ${rem(14)};
  letter-spacing: 0.25px;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.blue.core};
  padding-inline: ${rem(8)};
  background: none;
  border: none;
  cursor: pointer;
`

interface INotification {
  buttonClick?: () => void
  buttonText?: string
  className?: string
  message?: string
  setShow?: (value: boolean) => void
  type: 'success' | 'error' | 'info' | 'warning'
  OverrideIcon?: React.ComponentType<TiltifyIcon>
  contentOverride?: React.ReactNode
}

const getIcon = (
  type: string,
  theme: DefaultTheme,
  OverrideIcon?: React.ComponentType<TiltifyIcon>
) => {
  switch (type) {
    case 'success':
      if (OverrideIcon) return <OverrideIcon size='large' fill={theme.colors.green[800]} />
      return <Success size='large' fillType='green.800' />
    case 'error':
      if (OverrideIcon) return <OverrideIcon size='large' fill={theme.colors.error.core} />
      return <Warning size='large' fillType='error.core' />
    case 'warning':
      if (OverrideIcon) return <OverrideIcon size='large' fill={theme.colors.warning[600]} />
      return <Warning size='large' fillType='warning.600' />
    default:
      if (OverrideIcon) return <OverrideIcon size='large' fillType='blue.core' />
      return <Information size='large' fillType='blue.core' />
  }
}

export const NotificationBanner = ({
  buttonClick,
  buttonText,
  className,
  type,
  message,
  setShow,
  OverrideIcon,
  contentOverride,
}: INotification): JSX.Element => {
  const theme = useTheme()
  return (
    <NotificationWrapper className={className} type={type}>
      <NotificationLeft>{getIcon(type, theme, OverrideIcon)}</NotificationLeft>
      <NotificationContent>
        <NotificationText>
          {contentOverride ? (
            contentOverride
          ) : (
            <StyledMessage size='small'>
              {message}
              {buttonClick && buttonText && (
                <ActionButton type='button' onClick={buttonClick}>
                  {buttonText}
                </ActionButton>
              )}
            </StyledMessage>
          )}
        </NotificationText>
      </NotificationContent>
      <>
        {setShow && (
          <NotificationRight>
            <CloseButton onClick={() => setShow(false)}>
              <Close fillType='black.400' />
            </CloseButton>
          </NotificationRight>
        )}
      </>
    </NotificationWrapper>
  )
}
