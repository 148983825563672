import { useTheme } from 'styled-components'

import { useFillColor, useSize } from './IconHooks'

export const Trash = ({
  fill,
  fillType = 'black.50',
  height,
  width,
  title = 'Trash',
  size = 'small',
}: TiltifyIcon): JSX.Element => {
  const theme = useTheme()
  const sizeValues = useSize(size, height, width)
  const fillValue = useFillColor(theme, fillType, fill)

  return (
    <svg
      width={sizeValues.width}
      height={sizeValues.height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>{title}</title>
      <path
        d='M6.66667 12.2C6.84348 12.2 7.01305 12.1263 7.13807 11.995C7.2631 11.8637 7.33333 11.6857 7.33333 11.5V7.3C7.33333 7.11435 7.2631 6.9363 7.13807 6.80503C7.01305 6.67375 6.84348 6.6 6.66667 6.6C6.48986 6.6 6.32029 6.67375 6.19526 6.80503C6.07024 6.9363 6 7.11435 6 7.3V11.5C6 11.6857 6.07024 11.8637 6.19526 11.995C6.32029 12.1263 6.48986 12.2 6.66667 12.2ZM13.3333 3.8H10.6667V3.1C10.6667 2.54305 10.456 2.0089 10.0809 1.61508C9.70581 1.22125 9.1971 1 8.66667 1H7.33333C6.8029 1 6.29419 1.22125 5.91912 1.61508C5.54405 2.0089 5.33333 2.54305 5.33333 3.1V3.8H2.66667C2.48986 3.8 2.32029 3.87375 2.19526 4.00503C2.07024 4.1363 2 4.31435 2 4.5C2 4.68565 2.07024 4.8637 2.19526 4.99497C2.32029 5.12625 2.48986 5.2 2.66667 5.2H3.33333V12.9C3.33333 13.457 3.54405 13.9911 3.91912 14.3849C4.29419 14.7788 4.8029 15 5.33333 15H10.6667C11.1971 15 11.7058 14.7788 12.0809 14.3849C12.456 13.9911 12.6667 13.457 12.6667 12.9V5.2H13.3333C13.5101 5.2 13.6797 5.12625 13.8047 4.99497C13.9298 4.8637 14 4.68565 14 4.5C14 4.31435 13.9298 4.1363 13.8047 4.00503C13.6797 3.87375 13.5101 3.8 13.3333 3.8ZM6.66667 3.1C6.66667 2.91435 6.73691 2.7363 6.86193 2.60503C6.98695 2.47375 7.15652 2.4 7.33333 2.4H8.66667C8.84348 2.4 9.01305 2.47375 9.13807 2.60503C9.2631 2.7363 9.33333 2.91435 9.33333 3.1V3.8H6.66667V3.1ZM11.3333 12.9C11.3333 13.0857 11.2631 13.2637 11.1381 13.395C11.013 13.5262 10.8435 13.6 10.6667 13.6H5.33333C5.15652 13.6 4.98695 13.5262 4.86193 13.395C4.7369 13.2637 4.66667 13.0857 4.66667 12.9V5.2H11.3333V12.9ZM9.33333 12.2C9.51014 12.2 9.67971 12.1263 9.80474 11.995C9.92976 11.8637 10 11.6857 10 11.5V7.3C10 7.11435 9.92976 6.9363 9.80474 6.80503C9.67971 6.67375 9.51014 6.6 9.33333 6.6C9.15652 6.6 8.98695 6.67375 8.86193 6.80503C8.73691 6.9363 8.66667 7.11435 8.66667 7.3V11.5C8.66667 11.6857 8.73691 11.8637 8.86193 11.995C8.98695 12.1263 9.15652 12.2 9.33333 12.2Z'
        fill={fillValue}
      />
    </svg>
  )
}
