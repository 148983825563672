import { useTheme } from 'styled-components'

import { useFillColor, useSize } from './IconHooks'

interface ISuccess extends Omit<TiltifyIcon, 'fillType'> {
  fillType?: 'green.200' | 'green.800' | 'black.400' | 'blue.core'
}

export const Success = ({
  fill,
  fillType = 'green.800',
  height,
  width,
  title = 'Check',
  size = 'small',
}: ISuccess) => {
  const theme = useTheme()
  const sizeValues = useSize(size, height, width)
  const fillValue = useFillColor(theme, fillType, fill)

  return (
    <svg
      width={sizeValues.width}
      height={sizeValues.height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>{title}</title>
      <path
        d='M9.81338 5.86L6.95338 8.72667L5.85338 7.62667C5.79361 7.55688 5.72007 7.5002 5.63736 7.46018C5.55465 7.42017 5.46456 7.39768 5.37275 7.39414C5.28093 7.39059 5.18938 7.40606 5.10383 7.43958C5.01828 7.47309 4.94058 7.52393 4.87561 7.5889C4.81064 7.65387 4.7598 7.73157 4.72629 7.81712C4.69277 7.90267 4.6773 7.99422 4.68084 8.08604C4.68439 8.17785 4.70688 8.26794 4.74689 8.35065C4.78691 8.43336 4.84359 8.5069 4.91338 8.56667L6.48004 10.14C6.54234 10.2018 6.61621 10.2507 6.69744 10.2838C6.77866 10.317 6.86564 10.3338 6.95338 10.3333C7.12827 10.3326 7.29587 10.2632 7.42004 10.14L10.7534 6.80667C10.8159 6.74469 10.8655 6.67096 10.8993 6.58972C10.9332 6.50848 10.9506 6.42134 10.9506 6.33333C10.9506 6.24533 10.9332 6.15819 10.8993 6.07695C10.8655 5.99571 10.8159 5.92198 10.7534 5.86C10.6285 5.73583 10.4595 5.66614 10.2834 5.66614C10.1073 5.66614 9.93829 5.73583 9.81338 5.86ZM8.00004 1.33333C6.6815 1.33333 5.39257 1.72433 4.29624 2.45687C3.19991 3.18941 2.34543 4.2306 1.84085 5.44878C1.33626 6.66695 1.20424 8.0074 1.46148 9.3006C1.71871 10.5938 2.35365 11.7817 3.286 12.714C4.21835 13.6464 5.40623 14.2813 6.69944 14.5386C7.99265 14.7958 9.33309 14.6638 10.5513 14.1592C11.7694 13.6546 12.8106 12.8001 13.5432 11.7038C14.2757 10.6075 14.6667 9.31854 14.6667 8C14.6667 7.12452 14.4943 6.25761 14.1592 5.44878C13.8242 4.63994 13.3331 3.90501 12.7141 3.28596C12.095 2.6669 11.3601 2.17584 10.5513 1.8408C9.74243 1.50577 8.87552 1.33333 8.00004 1.33333V1.33333ZM8.00004 13.3333C6.94521 13.3333 5.91406 13.0205 5.037 12.4345C4.15994 11.8485 3.47635 11.0155 3.07269 10.041C2.66902 9.06644 2.5634 7.99408 2.76919 6.95952C2.97498 5.92495 3.48293 4.97464 4.22881 4.22876C4.97469 3.48288 5.925 2.97493 6.95956 2.76915C7.99413 2.56336 9.06648 2.66898 10.041 3.07264C11.0156 3.47631 11.8485 4.1599 12.4345 5.03696C13.0206 5.91402 13.3334 6.94517 13.3334 8C13.3334 9.41449 12.7715 10.771 11.7713 11.7712C10.7711 12.7714 9.41453 13.3333 8.00004 13.3333V13.3333Z'
        fill={fillValue}
      />
    </svg>
  )
}
