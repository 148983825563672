import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { Portal } from '@tiltify/design/components/Modal/Portal'
import { Notification } from '@tiltify/design/components/Notification'

const NotificationWrapper = styled(motion.div)`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  top: 5px;
  right: 5px;
  z-index: 9999;

  .success {
    border-top: 2px solid #5ea400;
    background-color: #f0f5ea;
    color: #4b583a;
    box-shadow: 0 0 1px rgba(94, 164, 0, 0.9);
  }

  .error {
    border-top: 2px solid #ec3d3d;
    background-color: #f4e9e9;
    color: #412f2f;
    box-shadow: 0 0 1px rgba(236, 61, 61, 0.9);
  }
`

interface OptionsProps {
  type?: Pick<React.ComponentProps<typeof Notification>, 'type'>['type']
  message?: string
  title: string
}

const NotificationContext = React.createContext<iNotificationContext | undefined>(undefined)

interface iNotificationContext {
  triggerNotification: (message: string, error?: boolean) => void
  triggerNewNotification: (options: OptionsProps) => void
}

interface iNotificationContextProvider {
  children: React.ReactNode
}

const SlideAnimation = {
  initial: { x: 300, y: 0 },
  animate: { x: 0, y: 0 },
}

const NotificationContextProvider = ({ children }: iNotificationContextProvider): JSX.Element => {
  const [show, setShow] = useState(false)
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('')
  const [options, setOptions] = useState<OptionsProps | null>(null)
  const timeoutRef = useRef(0)
  const { initial, animate } = SlideAnimation

  useEffect(() => {
    timeoutRef.current = window.setTimeout(() => {
      setShow(false)
    }, 10000)
    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [show])

  const triggerNotification = (message: string, error = false) => {
    setError(error)
    setMessage(message)
    setShow(true)
    setOptions(null)
  }

  const triggerNewNotification = (options: OptionsProps) => {
    setShow(true)
    setOptions(options)
  }

  const handleToastClose = () => {
    setShow(false)
    clearTimeout(timeoutRef.current)
  }

  return (
    <NotificationContext.Provider value={{ triggerNotification, triggerNewNotification }}>
      <AnimatePresence mode='wait'>
        <>
          {show && (
            <Portal>
              <NotificationWrapper
                key='toast-notification'
                initial={initial}
                animate={animate}
                exit={initial}
              >
                <Notification
                  type={
                    options
                      ? options.type
                        ? options.type
                        : 'success'
                      : error
                        ? 'error'
                        : 'success'
                  }
                  message={options ? options.message : message ? message : 'Something went wrong'}
                  title={
                    options ? options.title : error ? 'Error Notification' : 'Success Notification'
                  }
                  {...options}
                  setShow={handleToastClose}
                />
              </NotificationWrapper>
            </Portal>
          )}
        </>
      </AnimatePresence>
      {children}
    </NotificationContext.Provider>
  )
}

const useNotificationContext = () => {
  const context = React.useContext(NotificationContext)
  if (context === undefined) {
    throw new Error('useNotificationContext must be used within a NotificationContextProvider')
  }
  return context
}

export { NotificationContextProvider, useNotificationContext }
