export function Facebook({ ariaHidden, focusable, height, width, isDark, title, ...rest }: any) {
  const h = height || '1rem'
  const w = width || '1rem'
  return (
    <svg
      viewBox='0 0 14222 14222'
      width={w}
      height={h}
      aria-hidden={ariaHidden}
      focusable={focusable}
      fill='none'
      {...rest}
    >
      {title ? <title>{title}</title> : null}
      <circle cx='7111' cy='7112' r='7111' fill={isDark ? '#fff' : '#1877F2'} />
      <path
        d='M9879 9168l315-2056H8222V5778c0-562 275-1111 1159-1111h897V2917s-814-139-1592-139c-1624 0-2686 984-2686 2767v1567H4194v2056h1806v4969c362 57 733 86 1111 86s749-30 1111-86V9168z'
        fill={isDark ? '#303034' : '#fff'}
      />
    </svg>
  )
}

Facebook.defaultProps = {
  ariaHidden: false,
  focusable: false,
}
