import { useTheme } from 'styled-components'

import { useFillColor, useSize } from './IconHooks'

export const Archive = ({
  fill,
  fillType = 'black.50',
  size = 'small',
  height,
  width,
  title = 'Archive',
}: TiltifyIcon) => {
  const theme = useTheme()
  const sizeValues = useSize(size, height, width)
  const fillValue = useFillColor(theme, fillType, fill)
  return (
    <svg
      width={sizeValues.width}
      height={sizeValues.height}
      viewBox='0 0 16 16'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>{title}</title>
      <path
        d='M6.66683 9.33333H9.3335C9.51031 9.33333 9.67988 9.2631 9.8049 9.13807C9.92992 9.01305 10.0002 8.84348 10.0002 8.66667C10.0002 8.48986 9.92992 8.32029 9.8049 8.19526C9.67988 8.07024 9.51031 8 9.3335 8H6.66683C6.49002 8 6.32045 8.07024 6.19542 8.19526C6.0704 8.32029 6.00016 8.48986 6.00016 8.66667C6.00016 8.84348 6.0704 9.01305 6.19542 9.13807C6.32045 9.2631 6.49002 9.33333 6.66683 9.33333ZM12.6668 2H3.3335C2.80306 2 2.29436 2.21071 1.91928 2.58579C1.54421 2.96086 1.3335 3.46957 1.3335 4V6C1.3335 6.17681 1.40373 6.34638 1.52876 6.4714C1.65378 6.59643 1.82335 6.66667 2.00016 6.66667H2.66683V12C2.66683 12.5304 2.87754 13.0391 3.25262 13.4142C3.62769 13.7893 4.1364 14 4.66683 14H11.3335C11.8639 14 12.3726 13.7893 12.7477 13.4142C13.1228 13.0391 13.3335 12.5304 13.3335 12V6.66667H14.0002C14.177 6.66667 14.3465 6.59643 14.4716 6.4714C14.5966 6.34638 14.6668 6.17681 14.6668 6V4C14.6668 3.46957 14.4561 2.96086 14.081 2.58579C13.706 2.21071 13.1973 2 12.6668 2ZM12.0002 12C12.0002 12.1768 11.9299 12.3464 11.8049 12.4714C11.6799 12.5964 11.5103 12.6667 11.3335 12.6667H4.66683C4.49002 12.6667 4.32045 12.5964 4.19542 12.4714C4.0704 12.3464 4.00016 12.1768 4.00016 12V6.66667H12.0002V12ZM13.3335 5.33333H2.66683V4C2.66683 3.82319 2.73707 3.65362 2.86209 3.5286C2.98712 3.40357 3.15668 3.33333 3.3335 3.33333H12.6668C12.8436 3.33333 13.0132 3.40357 13.1382 3.5286C13.2633 3.65362 13.3335 3.82319 13.3335 4V5.33333Z'
        fill={fillValue}
      />
    </svg>
  )
}
