import { defineMessages } from 'react-intl'

export const inputMessages = defineMessages({
  email: {
    id: 'InputMessages.Email',
    defaultMessage: 'Email address',
    description: 'Label text for the email input.',
  },
  emailPlaceholder: {
    id: 'InputMessages.EmailPlaceholder',
    defaultMessage: 'email@domain.com',
    description: 'The text for the email input placeholder.',
  },
  password: {
    id: 'InputMessages.Password',
    defaultMessage: 'Password',
    description: 'The label for the password input field.',
  },
  passwordConfirmation: {
    id: 'InputMessages.PasswordConfirmation',
    defaultMessage: 'Confirm password',
    description: 'The label for the password confirmation input field.',
  },
  firstName: {
    id: 'InputMessages.FirstName',
    defaultMessage: 'First name',
    description: 'The label for the first name input field.',
  },
  lastName: {
    id: 'InputMessages.LastName',
    defaultMessage: 'Last name',
    description: 'The label for the last name input field.',
  },
  username: {
    id: 'InputMessages.Username',
    defaultMessage: 'Username',
    description: 'The label for the username input field.',
  },
  chooseUsername: {
    id: 'InputMessages.ChooseUsername',
    defaultMessage: 'Choose a username',
    description: 'The label for the username input field.',
  },
  campaignSearch: {
    id: 'InputMessages.CampaignSearch',
    defaultMessage: 'Search for a campaign name',
    description: 'Search input label for finding a campaign',
  },
  individual: {
    id: 'InputMessages.Individual',
    defaultMessage: 'Individual',
    description: 'Label for individual checkbox',
  },
  team: {
    id: 'InputMessages.Team',
    defaultMessage: 'Team',
    description: 'Label for team checkbox',
  },
  supportLabel: {
    id: 'InputMessages.SupportLabel',
    defaultMessage: 'Support an existing team campaign (add your total into a larger campaign)',
    description: 'Label for support campaign checkbox',
  },
  twitch: {
    id: 'InputMessages.Twitch',
    defaultMessage: 'Twitch',
    description: 'Label for Twitch checkbox',
  },
  ytLive: {
    id: 'InputMessages.YTLive',
    defaultMessage: 'YouTube Live',
    description: 'Label for YouTube Live checkbox',
  },
  facebook: {
    id: 'InputMessages.Facebook',
    defaultMessage: 'Facebook',
    description: 'Label for Facebook checkbox',
  },
  video: {
    id: 'InputMessages.Video',
    defaultMessage: 'YouTube Video',
    description: 'Label for YouTube Video checkbox',
  },
  image: {
    id: 'InputMessages.Image',
    defaultMessage: 'Image',
    description: 'Label for Image checkbox',
  },
  livestream: {
    id: 'InputMessages.Livestream',
    defaultMessage: 'Livestream',
    description: 'Label for livestream checkbox',
  },
  bio: {
    id: 'InputMessages.Bio',
    defaultMessage: 'Bio',
    description: 'Label for bio input',
  },
  avatar: {
    id: 'InputMessages.Avatar',
    defaultMessage: 'Avatar',
    description: 'Label for avatar image uploader',
  },
  suggestedAvatar: {
    id: 'InputMessages.SuggestedAvatar',
    defaultMessage: 'Suggested 400 x 400px jpg or png',
    description: 'Helper text for the avatar image uploader',
  },
  suggestedImage: {
    id: 'InputMessages.SuggestedImage',
    defaultMessage: 'Suggested 1440 x 530px jpg or png',
    description: 'Helper text for the avatar image uploader',
  },
  publicVideo: {
    id: 'InputMessages.PublicVideo',
    defaultMessage: 'Video must be public',
    description: 'Helper text for the avatar image uploader',
  },
  searchHere: {
    id: 'InputMessages.SearchHere',
    defaultMessage: 'search here',
    description: 'Placeholder text for the search input',
  },
  submit: {
    id: 'InputMessages.Submit',
    defaultMessage: 'submit',
    description: 'Button text for submit',
  },
  none: {
    id: 'InputMessages.None',
    defaultMessage: 'None',
    description: 'Button text for none',
  },
  campaignName: {
    id: 'InputMessages.CampaignName',
    defaultMessage: 'Campaign name',
    description: 'label for campaign name',
  },
  campaignDescription: {
    id: 'InputMessages.CampaignDescription',
    defaultMessage: 'Campaign description',
    description: 'Label text for a campaign description input',
  },
  campaignLink: {
    id: 'InputMessages.CampaignLink',
    defaultMessage: 'Campaign link',
    description: 'Label text for a campaign URL input',
  },
  selectUnits: {
    id: 'InputMessages.SelectUnits',
    defaultMessage: 'Select your units',
    description: 'heading text for a mi/km toggle input',
  },
  km: {
    id: 'InputMessages.KM',
    defaultMessage: 'Km',
    description: 'label text for km toggle',
  },
  miles: {
    id: 'InputMessages.Miles',
    defaultMessage: 'Miles',
    description: 'label text for miles toggle',
  },
  selectActivities: {
    id: 'InputMessages.SelectActivities',
    defaultMessage: 'Activity types to include in this campaign',
    description: 'heading text for activities to include radio list',
  },
  campaignActivityGoals: {
    id: 'InputMessages.CampaignActivityGoals',
    defaultMessage: 'Any campaign goals?',
    description: 'heading text for activity goals',
  },
  distance: {
    id: 'InputMessages.Distance',
    defaultMessage: 'Distance',
    description: 'label text for distance input',
  },
  distanceWithLabel: {
    id: 'InputMessages.DistanceWithLabel',
    defaultMessage: 'Distance ({unit})',
    description: 'label text for distance input with unit of measurement',
  },
  time: {
    id: 'InputMessages.Time',
    defaultMessage: 'Time',
    description: 'label text for time input',
  },
  timeWithHour: {
    id: 'InputMessages.TimeWithHour',
    defaultMessage: 'Time (hr)',
    description: 'label text for time input with hour label',
  },
  timeWithMinute: {
    id: 'InputMessages.TimeWithMinute',
    defaultMessage: 'Time (mm)',
    description: 'label text for time input with minute label',
  },
  showPolyline: {
    id: 'InputMessages.ShowPolyline',
    defaultMessage: 'Display routes (without locations) on your campaign page',
    description: 'label text for polyline allow switch',
  },
  itemName: {
    id: 'InputMessages.ItemName',
    defaultMessage: 'Item name',
    description: 'label text for schedule item name',
  },
  description: {
    id: 'InputMessages.Description',
    defaultMessage: 'Description',
    description: 'label text for description',
  },
  startDate: {
    id: 'InputMessages.StartDate',
    defaultMessage: 'When does it start?',
    description: 'label text for when does it start datepicker',
  },
  endDate: {
    id: 'InputMessages.EndDate',
    defaultMessage: 'End date',
    description: 'label text for end date',
  },
  twitchUsername: {
    id: 'InputMessages.TwitchUsername',
    defaultMessage: 'Twitch username',
    description: 'label for the twitch username input',
  },
  ytChannelId: {
    id: 'InputMessages.YTChannelID',
    defaultMessage: 'YouTube channel id',
    description: 'label for the youtube channel id input',
  },
  facebookVideo: {
    id: 'InputMessages.FacebookVideo',
    defaultMessage: 'Facebook video url',
    description: 'label for the facebook video input',
  },
  videoUrl: {
    id: 'InputMessages.VideoURL',
    defaultMessage: 'Video url',
    description: 'label for the video input',
  },
  preview: {
    id: 'InputMessages.Preview',
    defaultMessage: 'preview',
    description: 'button text for preview',
  },
  create: {
    id: 'InputMessages.Create',
    defaultMessage: 'Create',
    description: 'button text for create',
  },
  createAndPublish: {
    id: 'InputMessages.CreateAndPublish',
    defaultMessage: 'Create & publish',
    description: 'button text for create and publish',
  },
  cancel: {
    id: 'InputMessages.Cancel',
    defaultMessage: 'Cancel',
    description: 'button text for cancel',
  },
  campaignGoal: {
    id: 'InputMessages.CampaignGoal',
    defaultMessage: 'Campaign goal',
    description: 'label text for campaign goal',
  },
  minimumGoal: {
    id: 'InputMessages.MinimumGoal',
    defaultMessage: 'Minimum goal {amount}',
    description: 'helper text for minimum goal',
  },
  minimumGoalWithMilestones: {
    id: 'InputMessages.MinimumGoalWithMilestones',
    defaultMessage: 'Minimum goal {amount} (based on milestones selected)',
    description: 'helper text for minimum goal with milestones',
  },
  enableAll: {
    id: 'InputMessages.EnableAll',
    defaultMessage: 'Enable all',
    description: 'label text for enable all switch',
  },
  renameMilestone: {
    id: 'InputMessages.RenameMilestone',
    defaultMessage: 'Rename milestone',
    description: 'label text for rename milestone input',
  },
  agree: {
    id: 'InputMessages.Agree',
    defaultMessage: 'I have read and agree to this statement.',
    description: 'checkbox label for agree statement',
  },
  addAddress: {
    id: 'InputMessages.AddAddress',
    defaultMessage: 'Add address',
    description: 'button text for add address',
  },
  newAddress: {
    id: 'InputMessages.NewAddress',
    defaultMessage: 'New address',
    description: 'button text for new address',
  },
  useAddress: {
    id: 'InputMessages.UseAddress',
    defaultMessage: 'Use address',
    description: 'button text for use address',
  },
  addOne: {
    id: 'InputMessages.AddOne',
    defaultMessage: 'Address',
    description: 'label text for address line 1',
  },
  addTwo: {
    id: 'InputMessages.AddTwo',
    defaultMessage: 'Address 2',
    description: 'label text for address line 2',
  },
  country: {
    id: 'InputMessages.Country',
    defaultMessage: 'Country',
    description: 'label text for country',
  },
  state: {
    id: 'InputMessages.State',
    defaultMessage: 'State/Region',
    description: 'label text for state',
  },
  city: {
    id: 'InputMessages.City',
    defaultMessage: 'City',
    description: 'label text for city',
  },
  zip: {
    id: 'InputMessages.Zip',
    defaultMessage: 'Zip/Postal code',
    description: 'label text for zip code',
  },
  shirtFit: {
    id: 'InputMessages.ShirtFit',
    defaultMessage: 'Shirt fit',
    description: 'label text for shirt fit',
  },
  shirtSize: {
    id: 'InputMessages.ShirtSize',
    defaultMessage: 'Shirt size',
    description: 'label text for shirt size',
  },
  phone: {
    id: 'InputMessages.Phone',
    defaultMessage: 'Phone number',
    description: 'label text for phone number',
  },
  dob: {
    id: 'InputMessages.DOB',
    defaultMessage: 'Date of Birth',
    description: 'label text for birthday',
  },
  serviceHours: {
    id: 'InputMessages.ServiceHours',
    defaultMessage: 'Eligible for Student Service Hours',
    description: 'label text for service hours',
  },
  back: {
    id: 'InputMessages.Back',
    defaultMessage: 'Back',
    description: 'button text for back',
  },
  skip: {
    id: 'InputMessages.Skip',
    defaultMessage: 'Skip',
    description: 'button text for skip',
  },
  continue: {
    id: 'InputMessages.Continue',
    defaultMessage: 'Continue',
    description: 'button text for continue',
  },
  all: {
    id: 'InputMessages.All',
    defaultMessage: 'All',
    description: 'toggle for all region filter',
  },
  us: {
    id: 'InputMessages.US',
    defaultMessage: 'United States',
    description: 'toggle for US region filter',
  },
  international: {
    id: 'InputMessages.International',
    defaultMessage: 'International',
    description: 'toggle for international region filter',
  },
  causeSuggestedImage: {
    id: 'InputMessages.CauseSuggestedImage',
    defaultMessage: 'Use cause suggested image',
    description: 'label for cause suggested image',
  },
  causeSuggestedVideo: {
    id: 'InputMessages.CauseSuggestedVideo',
    defaultMessage: 'Use cause YouTube video',
    description: 'label for cause suggested video',
  },
  yesAnswer: {
    id: 'InputMessages.YesAnswer',
    defaultMessage: 'Yes',
    description: 'label for yes answer',
  },
  noAnswer: {
    id: 'InputMessages.NoAnswer',
    defaultMessage: 'No',
    description: 'label for no answer',
  },
  saveChanges: {
    id: 'InputMessages.SaveChanges',
    defaultMessage: 'Save changes',
    description: 'label for save changes button',
  },
  revertChanges: {
    id: 'InputMessages.RevertChanges',
    defaultMessage: 'Undo changes',
    description: 'label for revert changes button',
  },
  thankYouMessage: {
    id: 'InputMessages.ThankYouMessage',
    defaultMessage: '  Thank you message for your donors ',
    description: 'label for thank you message',
  },
})
