import { useTheme } from 'styled-components'

import { useFillColor, useSize } from './IconHooks'

export const Copy = ({
  fill,
  fillType = 'black.50',
  size = 'small',
  height,
  width,
  title = 'Copy',
}: TiltifyIcon) => {
  const theme = useTheme()
  const sizeValues = useSize(size, height, width)
  const fillValue = useFillColor(theme, fillType, fill)

  return (
    <svg
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
      width={sizeValues.width}
      height={sizeValues.height}
      aria-hidden={false}
      focusable={false}
      fill='none'
    >
      <title>{title}</title>
      <path
        d='M14 5.62667C13.9931 5.56542 13.9796 5.50509 13.96 5.44667V5.38667C13.9279 5.31812 13.8852 5.25511 13.8333 5.2L9.83333 1.2C9.77822 1.14814 9.71521 1.10539 9.64667 1.07333C9.62677 1.07051 9.60657 1.07051 9.58667 1.07333C9.51894 1.03449 9.44415 1.00956 9.36667 1H6.66667C6.13623 1 5.62753 1.21071 5.25245 1.58579C4.87738 1.96086 4.66667 2.46957 4.66667 3V3.66667H4C3.46957 3.66667 2.96086 3.87738 2.58579 4.25245C2.21071 4.62753 2 5.13623 2 5.66667V12.3333C2 12.8638 2.21071 13.3725 2.58579 13.7475C2.96086 14.1226 3.46957 14.3333 4 14.3333H9.33333C9.86377 14.3333 10.3725 14.1226 10.7475 13.7475C11.1226 13.3725 11.3333 12.8638 11.3333 12.3333V11.6667H12C12.5304 11.6667 13.0391 11.456 13.4142 11.0809C13.7893 10.7058 14 10.1971 14 9.66667V5.66667C14 5.66667 14 5.66667 14 5.62667ZM10 3.27333L11.7267 5H10.6667C10.4899 5 10.3203 4.92976 10.1953 4.80474C10.0702 4.67971 10 4.51014 10 4.33333V3.27333ZM10 12.3333C10 12.5101 9.92976 12.6797 9.80474 12.8047C9.67971 12.9298 9.51014 13 9.33333 13H4C3.82319 13 3.65362 12.9298 3.5286 12.8047C3.40357 12.6797 3.33333 12.5101 3.33333 12.3333V5.66667C3.33333 5.48986 3.40357 5.32029 3.5286 5.19526C3.65362 5.07024 3.82319 5 4 5H4.66667V9.66667C4.66667 10.1971 4.87738 10.7058 5.25245 11.0809C5.62753 11.456 6.13623 11.6667 6.66667 11.6667H10V12.3333ZM12.6667 9.66667C12.6667 9.84348 12.5964 10.013 12.4714 10.1381C12.3464 10.2631 12.1768 10.3333 12 10.3333H6.66667C6.48986 10.3333 6.32029 10.2631 6.19526 10.1381C6.07024 10.013 6 9.84348 6 9.66667V3C6 2.82319 6.07024 2.65362 6.19526 2.5286C6.32029 2.40357 6.48986 2.33333 6.66667 2.33333H8.66667V4.33333C8.66667 4.86377 8.87738 5.37247 9.25245 5.74755C9.62753 6.12262 10.1362 6.33333 10.6667 6.33333H12.6667V9.66667Z'
        fill={fillValue}
      />
    </svg>
  )
}
