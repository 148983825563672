import { isArray } from 'lodash'
import React, { useEffect } from 'react'
import { FormProvider, UseFormReturn, useForm } from 'react-hook-form'

interface IForm {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children:
    | React.ReactNode
    | React.ReactNode[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | ((methods: UseFormReturn<any, object>) => React.ReactNode)
  id?: string
  formRef?: React.RefObject<HTMLFormElement>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialValues?: Record<string, any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (data: any) => void
  resetOnSubmit?: boolean
  onKeyDown?: React.KeyboardEventHandler<HTMLFormElement>
  className?: string
}

export const Form = ({
  id,
  formRef,
  children,
  initialValues,
  onSubmit,
  resetOnSubmit = true,
  onKeyDown,
  className,
}: IForm): JSX.Element => {
  const methods = useForm({ defaultValues: initialValues, mode: 'onBlur' })

  useEffect(() => {
    if (methods.formState.isSubmitSuccessful && resetOnSubmit) {
      methods.reset(initialValues)
    }
  }, [initialValues, methods.formState.isSubmitSuccessful, resetOnSubmit])

  return (
    <FormProvider {...methods}>
      <form
        ref={formRef}
        id={id}
        className={className}
        onSubmit={(e) => {
          e.stopPropagation()
          methods
            .handleSubmit(onSubmit)(e)
            .catch((errors) => {
              if (isArray(errors)) {
                errors.forEach((error: { key: string; message: string }) => {
                  if (error.key) {
                    methods.setError(error.key, {
                      type: 'submission-error',
                      message: error.message,
                    })
                  }
                })
              }
            })
        }}
        onKeyDown={onKeyDown}
      >
        {typeof children === 'function' ? children(methods) : children}
      </form>
    </FormProvider>
  )
}
