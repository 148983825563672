import styled from 'styled-components/macro'

import { Heading, AccentBar, MaxWidthWrapper } from '../styles'
import { Grid } from '@tiltify/ui'
import { SupportingCard } from './SupportingCard'
import autistica from './2023/autistica.png'
import calm from './2023/calm.png'
import comicrelief from './2023/comicrelief.png'
import coppafeel from './2023/coppafeel.png'
import galop from './2023/galop.png'
import grandappeal from './2023/grandappeal.png'
import helloworld from './2023/helloworld.png'
import justdiggit from './2023/justdiggit.png'
import movember from './2023/movember.png'
import rnib from './2023/rnib.png'
import warchild from './2023/warchild.png'
import wdc from './2023/wdc.png'

const StyledHeading = styled(Heading)`
  margin-top: 3rem;
`

const StyledGrid = styled(Grid)`
  min-height: 200px;
  padding-bottom: 3rem;

  @media (max-width: calc(${({ theme }) => theme.contentMaxWidth} - 32px)) {
    grid-template-columns: repeat(2, minmax(${({ itemWidth }) => itemWidth}, 1fr));
  }

  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    grid-template-columns: repeat(auto-fit, minmax(${({ itemWidth }) => itemWidth}, 1fr));
  }
`

const Paragraph = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 1px;
  color: #333333;
`

export const causeList = [
  {
    name: 'Autistica',
    description:
      'Creating a Mental Health and Wellbeing Tips Hub and other resources to support and empower autistic young people.',
    region: '582',
    logo: autistica,
    url: 'https://tiltify.com/autistica',
  },
  {
    name: 'Campaign Against Living Miserably (CALM)',
    description:
      'Answering 50,000 potentially live-saving helpline calls in 2024 from young people struggling to see a way forward.',
    region: '575',
    logo: calm,
    url: 'https://tiltify.com/calm',
  },
  {
    name: 'CoppaFeel!',
    description:
      'Stamping out the late detection and misdiagnosis of breast cancer in young people.',
    region: '589',
    logo: coppafeel,
    url: 'https://tiltify.com/coppafeel',
  },
  {
    name: 'Comic Relief',
    description:
      'Aiming to provide one million meals to people who need them most this winter by working together with FareShare and local community groups.',
    region: '583',
    logo: comicrelief,
    url: 'https://tiltify.com/comic-relief',
  },
  {
    name: 'Galop',
    description:
      'Unlocking a better future for LGBT+ youth with a national abuse and violence support service.',
    region: '585',
    logo: galop,
    url: 'https://tiltify.com/lesbian-gay-and-bisexual-anti-violence-and-policing-group',
  },
  {
    name: 'Hello World',
    description:
      'Building new Hello Hubs that provide connectivity, education, and power to hard-to-reach communities across Africa and Asia.',
    region: '586',
    logo: helloworld,
    url: '',
  },
  {
    name: 'Justdiggit',
    description:
      'Regreening African landscapes using indigenous restoration techniques, technology, and communication.',
    region: '587',
    logo: justdiggit,
    url: 'https://tiltify.com/justdiggit',
  },
  {
    name: 'Movember',
    description:
      'Solving some of the most complex issues facing men today including prostate cancer and testicular cancer.',
    region: '570',
    logo: movember,
    url: 'https://tiltify.com/movember',
  },
  {
    name: 'RNIB',
    description:
      'Transforming the lives of children and young people with vision impairment by creating a unique Developer Kit to help make games more accessible.',
    region: '590',
    logo: rnib,
    url: '',
  },
  {
    name: "Wallace & Gromit's Grand Appeal",
    description:
      "Creating the 'Jingle Jam Building', a unique facility that will help sick children and their families at Bristol Children's Hospital.",
    region: '565',
    logo: grandappeal,
    url: 'https://tiltify.com/the-grand-appeal',
  },
  {
    name: 'War Child',
    description:
      'Protecting, educating, and advocating for the rights of children impacted by the ongoing conflict in Ukraine.',
    region: '588',
    logo: warchild,
    url: 'https://tiltify.com/war-child-uk',
  },
  {
    name: 'Whale and Dolphin Conservation',
    description:
      'Helping to stop hunting and whaling for good so whales and dolphins can live safe and free.',
    region: '574',
    logo: wdc,
    url: 'https://tiltify.com/wdc',
  },
]

export const Causes = (): JSX.Element | null => {
  return (
    <MaxWidthWrapper>
      <StyledHeading>This year, Jingle Jam is supporting these amazing charities:</StyledHeading>
      <AccentBar />
      <Paragraph>
        You can fundraise for all of them using the button above, or fundraise for a specific cause
        using the registration buttons below.
      </Paragraph>
      <div>
        <StyledGrid itemWidth='260px'>
          {causeList.map((cause) => (
            <SupportingCard cause={cause} key={cause.region} />
          ))}
        </StyledGrid>
      </div>
    </MaxWidthWrapper>
  )
}
