import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

import { PageLoading } from '@tiltify/ui'

import JingleJamPink from 'assets/JJ2023.png'
import { causeList } from 'components/Causes'
import {
  AccentBar,
  /* BlackButton, */
  Heading,
} from 'components/styles'
import { useRouteContext } from 'contexts/RouteContext/index'

import { CampaignGrid } from './CampaignGrid'

const Wrapper = styled.div`
  height: 100%;
  min-height: 95vh;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;

  img {
    max-width: 233px;
  }
  h2 {
    margin-top: 0;
  }
`

const StyledLoading = styled.div`
  height: 100vh;
`

export const AllCampaignsComponent = (): JSX.Element => {
  const { fundraisingEvent } = useRouteContext()
  const [params] = useSearchParams()
  const [region] = useState(params.get('regionId') || '')
  const cause = causeList.find((cause) => cause.region === region)

  if (!fundraisingEvent) {
    return (
      <StyledLoading>
        <PageLoading />
      </StyledLoading>
    )
  }

  return (
    <Wrapper>
      <div style={{ marginBlock: '2rem' }}>
        <img style={{ maxWidth: '200px' }} src={JingleJamPink} alt='Jingle Jam Logo' />
      </div>
      {cause && (
        <HeadingWrapper>
          <img
            style={{ maxHeight: '63px', marginBottom: '1rem' }}
            src={cause.logo}
            alt={cause.name}
          />
          <Heading>{cause.name}</Heading>
          <AccentBar />
          {/* {fundraisingEvent.supportable && (
            <BlackButton
              as='a'
              href={`https://tiltify.com/jingle-jam/jingle-jam-2023/start?regionId=${cause.region}`}
              style={{ margin: 0, display: 'flex', justifyContent: 'center' }}
            >
              START FUNDRAISING
            </BlackButton>
          )} */}
        </HeadingWrapper>
      )}
      <CampaignGrid fundraisingEvent={fundraisingEvent} searchValue={region} />
    </Wrapper>
  )
}
