import { useTheme } from 'styled-components'

import { useFillColor, useSize } from './IconHooks'

export const ArrowLeft = ({
  fill,
  fillType = 'black.50',
  size = 'small',
  height,
  width,
  title = 'Arrow left',
}: TiltifyIcon) => {
  const theme = useTheme()
  const sizeValues = useSize(size, height, width)
  const fillValue = useFillColor(theme, fillType, fill)

  return (
    <svg
      width={sizeValues.width}
      height={sizeValues.height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>{title}</title>
      <path
        d='M12.1663 7.16664L4.83869 7.16664L7.58983 4.42723C7.74682 4.27044 7.83501 4.05779 7.83501 3.83605C7.83501 3.61432 7.74682 3.40167 7.58983 3.24487C7.43285 3.08808 7.21993 3 6.99792 3C6.77591 3 6.56299 3.08808 6.40601 3.24487L2.23761 7.40811C2.16171 7.4873 2.10222 7.58067 2.06254 7.68288C1.97915 7.8856 1.97915 8.11298 2.06254 8.3157C2.10222 8.4179 2.16171 8.51128 2.23761 8.59047L6.40601 12.7537C6.48351 12.8317 6.57572 12.8937 6.67731 12.936C6.7789 12.9782 6.88787 13 6.99792 13C7.10798 13 7.21694 12.9782 7.31854 12.936C7.42013 12.8937 7.51233 12.8317 7.58983 12.7537C7.66797 12.6763 7.72999 12.5842 7.77232 12.4827C7.81464 12.3813 7.83644 12.2724 7.83644 12.1625C7.83644 12.0526 7.81464 11.9438 7.77232 11.8423C7.72999 11.7408 7.66797 11.6488 7.58983 11.5713L4.83869 8.83194H12.1663C12.3874 8.83194 12.5995 8.74421 12.7558 8.58806C12.9122 8.43191 13 8.22012 13 7.99929C13 7.77846 12.9122 7.56667 12.7558 7.41052C12.5995 7.25437 12.3874 7.16664 12.1663 7.16664Z'
        fill={fillValue}
      />
    </svg>
  )
}
