export const getInitialScope = (
  user: AuthenticatedUser | null,
  path: string,
  forceSetScope: () => void
): UserScopeType => {
  const causeRoutes = ['causes', 'auctions', 'data-management', 'donations', 'events']

  if (!user) return 'USER'
  if (user.causes.length === 0) {
    return 'USER'
  } else {
    if (!user.roles.fundraiser) {
      return 'CAUSE'
    }
    if (causeRoutes.some((route) => path.includes(route))) {
      return 'CAUSE'
    }

    if (path.includes('login') || path.includes('hub')) {
      forceSetScope()
    }
    return 'USER'
  }
}
