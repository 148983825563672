import styled from 'styled-components'

import {
  Smashcast,
  Twitch,
  Youtube,
  Rumble,
  YoutubeLive,
  Image,
  Facebook,
  YoutubeExtract,
  Vimeo,
} from './Providers'

const StyledMediaWrapper = styled.div`
  padding-top: 56.25%;
  position: relative;
`

const getComponent = (type: any) => {
  switch (type) {
    case 'smashcast':
      return Smashcast
    case 'twitch':
      return Twitch
    case 'youtube_live':
      return YoutubeLive
    case 'youtube_video':
      return Youtube
    case 'rumble_video':
      return Rumble
    case 'cause_video':
    case 'fundraising_event_video':
      return YoutubeExtract
    case 'facebook':
      return Facebook
    case 'image':
    case 'cause_image':
    case 'fundraising_event_image':
      return Image
    case 'vimeo':
      return Vimeo
    default:
      return undefined
  }
}

export const MediaEmbed = ({ livestream, ...rest }: any) => {
  if (!livestream) return null

  const PlayerEmbed = getComponent(livestream.type)
  if (!PlayerEmbed) return null

  return (
    <StyledMediaWrapper {...rest}>
      <PlayerEmbed channel={livestream.channel} />
    </StyledMediaWrapper>
  )
}
