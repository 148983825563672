import { rem } from 'polished'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Grid } from '@tiltify/ui'

import { AccentBar, Heading, MaxWidthWrapper } from '../styles'
import { SingleGame } from './SingleGame'
import { games } from './games2023'

const StyledGrid = styled(Grid)`
  min-height: 200px;
  padding-bottom: 1rem;
  display: flex;
  position: relative;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`

const SubHeading = styled.p`
  font-size: ${rem(20)};
  line-height: 32px;
  color: #333;
  text-align: center;
  max-width: 720px;
  margin: 0 auto;
  margin-bottom: 2rem;
`

const ViewAll = styled(Link)`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 1px;
  text-decoration-line: underline;
  color: #e21350;
`

export const Games = ({ viewAll = false }: { viewAll?: boolean }): JSX.Element => {
  return (
    <MaxWidthWrapper>
      <Heading>The Jingle Jam 2023 Games Collection</Heading>
      <AccentBar />
      <SubHeading>
        Below is our amazing collection for 2023. Please make sure to redeem your codes before they
        expire on the 31st December 2023. <br />
        <br />
        Thank you to all of the generous publishers, developers and partners who contributed to this
        year&apos;s collection!
      </SubHeading>
      <StyledGrid itemWidth='264px'>
        {games.slice(0, viewAll ? 999 : 16).map((c, i) => {
          return (
            <SingleGame
              image={c.image}
              name={c.name}
              dev={c.dev}
              detail={c.detail}
              link={c.link}
              key={i}
            />
          )
        })}
      </StyledGrid>
      {!viewAll && (
        <ButtonContainer>
          <ViewAll to='games'>VIEW ALL GAMES</ViewAll>
        </ButtonContainer>
      )}
    </MaxWidthWrapper>
  )
}
