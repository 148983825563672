import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { Button } from '@tiltify/design/components/Button'
import {
  useAuthenticationDispatch,
  useAuthenticationState,
} from '@tiltify/shared/contexts/AuthenticationContext'
import { layoutMessages } from '@tiltify/ui/translations'

const StyledAuth = styled.div`
  display: flex;
  & > button:first-of-type {
    margin-right: 0.25rem;
  }
`

export const AuthOptions = (): JSX.Element => {
  const { loading } = useAuthenticationState()
  const authenticationDispatch = useAuthenticationDispatch()
  const { formatMessage } = useIntl()

  const handleLoginButton = () => {
    authenticationDispatch({
      type: useAuthenticationDispatch.ACTION_TYPES.SHOW_AUTHENTICATION,
      item: true,
    })
  }

  if (loading) return <div />

  return (
    <StyledAuth id='gtm_auth-buttons'>
      <Button
        id='signInButton'
        text={formatMessage(layoutMessages.signInText)}
        onClick={handleLoginButton}
      />
    </StyledAuth>
  )
}
