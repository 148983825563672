import { ApolloError } from '@apollo/client'

interface IRouteData {
  cause?: TiltifyPublicCause
  fundraisingEvent?: TiltifyPublicFundraisingEvent
  loading: boolean
  error?: ApolloError | string
}

export const formatQueryData = (
  cause: TiltifyPublicCause,
  fundraisingEvent: TiltifyPublicFundraisingEvent,
  loading: boolean,
  error?: ApolloError
): IRouteData => {
  let routeData = {
    cause: undefined,
    fundraisingEvent: undefined,
  } as IRouteData

  if (loading) {
    routeData = { ...routeData, loading: true }
  } else if (cause && fundraisingEvent) {
    routeData = {
      ...routeData,
      cause: cause,
      fundraisingEvent: { ...fundraisingEvent },
    }
  } else if (cause) {
    routeData = { ...routeData, cause: cause }
  } else {
    routeData = { ...routeData, loading: false, error: error || 'Invalid Route' }
  }

  return routeData
}
