import styled from 'styled-components'

const StyledSVG = styled.svg<{ fillColor: string; strokeColor: string }>`
  fill: ${({ fillColor }) => fillColor};
  stroke: ${({ strokeColor }) => strokeColor};
`

export function Close({ ariaHidden, stroke, fill, focusable, height, title, width, ...rest }: any) {
  const h = height || '1rem'
  const w = width || '1rem'

  return (
    <StyledSVG
      id='closeIcon'
      viewBox='0 0 32 32'
      width={w}
      height={h}
      aria-hidden={ariaHidden}
      focusable={focusable}
      fillColor={fill}
      strokeColor={stroke}
      {...rest}
    >
      {title ? <title>{title}</title> : null}
      <path
        d='M6 6l19.799 19.799M6 25.799L25.799 6'
        strokeWidth='2'
        strokeLinecap='round'
        fill='none'
      />
    </StyledSVG>
  )
}

Close.defaultProps = {
  ariaHidden: false,
  focusable: false,
  stroke: '#000',
  fill: '#000',
}
