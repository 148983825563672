import { get } from 'lodash'
import { rem } from 'polished'
import React, { useEffect, useId } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import styled, { css } from 'styled-components'

import { statusMessages } from '@tiltify/translations'

import { StyledError, StyledHelperMessage, StyledInputWrapper, StyledLabel } from '../styles'

const StyledField = styled.input<{
  errorState: boolean
  showCustomErrors: boolean
  as?: React.ElementType
  size: string
}>`
  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          padding: 6px 16px;
          font-size: ${rem(14)};
          line-height: ${rem(20)};
        `
      case 'large':
        return css`
          padding: 11px 15px 11px 15px;
          font-size: ${rem(16)};
          line-height: ${rem(24)};
        `
      default:
        return css`
          padding: 11px 15px 11px 15px;
          font-size: ${rem(16)};
          line-height: ${rem(24)};
        `
    }
  }}

  width: 100%;
  background: ${({ theme }) => (theme.isDark ? '#232628' : theme.colors.white.core)};
  border: 1px solid ${({ theme }) => theme.colors.black[400]};
  box-sizing: border-box;
  border-radius: 4px;
  color: ${({ theme }) => (theme.isDark ? '#ffffff' : theme.colors.black[600])};
  transition: all 0.2s ease-in-out;
  margin-bottom: 0;

  &&:focus-visible {
    outline: 1px solid ${({ theme }) => theme.colors.blue[400]};
  }

  ${({ showCustomErrors, errorState, theme }) =>
    errorState &&
    css`
      padding: 10px 14px 10px 14px;
      border: 2px solid ${theme.colors.error.core};
      ${!showCustomErrors &&
      css`
        border-radius: 4px 4px 0px 0px;
      `}
    `}

  &&:disabled {
    background-color: ${({ theme }) => theme.colors.black[100]};
    border-color: ${({ theme }) => theme.colors.black[800]};
    color: ${({ theme }) => theme.colors.black[500]};
  }
`

export interface ITextInput {
  autoComplete?: string
  className?: string
  customErrors?: React.ReactNode
  deps?: string | string[]
  disabled?: boolean
  fixedWidth?: boolean
  focusOnLoad?: boolean
  forwardedAs?: React.ElementType
  helperText?: string
  inputMode?: 'text' | 'numeric'
  label: string
  maxValue?: number
  minValue?: number
  maxLength?: number
  minLength?: number
  name: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  pattern?: { value: RegExp; message: string }
  placeholder?: string
  readOnly?: boolean
  required?: boolean | string
  rowCount?: number
  showCustomErrors?: boolean
  type?: 'text' | 'password' | 'number'
  size?: 'large' | 'small'
  validate?:
    | Record<string, (value: string) => boolean | string>
    | ((value: string) => boolean | string)
    | Record<string, (value: string, formValues: Record<string, unknown>) => boolean | string>
    | ((value: string, formValues: Record<string, unknown>) => boolean | string)
}

export const TextInput = ({
  autoComplete,
  className,
  customErrors,
  deps,
  disabled = false,
  fixedWidth = true,
  focusOnLoad = false,
  forwardedAs = 'input',
  helperText,
  inputMode = 'text',
  label,
  maxLength = 255,
  minLength,
  name,
  onChange,
  pattern,
  placeholder = '',
  required = false,
  rowCount = 5,
  showCustomErrors = false,
  type = 'text',
  size = 'large',
  maxValue,
  minValue,
  validate,
  readOnly = false,
}: ITextInput) => {
  const { formatMessage } = useIntl()
  const {
    register,
    formState: { errors },
    setFocus,
  } = useFormContext()
  const id = useId()
  const error = get(errors, name)

  useEffect(() => {
    if (focusOnLoad && setFocus) setFocus(name)
  }, [focusOnLoad, name, setFocus])

  const numberValidations: {
    max?: { value: number; message: string }
    min?: { value: number; message: string }
  } = {}

  if (maxValue || maxValue === 0) {
    numberValidations.max = {
      value: maxValue,
      message: formatMessage(statusMessages.maxValue, { max: maxValue }),
    }
  }

  if (minValue || minValue === 0) {
    numberValidations.min = {
      value: minValue,
      message: formatMessage(statusMessages.minValue, { min: minValue }),
    }
  }

  return (
    <StyledInputWrapper className={className} fixedWidth={fixedWidth}>
      {label && (
        <StyledLabel htmlFor={id}>{`${label}${
          !required && !disabled ? ' (optional)' : ''
        }`}</StyledLabel>
      )}
      <StyledField
        id={id}
        as={forwardedAs}
        autoComplete={autoComplete}
        placeholder={placeholder}
        disabled={disabled}
        type={type}
        errorState={!showCustomErrors && !!error}
        showCustomErrors={!!showCustomErrors}
        maxLength={maxLength}
        rows={rowCount}
        inputMode={inputMode}
        readOnly={readOnly}
        size={size}
        {...register(name, {
          onChange: onChange,
          maxLength: {
            value: maxLength,
            message: formatMessage(statusMessages.maxLength, { max: maxLength }),
          },
          minLength: minLength && {
            value: minLength,
            message: formatMessage(statusMessages.minLength, { min: minLength }),
          },
          pattern,
          required: required ? formatMessage(statusMessages.required) : false,
          validate: required
            ? validate
              ? typeof validate === 'object'
                ? {
                    ...validate,
                    whitespace: (value) => !!String(value).trim() || formatMessage(statusMessages.required),
                  }
                : {
                    validateFunction: validate,
                    whitespace: (value) => !!String(value).trim() || formatMessage(statusMessages.required),
                  }
              : (value) => !!String(value).trim() || formatMessage(statusMessages.required)
            : validate,
          deps,
          ...numberValidations,
        })}
      />
      {!showCustomErrors ? (
        <StyledError errorState={!!error}>{error ? (error.message as string) : null}</StyledError>
      ) : null}
      {helperText ? <StyledHelperMessage>{helperText}</StyledHelperMessage> : null}
      {showCustomErrors ? customErrors : null}
    </StyledInputWrapper>
  )
}
