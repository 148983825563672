export const colors = {
  white: {
    core: '#FFFFFF',
  },
  blue: {
    50: '#F4F5FF',
    100: '#D0D8FD',
    200: '#A1B1FB',
    300: '#728BF8',
    400: '#4364F6',
    core: '#143DF4',
    600: '#092DCA',
    700: '#072197',
    800: '#051665',
    900: '#020B32',
  },
  green: {
    50: '#F5FFF4',
    100: '#D8FDD0',
    200: '#B1FBA1',
    300: '#8BF872',
    400: '#64F643',
    500: '#3DF414',
    core: '#2DCA09',
    700: '#219707',
    800: '#166505',
    900: '#0B3202',
  },
  black: {
    50: '#F8F8F9',
    100: '#E3E3E5',
    200: '#C7C7CB',
    300: '#ACACB1',
    400: '#909098',
    500: '#74747D',
    600: '#5B5B61',
    700: '#414146',
    800: '#27272A',
    core: '#0D0D0E',
  },
  satin: {
    50: '#FDFDFC',
    100: '#FBFAF8',
    200: '#F6F6F1',
    300: '#F2F1EA',
    400: '#EDEDE3',
    500: '#E9E8DC',
  },
  pink: {
    50: '#FFE7F3',
    100: '#FFCFE6',
    200: '#FF9FCC',
    300: '#FF6FB3',
    400: '#FF3F99',
    500: '#FF0F80',
    600: '#D80066',
    700: '#A2004C',
    800: '#6C0033',
    900: '#360019',
  },
  error: {
    50: '#FFF4F5',
    100: '#FDD0D8',
    200: '#FBA1B1',
    300: '#F8728B',
    400: '#F64364',
    500: '#F4143D',
    core: '#CA092D',
    700: '#970721',
    800: '#650516',
    900: '#32020B',
  },
  warning: {
    50: '#FFFDF4',
    100: '#FDF5D0',
    200: '#FBEAA1',
    300: '#F8E072',
    400: '#F6D543',
    core: '#F4CB14',
    600: '#CAA709',
    700: '#977D07',
    800: '#655305',
    900: '#322A02',
  },
}

const depth = {
  4: '0px 2px 6px rgba(50, 53, 58, 0.13), 0px 1px 1px rgba(50, 53, 58, 0.1)',
  8: '0px 6px 16px rgba(50, 53, 58, 0.13), 0px 1px 1px rgba(50, 53, 58, 0.1)',
  16: '0px 6px 20px rgba(50, 53, 58, 0.13), 0px 4px 14px rgba(50, 53, 58, 0.1)',
  32: '0px 12px 32px rgba(50, 53, 58, 0.15), 0px 6px 16px rgba(50, 53, 58, 0.1)',
}

const transition = {
  0: 'transition: all 0.2s ease-in-out;',
}

/* breakpoints: 375px, 744px, 1024px, 1200px, 1440px */

const themeConstants = {
  breakpoints: ['23.4em', '46.5em', '64em', '75em', '90em'],
  contentMaxWidth: '1110px',
  colors,
  depth,
  transition,
}

export const light = {
  ...themeConstants,
  isDark: false,
}

export const dark = {
  ...themeConstants,
  isDark: true,
}
