import { useEffect, useRef, useState } from 'react'
import styled, { useTheme } from 'styled-components'

import { useOnOutsideClick } from '../../hooks'
import { Close } from '../../icons/Close'
import { Heading } from '../Heading'
import { Portal } from './Portal'

const ModalContainer = styled.div`
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: RGBA(13, 13, 14, 0.92);
`

const ModalContent = styled.div`
  margin-top: 4rem;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.depth[4]};
  background-color: ${({ theme }) =>
    theme.isDark ? theme.colors.black[800] : theme.colors.white.core};
  box-sizing: border-box;
  min-width: 540px;

  @media ${({ theme }) => `(max-width: ${theme.breakpoints[1]})`} {
    min-width: auto;
  }

  @media ${({ theme }) => `(max-width: ${theme.breakpoints[0]})`} {
    width: 100vw;
    min-height: 100vh;
    margin-top: 0;
  }
`

const SubHeading = styled(Heading)`
  color: ${({ theme }) => theme.colors.black[500]};
  max-width: 521px;
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  gap: 6rem;
  border-bottom: solid 1px ${({ theme }) => theme.colors.black[100]};
`

const CloseButton = styled.button`
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0;

  cursor: pointer;
  ${({ disabled }) => disabled && '& > svg{opacity: 0.5;}'}
`

const ModalBody = styled.div`
  flex: 1;
  padding: 2rem 1.5rem;
`

const ButtonWrapper = styled.div`
  border-top: solid 1px ${({ theme }) => theme.colors.black[100]};
  padding: 1.5rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`

interface IModal {
  setIsVisible: (value: boolean) => void
  children: JSX.Element
  buttonChildren?: JSX.Element
  skipOutsideClick?: boolean
  scrollLock?: boolean
  title: string
  subtitle?: string
  locked?: boolean
  className?: string
}

export const Modal = ({
  setIsVisible,
  children,
  buttonChildren,
  skipOutsideClick = false,
  scrollLock,
  title,
  subtitle,
  locked,
  className,
}: IModal) => {
  const handleClose = () => setIsVisible(false)
  const [focusTrapped, setFocusTrapped] = useState(false)
  const theme = useTheme()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    function keyListener(e: any) {
      const listener = keyListenersMap.get(e.key)
      return listener && listener(e)
    }

    document.addEventListener('keydown', keyListener)

    return () => document.removeEventListener('keydown', keyListener)
  })

  const modalRef = useRef<HTMLDivElement>(null)

  useOnOutsideClick(modalRef, handleClose, skipOutsideClick)

  const handleTabKey = (e: any) => {
    if (modalRef.current) {
      const focusableModalElements = modalRef.current.querySelectorAll(
        'a[href], button, textarea,   input[type="text"], input[type="radio"], input[type="checkbox"], select'
      ) as NodeListOf<HTMLElement>
      const firstElement = focusableModalElements[0]
      const lastElement = focusableModalElements[focusableModalElements.length - 1]

      if (!focusTrapped) {
        if (Array.from(focusableModalElements).find((e) => e === document.activeElement)) {
          setFocusTrapped(true)
        } else {
          firstElement.focus()
          setFocusTrapped(true)
        }
      }

      if (focusableModalElements.length === 1) {
        firstElement.focus()
        return e.preventDefault()
      }

      if (!e.shiftKey && document.activeElement === lastElement) {
        firstElement.focus()
        return e.preventDefault()
      }

      if (e.shiftKey && document.activeElement === firstElement) {
        lastElement.focus()
        e.preventDefault()
      }
    }
  }

  const keyListenersMap = new Map([
    ['Escape', () => setIsVisible(false)],
    ['Tab', handleTabKey],
  ])

  return (
    <Portal scrollLock={scrollLock}>
      <ModalContainer role='dialog' aria-modal='true' className={className}>
        <ModalContent id='modal-content' ref={modalRef}>
          <ModalHeader>
            <div>
              <Heading type='2'>{title}</Heading>
              <SubHeading type='sub'>{subtitle}</SubHeading>
            </div>
            <CloseButton title='close modal' onClick={handleClose} disabled={locked}>
              <Close
                size='small'
                title='close'
                fillType={theme.isDark ? 'white.core' : 'black.400'}
              />
            </CloseButton>
          </ModalHeader>
          <ModalBody>{children}</ModalBody>
          {buttonChildren && <ButtonWrapper>{buttonChildren}</ButtonWrapper>}
        </ModalContent>
      </ModalContainer>
    </Portal>
  )
}
