import { defineMessages } from 'react-intl'

export const statusMessages = defineMessages({
  error429: {
    id: 'StatusMessages.Error.Error429',
    defaultMessage: 'Number of tries exceeded, try again later.',
    description: 'Message for rate limit exceeded',
  },
  error: {
    id: 'StatusMessages.Error.Error',
    defaultMessage: 'Something went wrong.',
    description: 'Message for generic error',
  },
  internalServerError: {
    id: 'StatusMessages.Error.InternalServerError',
    defaultMessage: 'Internal server error',
    description: 'Error text when server is unreachable',
  },
  invalidEmailOrPassword: {
    id: 'StatusMessages.Error.InvalidEmailOrPassword',
    defaultMessage: 'Email or password is invalid.',
    description: 'Error text when login email or password is invalid',
  },
  submitting: {
    id: 'StatusMessages.Status.Submitting',
    defaultMessage: 'submitting',
    description: 'Status message for submitting',
  },
  copiedToClipboard: {
    id: 'StatusMessages.Status.CopiedToClipboard',
    defaultMessage: 'Copied to clipboard',
    description: 'Label text for the successful copy to clipboard',
  },
  error404Title: {
    id: 'StatusMessages.Error.404Title',
    defaultMessage: "It's not you, it's us",
    description: 'Heading for error 404 page',
  },
  error404Body: {
    id: 'StatusMessages.Error.404Body',
    defaultMessage:
      "The page you were looking for isn't available. You can use the navigation bar above to find what you are looking for.",
    description: 'Limited description for error 404 page',
  },
  invalidEmail: {
    id: 'StatusMessages.Error.InvalidEmail',
    defaultMessage: 'Email address is not formatted correctly',
    description: 'The text displayed when the email address entered is not formatted correctly.',
  },
  passwordConfirmation: {
    id: 'StatusMessages.Error.PasswordConfirmation',
    defaultMessage: 'Passwords do not match.',
    description: 'The text displayed when a password does not match the password confirmation',
  },
  required: {
    id: 'StatusMessages.Error.Required',
    defaultMessage: 'Required',
    description: 'The text displayed when a required input field has no value.',
  },
  // Computed Translation
  maxLength: {
    id: 'StatusMessages.Error.MaxLength',
    defaultMessage: 'Must be less than {max} characters',
    description: 'Text displayed when the value is not less than a given length',
  },
  // Computed Translation
  minLength: {
    id: 'StatusMessages.Error.MinLength',
    defaultMessage: 'Must be at least {min} characters',
    description: 'The text displayed when the minimum length has not been entered.',
  },
  // Computed Translation
  minValue: {
    id: 'StatusMessages.Error.MinValue',
    defaultMessage: 'Minimum {min}',
    description: 'The text displayed when the minimum has not been entered.',
  },
  // Computed Translation
  maxValue: {
    id: 'StatusMessages.Error.MaxValue',
    defaultMessage: 'Maximum {max}',
    description: 'The text displayed when the maximum has not been entered.',
  },
  missingUpperCase: {
    id: 'StatusMessages.Error.UpperCase',
    defaultMessage: 'Must contain a capital (uppercase) letter',
    description: 'The text displayed when an uppercase letter has not been entered.',
  },
  missingLowerCase: {
    id: 'StatusMessages.Error.LowerCase',
    defaultMessage: 'Must contain a lowercase letter',
    description: 'The text displayed when an lowercase letter has not been entered.',
  },
  missingSymbol: {
    id: 'StatusMessages.Error.Symbol',
    defaultMessage: 'Must contain a special character [!@#$%^&*]',
    description: 'The text displayed when an symbol has not been entered.',
  },
  missingNumber: {
    id: 'StatusMessages.Error.Number',
    defaultMessage: 'Must contain a number',
    description: 'The text displayed when an number has not been entered.',
  },
  campaignValidationError: {
    id: 'StatusMessages.Error.CampaignValidationError',
    defaultMessage: 'Campaign name must contain at least 4 letters',
    description: 'Validation error for campaign name',
  },
  campaignReservedWordError: {
    id: 'StatusMessages.Error.CampaignReservedWordError',
    defaultMessage: 'Campaign name cannot be a reserved word.',
    description: 'Validation error for campaign name',
  },
  campaignSlugTaken: {
    id: 'StatusMessages.Error.CampaignSlugTaken',
    defaultMessage: 'Campaign URL is already being used.',
    description: 'Validation error for campaign slug taken',
  },
  slugValidationError: {
    id: 'StatusMessages.Error.SlugValidationError',
    defaultMessage: 'Campaign url must contain at least 4 letters',
    description: 'Validation error for campaign name',
  },
  urlValidation: {
    id: 'StatusMessages.Error.URLValidation',
    defaultMessage: 'Url is invalid, make sure https is included',
    description: 'Validation error for url',
  },
  uniqueMilestoneError: {
    id: 'StatusMessages.Error.UniqueMilestoneError',
    defaultMessage: 'Milestone names must be unique.',
    description: 'Error text when a milestone name has been duplicated.',
  },
  invalidCampaign: {
    id: 'StatusMessages.Error.InvalidCampaign',
    defaultMessage: 'This campaign cannot be supported.',
    description: 'Error displayed when a campaign is not in a status to be supported.',
  },
  invalidFundraisingEvent: {
    id: 'StatusMessages.Error.InvalidFundraisingEvent',
    defaultMessage: 'This fundraising event cannot be supported.',
    description: 'Error displayed when a fundraising event is not in a status to be supported.',
  },
  secondsAgo: {
    id: 'StatusMessages.Status.SecondsAgo',
    defaultMessage: 'a few seconds ago',
    description: 'ending text for timer',
  },
  // Computed Translation
  minutesAgo: {
    id: 'StatusMessages.Status.Mins',
    defaultMessage: '{mins, plural, one {# min ago} other {# mins ago}}',
    description: 'abbreviation for multiple minutes',
  },
  // Computed Translation
  hoursAgo: {
    id: 'StatusMessages.Status.HoursAgo',
    defaultMessage: '{hours, plural, one {# hour ago} other {# hours ago}}',
    description: 'ending hour text for timer',
  },
})
