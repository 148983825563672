import { Route, Routes } from 'react-router-dom'
import styled from 'styled-components/macro'

import { Nav } from '@tiltify/shared/components/OldNav'
import { GATrackers, Helmet } from '@tiltify/ui'

import { About } from 'components/About'
import { AllCampaignsComponent } from 'components/AllCampaigns'
import { CampaignSliderContainer } from 'components/CampaignSlider'
import { Causes } from 'components/Causes'
import { Games } from 'components/Games'
import { Hero } from 'components/Hero'
import { Leaderboard } from 'components/Leaderboard'
import { MediaDisplay } from 'components/MediaDisplay'
import { PoweredBy } from 'components/PoweredBy'
import { Schedule } from 'components/Schedule'
import { Sponsors } from 'components/Sponsors'
import { StartFundraising } from 'components/StartFundraising'
import { useRouteContext } from 'contexts/RouteContext'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
`

const StyledNav = styled(Nav)`
  color: #fff;
  background: #fff;
  z-index: 999;
  position: relative;
  width: 100%;

  nav button {
    border-radius: 4px;
  }
  & svg > path {
    stroke: #e21350;
  }

  #closeIcon path {
    fill: #000 !important;
    stroke: #000 !important;
  }

  & #signInButton {
    color: #fff;
    background-color: #e21350;
    border-color: #e21350;
    padding: 11px 24px;
    border-radius: 8px;
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 14px;
    text-decoration: none;

    div {
      font-weight: bold;
      top: 5px;
    }
  }
`

const App = (): JSX.Element => {
  const { fundraisingEvent, cause } = useRouteContext()
  return (
    <Wrapper>
      <GATrackers
        cause={cause}
        fundraisingEvent={fundraisingEvent}
        tracker={{
          trackingId: fundraisingEvent?.trackers && fundraisingEvent?.trackers[0],
          gaOptions: { name: 'activation', userId: fundraisingEvent?.legacyFundraisingEventId },
        }}
      />
      <Helmet
        description='Jingle Jam is fundraising on Tiltify'
        title='Jingle Jam'
        image={fundraisingEvent?.avatar?.src}
      />
      <StyledNav withNavItems={false} />
      <Routes>
        <Route index element={<AppMain />} />
        <Route path='games' element={<Games viewAll />} />
        <Route path='campaigns' element={<AllCampaignsComponent />} />
      </Routes>
    </Wrapper>
  )
}

const AppMain = (): JSX.Element => {
  return (
    <Wrapper>
      <Hero />
      <MediaDisplay />
      <Schedule />
      <Games />
      <Causes />
      <StartFundraising />
      <Leaderboard />
      <CampaignSliderContainer />
      <Sponsors />
      <About />
      <PoweredBy />
    </Wrapper>
  )
}

export default App
