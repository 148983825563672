import { useTheme } from 'styled-components'

import { useFillColor, useSize } from './IconHooks'

export const CaretDown = ({
  fill,
  fillType = 'black.50',
  height,
  width,
  size = 'small',
  title = 'Down',
}: TiltifyIcon) => {
  const theme = useTheme()
  const sizeValues = useSize(size, height, width)
  const fillValue = useFillColor(theme, fillType, fill)

  return (
    <svg
      width={sizeValues.width}
      height={sizeValues.height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>{title}</title>
      <path
        d='M8.10118 8.83079L4.56118 5.29079C4.37381 5.10454 4.12036 5 3.85618 5C3.59199 5 3.33854 5.10454 3.15118 5.29079C3.05745 5.38376 2.98305 5.49436 2.93228 5.61622C2.88152 5.73807 2.85538 5.86878 2.85538 6.00079C2.85538 6.1328 2.88152 6.26351 2.93228 6.38537C2.98305 6.50723 3.05745 6.61783 3.15118 6.71079L7.39118 10.9508C7.48414 11.0445 7.59474 11.1189 7.7166 11.1697C7.83846 11.2205 7.96916 11.2466 8.10118 11.2466C8.23319 11.2466 8.36389 11.2205 8.48575 11.1697C8.60761 11.1189 8.71821 11.0445 8.81118 10.9508L13.1012 6.71079C13.1939 6.61735 13.2672 6.50654 13.3169 6.3847C13.3667 6.26286 13.3919 6.1324 13.3912 6.00079C13.3919 5.86919 13.3667 5.73872 13.3169 5.61689C13.2672 5.49505 13.1939 5.38423 13.1012 5.29079C12.9138 5.10454 12.6604 5 12.3962 5C12.132 5 11.8785 5.10454 11.6912 5.29079L8.10118 8.83079Z'
        fill={fillValue}
      />
    </svg>
  )
}
