import styled from 'styled-components'

import { Button } from '@tiltify/design/components/Button'
import { Form } from '@tiltify/design/components/Forms/Form'
import { TextInput } from '@tiltify/design/components/Forms/TextInput'
import { Heading } from '@tiltify/design/components/Heading'
import { Paragraph } from '@tiltify/design/components/Paragraph'
import { useNotificationContext } from '@tiltify/shared/contexts/NotificationContextProvider'

import AuthenticationService from '../../../services/ApiService/AuthenticationService'

const InputWrapper = styled.div`
  margin: 1rem auto;
`

export const MFAVerify = ({
  refetch,
  setShowVerify,
  onSuccess,
}: {
  refetch?: () => void
  setShowVerify: (showVerify: boolean) => void
  onSuccess?: (user: AuthenticatedUser) => void
}) => {
  const service = new AuthenticationService()
  const { triggerNotification } = useNotificationContext()

  const onVerify = (values: any) => {
    service
      .mfaVerify(values)
      .then((result) => {
        if (result.status === 200 && result.ok) {
          return result.json()
        }
        triggerNotification('Please enter the code again', true)
      })
      .then((result) => {
        if (result.user) {
          if (refetch) {
            refetch()
          }
          if (onSuccess) {
            onSuccess(result.user)
          }
        }
      })
      .catch((err) => {
        throw err
      })
  }

  return (
    <Form initialValues={{ code: '' }} onSubmit={onVerify}>
      <div>
        <Heading type='2'>Two-factor authentication</Heading>
        <Paragraph>Open your authentication app and enter the code below</Paragraph>
        <InputWrapper>
          <TextInput name='code' label='Authentication code' required />
        </InputWrapper>
        <div style={{ display: 'flex', gap: '1rem' }}>
          <Button
            text='back'
            iconPosition='left'
            icon='arrow-left'
            buttonStyle='text'
            size='small'
            onClick={() => setShowVerify(false)}
          />
          <Button intent='primary' size='small' type='submit' text='Submit' />
        </div>
      </div>
    </Form>
  )
}
