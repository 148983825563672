import React, { useCallback, useLayoutEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'

import { dark, light } from '@tiltify/design/theme'

interface IThemeToggleContext {
  toggle: () => void
}

const ThemeToggleContext = React.createContext<IThemeToggleContext | undefined>(undefined)

interface IThemeToggleContextProvider {
  children: React.ReactNode
  lightModeOnly?: boolean
}

const ThemeToggleContextProvider = ({
  children,
  lightModeOnly = false,
}: IThemeToggleContextProvider): JSX.Element => {
  const LOCAL_STORAGE_KEY = '@@tiltify/ui/theme'
  const [isDark, toggleTheme] = useState(false)

  const setInitialTheme = useCallback(
    (theme: string) =>
      validate(theme) === 'dark' && !lightModeOnly ? toggleTheme(true) : toggleTheme(false),
    [lightModeOnly]
  )

  const loadTheme = useCallback(() => {
    try {
      if (window.localStorage && window.localStorage.getItem(LOCAL_STORAGE_KEY)) {
        const value = window.localStorage.getItem(LOCAL_STORAGE_KEY)
        return validate(value)
      }
    } catch {
      // Do nothing
    }

    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      return 'dark'
    }

    return 'light'
  }, [])

  useLayoutEffect(() => {
    const theme = loadTheme()
    setInitialTheme(theme)
  }, [loadTheme, setInitialTheme])

  const validate = (theme: string | null): string => {
    if (theme === 'dark') return 'dark'
    if (theme === 'light') return 'light'
    return 'light'
  }

  const saveTheme = (theme: string) => {
    try {
      window.localStorage.setItem(LOCAL_STORAGE_KEY, validate(theme))
    } catch {
      return
    }
  }

  const toggle = () => {
    toggleTheme(!isDark)
    saveTheme(isDark ? 'light' : 'dark')
  }

  const themeContext = {
    toggle,
  }

  return (
    <ThemeToggleContext.Provider value={themeContext}>
      <ThemeProvider theme={isDark ? (dark as any) : (light as any)}>{children}</ThemeProvider>
    </ThemeToggleContext.Provider>
  )
}

const useThemeToggleContext = () => {
  const context = React.useContext(ThemeToggleContext)
  if (context === undefined) {
    throw new Error('useNotificationContext must be used within a NotificationContextProvider')
  }
  return context
}

export { ThemeToggleContextProvider, useThemeToggleContext }
