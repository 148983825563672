import { useTheme } from 'styled-components'

import { useFillColor, useSize } from './IconHooks'

export const Ellipsis = ({
  fill,
  fillType = 'black.50',
  size = 'small',
  height,
  width,
  title = 'Ellipsis',
}: TiltifyIcon) => {
  const theme = useTheme()
  const sizeValues = useSize(size, height, width)
  const fillValue = useFillColor(theme, fillType, fill)

  return (
    <svg
      width={sizeValues.width}
      height={sizeValues.height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>{title}</title>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.99996 7.66667C3.99996 8.03486 3.70148 8.33333 3.33329 8.33333C2.9651 8.33333 2.66663 8.03486 2.66663 7.66667C2.66663 7.29848 2.9651 7 3.33329 7C3.70148 7 3.99996 7.29848 3.99996 7.66667ZM7.99998 8.33333C8.36817 8.33333 8.66665 8.03486 8.66665 7.66667C8.66665 7.29848 8.36817 7 7.99998 7C7.63179 7 7.33331 7.29848 7.33331 7.66667C7.33331 8.03486 7.63179 8.33333 7.99998 8.33333ZM12.6666 8.33333C13.0348 8.33333 13.3333 8.03486 13.3333 7.66667C13.3333 7.29848 13.0348 7 12.6666 7C12.2984 7 12 7.29848 12 7.66667C12 8.03486 12.2984 8.33333 12.6666 8.33333Z'
        fill={fillValue}
      />
      <path
        d='M3.33329 9.33333C4.25377 9.33333 4.99996 8.58714 4.99996 7.66667H2.99996C2.99996 7.48257 3.1492 7.33333 3.33329 7.33333V9.33333ZM1.66663 7.66667C1.66663 8.58714 2.41282 9.33333 3.33329 9.33333V7.33333C3.51739 7.33333 3.66663 7.48257 3.66663 7.66667H1.66663ZM3.33329 6C2.41282 6 1.66663 6.74619 1.66663 7.66667H3.66663C3.66663 7.85076 3.51739 8 3.33329 8V6ZM4.99996 7.66667C4.99996 6.74619 4.25377 6 3.33329 6V8C3.1492 8 2.99996 7.85076 2.99996 7.66667H4.99996ZM7.66665 7.66667C7.66665 7.48257 7.81588 7.33333 7.99998 7.33333V9.33333C8.92045 9.33333 9.66665 8.58714 9.66665 7.66667H7.66665ZM7.99998 8C7.81588 8 7.66665 7.85076 7.66665 7.66667H9.66665C9.66665 6.74619 8.92045 6 7.99998 6V8ZM8.33331 7.66667C8.33331 7.85076 8.18407 8 7.99998 8V6C7.0795 6 6.33331 6.74619 6.33331 7.66667H8.33331ZM7.99998 7.33333C8.18407 7.33333 8.33331 7.48257 8.33331 7.66667H6.33331C6.33331 8.58714 7.0795 9.33333 7.99998 9.33333V7.33333ZM12.3333 7.66667C12.3333 7.48257 12.4825 7.33333 12.6666 7.33333V9.33333C13.5871 9.33333 14.3333 8.58714 14.3333 7.66667H12.3333ZM12.6666 8C12.4825 8 12.3333 7.85076 12.3333 7.66667H14.3333C14.3333 6.74619 13.5871 6 12.6666 6V8ZM13 7.66667C13 7.85076 12.8507 8 12.6666 8V6C11.7462 6 11 6.74619 11 7.66667H13ZM12.6666 7.33333C12.8507 7.33333 13 7.48257 13 7.66667H11C11 8.58714 11.7462 9.33333 12.6666 9.33333V7.33333Z'
        fill={fillValue}
      />
    </svg>
  )
}
