export const Twitch = ({ ariaHidden, focusable, height, width, isDark, title, ...rest }: any) => {
  const h = height || '1rem'
  const w = width || '1rem'

  return (
    <svg
      viewBox='0 0 2400 2800'
      width={w}
      height={h}
      aria-hidden={ariaHidden}
      focusable={focusable}
      {...rest}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      xmlSpace='preserve'
    >
      {title ? <title>{title}</title> : null}
      <g>
        {!isDark && (
          <polygon
            fill='#FFFFFF'
            points='2200,1300 1800,1700 1400,1700 1050,2050 1050,1700 600,1700 600,200 2200,200'
          />
        )}
        <g>
          <g id='Layer_1-2' fill={isDark ? '#fff' : ''}>
            <path
              fill={isDark ? '' : '#9146FF'}
              d='M500,0L0,500v1800h600v500l500-500h400l900-900V0H500z M2200,1300l-400,400h-400l-350,350v-350H600V200h1600V1300z'
            />
            <rect fill={isDark ? '' : '#9146FF'} x='1700' y='550' width='200' height='600' />
            <rect fill={isDark ? '' : '#9146FF'} x='1150' y='550' width='200' height='600' />
          </g>
        </g>
      </g>
    </svg>
  )
}

Twitch.defaultProps = {
  isDark: false,
}
