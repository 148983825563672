import { MediaIframeEmbed } from '../MediaIframeEmbed'

export const Vimeo = ({ channel, ...rest }: any) => {
  if (!channel) return null
  const id = channel

  return <>
    <MediaIframeEmbed src={`https://player.vimeo.com/video/${id}?pip=false&portrait=false&byline=false&title=false`} {...rest} />
    <script src="https://player.vimeo.com/api/player.js"></script>
  </>
}
