import { rem } from 'polished'
import { Link } from 'react-router-dom'
import styled from /*, { css } */
'styled-components'

// import { useRouteContext } from 'contexts/RouteContext'

const StyledCampaignCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
  background-color: ${({ theme }) => theme.backgroundAccent};
  width: 100%;
  max-width: 592px;
  min-height: 350px;
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;

  @media (max-width: 400px) {
    width: 100%;
  }
`

const StyledStreamWrapper = styled.div`
  position: relative;
  justify-content: center;
  display: flex;
  padding: 1rem;
  margin-bottom: 1rem;

  img {
    max-width: 100%;
    max-height: 63px;
  }
`

const StyledDescription = styled.div`
  text-align: left;
`

const StyledCauseName = styled.div`
  font-size: ${rem(14)};
  text-align: center;
  line-height: 17px;
  margin-bottom: 15px;
`

const StyledCampaignName = styled.p`
  font-weight: bold;
  color: ${({ theme }) => theme.textColor};
  line-height: ${rem(20)};
  text-align: center;
  margin-top: 0;
  margin-bottom: 1rem;
`

const BottomContentWrapper = styled.div`
  padding: 0 ${rem(16)} ${rem(16)} ${rem(16)};
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem;
  margin-bottom: 2rem;
`

// const ViewAllButton = styled.a<{ $disabled?: boolean }>`
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 700;
//   font-size: 14px;
//   line-height: 14px;
//   text-align: center;
//   letter-spacing: 1px;
//   color: #e21350;
//   border: 2px solid #e21350;
//   border-radius: 100px;
//   text-decoration: none;
//   padding: 1rem;

//   ${({ $disabled }) =>
//     $disabled &&
//     css`
//       pointer-events: none;
//       cursor: pointer;
//       filter: grayscale();
//       opacity: 0.5;
//     `}
// `

const AllCampaignsLink = styled(Link)`
  color: #e21350;
  text-align: center;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 100% */
  letter-spacing: 1px;
  text-decoration-line: none;
  border: 2px solid #e21350;
  border-radius: 100px;
  padding: 1rem;
`

export const SupportingCard = ({
  cause,
}: {
  cause: { logo: string; description: string; name: string; region: string; url: string }
}): JSX.Element => {
  // const { fundraisingEvent } = useRouteContext()
  return (
    <StyledCampaignCard>
      <div>
        <StyledStreamWrapper>
          <img src={cause.logo} alt={cause.name} />
        </StyledStreamWrapper>
        <BottomContentWrapper>
          <StyledDescription>
            <StyledCampaignName>{cause.description}</StyledCampaignName>
            <StyledCauseName>{cause.name}</StyledCauseName>
          </StyledDescription>
        </BottomContentWrapper>
      </div>
      <div>
        <ButtonWrapper>
          {/* <ViewAllButton
            $disabled={!fundraisingEvent?.supportable}
            href={`https://tiltify.com/jingle-jam/start?regionId=${cause.region}`}
          >
            FUNDRAISE NOW
          </ViewAllButton> */}
          <AllCampaignsLink to={`/campaigns?regionId=${cause.region}`}>
            VIEW FUNDRAISERS
          </AllCampaignsLink>
        </ButtonWrapper>
      </div>
    </StyledCampaignCard>
  )
}
