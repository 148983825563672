import { rem } from 'polished'
import styled from 'styled-components'

import { Grid } from '@tiltify/ui'

import Displate from 'assets/displate.png'
import Fanatical from 'assets/fanatical.png'
import FourthFloor from 'assets/ff.png'
import Twitch from 'assets/twitch.png'
import TheYogs from 'assets/yogs.png'

import { AccentBar, Heading, MaxWidthWrapper } from '../styles'

const StyledHeading = styled(Heading)`
  text-align: left;
`

const StyledAccentBar = styled(AccentBar)`
  margin: 0 0 ${rem(19)} 0;
`

const Copy = styled.p`
  font-size: ${rem(20)};
  line-height: 28px;
  letter-spacing: 1px;
  color: #333333;
  max-width: 50%;

  @media (max-width: 1000px) {
    max-width: 100%;
  }
`

const StyledGrid = styled(Grid)`
  min-height: 200px;
  padding-bottom: 1rem;
`

const StyledLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
`

const SponsorImage = styled.div<{ src: string; height?: number; width?: number }>`
  height: ${({ height }) => (height ? `${height}px` : '129px')};
  width: ${({ width }) => (width ? `${width}px` : '129px')};
  background-image: ${({ src }) => `url(${src})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`
export const Sponsors = (): JSX.Element => {
  return (
    <div style={{ background: '#fff' }}>
      <MaxWidthWrapper>
        <StyledHeading>Jingle Jam 2023 Partners</StyledHeading>
        <StyledAccentBar />
        <Copy>
          Jingle Jam 2023 wouldn&apos;t be possible without the help and support of those listed
          below, and more!
        </Copy>
        <StyledGrid itemWidth='200px'>
          <StyledLink href='https://twitch.tv' target='_blank' rel='noopener noreferrer'>
            <SponsorImage src={Twitch} title='Twitch' />
          </StyledLink>
          <StyledLink href='https://www.yogscast.com/' target='_blank' rel='noopener noreferrer'>
            <SponsorImage src={TheYogs} title='The Yogs' />
          </StyledLink>
          <StyledLink
            href='https://fourthfloorcreative.co/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <SponsorImage src={FourthFloor} height={69} title='Fourth Floor' />
          </StyledLink>
          <StyledLink
            href='https://www.fanatical.com/en/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <SponsorImage src={Fanatical} height={69} title='Fanatical' />
          </StyledLink>
          <StyledLink href='https://displate.com/' target='_blank' rel='noopener noreferrer'>
            <SponsorImage src={Displate} height={69} title='Displate' />
          </StyledLink>
        </StyledGrid>
      </MaxWidthWrapper>
    </div>
  )
}
