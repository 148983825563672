import React, { useReducer, useEffect } from 'react'
import { ApolloError, useQuery } from '@apollo/client'

import { formatQueryData } from './utils'
import { FullWindowLoading } from '@tiltify/ui'
import { ACTION_TYPES, routeReducer } from './routeReducer'
import { get_cause_and_fe_by_slug } from '@tiltify/ui/gql/queries/custom'
interface IRouteContext {
  cause?: TiltifyPublicCause
  fundraisingEvent?: TiltifyPublicFundraisingEvent
  loading: boolean
  error?: ApolloError | string
}

const RouteContext = React.createContext<IRouteContext>({ loading: true })

const RouteContextProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const query = get_cause_and_fe_by_slug
  const variables =
    process.env.REACT_APP_TILTIFY_ENV === 'production'
      ? { causeSlug: 'jingle-jam', feSlug: 'jingle-jam-2023' }
      : { causeSlug: 't4c', feSlug: 'published-t4fe' }
  const { data, loading, error } = useQuery(query, { variables, errorPolicy: 'all' })

  if (!data || loading) return <FullWindowLoading />
  return (
    <RouteContextContainer
      queryData={formatQueryData(data?.cause, data?.fundraisingEvent, loading, error)}
    >
      {children}
    </RouteContextContainer>
  )
}

const RouteContextContainer = ({
  queryData,
  children,
}: {
  queryData: {
    cause?: TiltifyPublicCause
    fundraisingEvent?: TiltifyPublicFundraisingEvent
    loading: boolean
    error?: ApolloError | string
  }
  children: JSX.Element
}): JSX.Element => {
  const [state, dispatch] = useReducer(routeReducer, queryData)

  useEffect(() => {
    if (
      !queryData.loading &&
      (queryData.loading !== state.loading || queryData.error !== state.error)
    ) {
      if (!queryData.cause && !queryData.fundraisingEvent) {
        window.location.href = 'https://tiltify.com'
      }
      dispatch({ type: ACTION_TYPES.INIT_CONTEXT, item: queryData })
    }
    // eslint-disable-next-line
  }, [queryData])

  return <RouteContext.Provider value={state}>{children}</RouteContext.Provider>
}

const useRouteContext = (): IRouteContext => {
  const context = React.useContext(RouteContext)
  if (context === undefined) {
    throw new Error('useRouteContext must be used within a RouteContextProvider')
  }
  return context
}

export { RouteContextProvider, useRouteContext }
