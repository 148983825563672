import { forwardRef } from 'react'

import styled from 'styled-components'
import { useIntl } from 'react-intl'

import { layoutMessages } from '../../translations'
import { useThemeContext } from '../../contexts/ThemeContext'
import { Button } from '../Button'
import { Dark } from './Dark'
import { Light } from './Light'

const StyledButton = styled(Button)(
  ({ theme, inFooter }) => `
  border: 1px solid ${inFooter ? '#fff' : theme.toggleColor};
  background: rgba(25, 111, 204, 0.05);
  margin-top: 0.25rem;
  color: ${inFooter ? '#fff' : theme.toggleColor};
  display: flex;
  align-items: center;

  svg {
    margin-left: 0.5rem;
  }
  path {
    fill: ${inFooter ? '#fff' : theme.toggleColor};
  }
  mask path {
    fill: ${inFooter ? '#fff' : theme.toggleColor};
  }

  span {
    display: flex;
    align-items: center;
  }
`
)

export const ThemeToggle = forwardRef(({ inFooter, ...rest }: any, ref) => {
  const { formatMessage } = useIntl()
  const themeContext = useThemeContext()
  const { toggle, isDark } = themeContext

  const darkSVG = (
    <>
      {formatMessage(layoutMessages.darkMode)}
      <Dark />
    </>
  )

  const lightSVG = (
    <>
      {formatMessage(layoutMessages.lightMode)}
      <Light />
    </>
  )
  return (
    <StyledButton
      size='small'
      type='button'
      onClick={toggle}
      outline
      inFooter={inFooter}
      ref={ref}
      {...rest}
    >
      {isDark ? darkSVG : lightSVG}
    </StyledButton>
  )
})

ThemeToggle.displayName = 'ThemeToggle'

ThemeToggle.defaultProps = {
  inFooter: false,
}
