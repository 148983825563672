import styled from 'styled-components'

import { Discord } from '@tiltify/design/icons/social/Discord'
import { Facebook } from '@tiltify/design/icons/social/Facebook'
import { Google } from '@tiltify/design/icons/social/Google'
import { Twitch } from '@tiltify/design/icons/social/Twitch'

import { SocialButton } from './SocialButton'

const GridWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    justify-content: center;
    max-width: 100%;
    gap: 1rem;
  }
`
export const Social = ({
  openChild,
  enforceFundraiser = false,
}: {
  enforceFundraiser?: boolean
  token: () => { type: string; value: string }
  openChild: (url: string) => void
}) => {
  const base = process.env.REACT_APP_ID_TILTIFY_URL
  const state = {
    fundraiser: enforceFundraiser,
  }

  const encode = btoa(JSON.stringify(state))
  const twitch = `${base}/auth/twitch?redirect_url=${encodeURIComponent(
    window.location.href
  )}&state=${encode}`
  const facebook = `${base}/auth/facebook?${encodeURIComponent(
    window.location.href
  )}&state=${encode}`
  const google = `${base}/auth/google?${encodeURIComponent(window.location.href)}&state=${encode}`
  const discord = `${base}/auth/discord?${encodeURIComponent(window.location.href)}&state=${encode}`

  return (
    <GridWrapper id='gtm_social-auth'>
      <SocialButton openChild={openChild} link={google} icon={<Google width={24} height={24} />} />
      <SocialButton openChild={openChild} link={twitch} icon={<Twitch width={24} height={24} />} />
      <SocialButton
        openChild={openChild}
        link={facebook}
        icon={<Facebook width={24} height={24} />}
      />
      <SocialButton
        openChild={openChild}
        link={discord}
        icon={<Discord width={24} height={24} />}
      />
    </GridWrapper>
  )
}
