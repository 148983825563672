import { gql } from '@apollo/client'

export const update_user_profile = gql`
  mutation UpdateUserProfile(
    $firstName: String!
    $lastName: String!
    $email: String!
    $username: String!
  ) {
    updateUserProfile(
      firstName: $firstName
      lastName: $lastName
      email: $email
      username: $username
    ) {
      id
      username
      email
      firstName
      lastName
      slug
      profileComplete
    }
  }
`

export const become_fundraiser = gql`
  mutation becomeFundraiser(
    $id: Int!
    $avatar: Upload
    $bio: String
    $social: UserSocialInput
    $firstName: String!
    $lastName: String!
    $username: String
  ) {
    becomeFundraiser(
      id: $id
      avatar: $avatar
      bio: $bio
      social: $social
      firstName: $firstName
      lastName: $lastName
      username: $username
    ) {
      id
      username
      firstName
      lastName
      avatar {
        alt
        height
        width
        src
      }
      bio
      social {
        twitter
        discord
        facebook
        website
        snapchat
        instagram
        youtube
        tiktok
        twitch
      }
    }
  }
`
