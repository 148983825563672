import { gql } from '@apollo/client'

export const get_cause_by_slug = gql`
  query get_cause_by_slug($slug: String!) {
    cause(slug: $slug) {
      id
      causeFactPublicId
      name
      slug
      trackers
      avatar {
        alt
        height
        width
        src
      }
      social {
        discord
        facebook
        instagram
        snapchat
        tiktok
        twitter
        website
        youtube
      }
      paymentOptions {
        currency
        monthlyGiving
      }
      paymentMethods {
        type
        currency
      }
      publishedFundraisingEvents {
        publicId
        name
        description
        shortDescription
        slug
        avatar {
          alt
          height
          src
          width
        }
        goal {
          currency
          value
        }
        amountRaised {
          currency
          value
        }
        totalAmountRaised {
          currency
          value
        }
      }
    }
  }
`

export const get_cause_and_fe_by_slug = gql`
  query get_cause_and_fe_by_slug($feSlug: String!, $causeSlug: String!) {
    cause(slug: $causeSlug) {
      id
      causeFactPublicId
      name
      slug
      trackers
      avatar {
        alt
        height
        width
        src
      }
      social {
        discord
        facebook
        instagram
        snapchat
        tiktok
        twitter
        website
        youtube
      }
      paymentMethods {
        type
        currency
      }
      paymentOptions {
        currency
        monthlyGiving
      }
    }
    fundraisingEvent(slug: $feSlug, causeSlug: $causeSlug) {
      publicId
      legacyFundraisingEventId
      name
      slug
      trackers
      description
      supportable
      sponsorList {
        image {
          alt
          height
          src
          width
        }
      }
      image {
        src
      }
      video
      avatar {
        src
      }
      amountRaised {
        currency
        value
      }
      totalAmountRaised {
        currency
        value
      }
      goal {
        currency
        value
      }
      visibility {
        raised
        goal
        donate
        toolkit {
          url
        }
        teamLeaderboard {
          visible
        }
        userLeaderboard {
          visible
        }
      }
      publishedCampaignsCount
      publishedCampaigns(limit: 50) {
        edges {
          cursor
          node {
            ... on Campaign {
              publicId
              name
              slug
              live
              cause {
                name
                avatar {
                  src
                }
              }
              user {
                id
                username
                slug
                avatar {
                  src
                  alt
                }
              }
              avatar {
                src
                alt
              }
              totalAmountRaised {
                value
                currency
              }
              goal {
                value
                currency
              }
              cardImage {
                src
                alt
              }
            }
            ... on TeamEvent {
              publicId
              name
              slug
              currentSlug
              live
              cause {
                name
                avatar {
                  src
                }
              }
              team {
                id
                name
                slug
                avatar {
                  src
                  alt
                }
              }
              avatar {
                src
                alt
              }
              totalAmountRaised {
                value
                currency
              }
              goal {
                value
                currency
              }
              cardImage {
                src
                alt
              }
            }
          }
        }
      }
      social {
        discord
        facebook
        instagram
        snapchat
        tiktok
        twitter
        website
        youtube
      }
      paymentOptions {
        currency
        monthlyGiving
      }
      incentives {
        id
        title
        description
        fairMarketValue {
          value
          currency
        }
        amount {
          value
          currency
        }
        image {
          src
          alt
        }
      }
    }
  }
`
