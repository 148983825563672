import styled from 'styled-components'

interface AvatarProps {
  className?: string
  src: string
  alt: string
  height?: number
  width?: number
}

const StyledAvatar = styled.img`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  box-shadow: ${({ theme }) => theme.depth[4]};
  border-radius: 6px;
`

export const Avatar = ({ className, src, alt, height = 64, width = 64 }: AvatarProps) => {
  return <StyledAvatar className={className} src={src} alt={alt} height={height} width={width} />
}
