import { forwardRef, useImperativeHandle, useRef } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { Button } from '@tiltify/ui/components/Button'
import { userMenuMessages } from '@tiltify/ui/translations'
import { baseTiltifyUrl, getSubdomainUrl } from '@tiltify/ui/utils/tiltifyUrl'

import { useAuthenticationState } from '../../../../../../contexts/AuthenticationContext'

const StyledButton = styled(Button)`
  display: flex;
  font-size: 0.8125rem !important;
  font-weight: normal !important;
  padding: 0.625rem 1.0625rem;
  border: none;
  color: ${({ theme }) => theme.textColor} !important;
`

export const FrontendUserEditCampaign = forwardRef(({ campaign }: any, ref) => {
  const { authenticatedUser: user } = useAuthenticationState()
  const { formatMessage } = useIntl()
  const localRef = useRef()
  useImperativeHandle(ref, () => localRef.current)

  const baseURL = getSubdomainUrl(baseTiltifyUrl(), 'app')
  const redirectUrl =
    campaign?.team && campaign?.team?.slug
      ? `${baseURL}/users/${user?.slug}/teams/${campaign.team.slug}/campaigns/${campaign.slug}/overview`
      : `${baseURL}/users/${user?.slug}/campaigns/${campaign.slug}/detail`

  return (
    <StyledButton forwardedAs='a' href={redirectUrl} ref={localRef}>
      {formatMessage(userMenuMessages.editCampaign)}
    </StyledButton>
  )
})

FrontendUserEditCampaign.displayName = 'FrontendUserEditCampaign'
