import { useTheme } from 'styled-components'

import { useFillColor, useSize } from './IconHooks'

export const Plus = ({
  fill,
  fillType = 'black.50',
  size = 'small',
  height,
  width,
  title = 'Plus',
}: TiltifyIcon) => {
  const theme = useTheme()
  const sizeValues = useSize(size, height, width)
  const fillValue = useFillColor(theme, fillType, fill)

  return (
    <svg
      width={sizeValues.width}
      height={sizeValues.height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>{title}</title>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 3.05882C9 2.47405 8.55228 2 8 2C7.44772 2 7 2.47405 7 3.05882V7H3.05882C2.47405 7 2 7.44772 2 8C2 8.55228 2.47405 9 3.05882 9H7V12.9412C7 13.5259 7.44772 14 8 14C8.55228 14 9 13.5259 9 12.9412V9H12.9412C13.5259 9 14 8.55228 14 8C14 7.44772 13.5259 7 12.9412 7H9V3.05882Z'
        fill={fillValue}
      />
    </svg>
  )
}
