import styled, { useTheme } from 'styled-components/macro'

import { TiltifyCircle } from '@tiltify/ui/icons'
import { CustomPoweredBy } from '@tiltify/ui'

const StyledCustomPoweredBy = styled(CustomPoweredBy)`
  padding: 1rem;
  font-weight: normal;
  svg {
    margin-bottom: 0.5rem;
  }
`

export const PoweredBy = (): JSX.Element => {
  const theme = useTheme()
  return (
    <StyledCustomPoweredBy
      color={theme.whiteText}
      backgroundColor='#212121'
      poweredByText={theme.whiteText}
      icon={<TiltifyCircle height={26} width={26} />}
    />
  )
}
