import styled from 'styled-components'

import { MediaEmbed } from '@tiltify/ui'
import { useRouteContext } from 'contexts/RouteContext'

const StyledMediaWrapper = styled.div`
  position: flex;
  justify-content: center;
`

const StyledWrapper = styled.div`
  max-width: 600px;
  margin: auto;
  margin-top: -170px;
  border-radius: 6px;

  && iframe {
    border-radius: 6px;
  }
`

export const MediaDisplay = (): JSX.Element => {
  const { fundraisingEvent } = useRouteContext()

  const livestream = { channel: 'yogscast', type: 'twitch' }
  return (
    <StyledMediaWrapper>
      <StyledWrapper>
        <MediaEmbed
          livestream={
            fundraisingEvent?.video
              ? { channel: fundraisingEvent.video, type: 'youtube_video' }
              : livestream
          }
        />
      </StyledWrapper>
    </StyledMediaWrapper>
  )
}
