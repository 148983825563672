import { useTheme } from 'styled-components'

import { useFillColor, useSize } from './IconHooks'

export const Checkmark = ({
  fill,
  fillType = 'black.50',
  height,
  width,
  title = 'Check',
  size = 'small',
}: TiltifyIcon) => {
  const theme = useTheme()
  const sizeValues = useSize(size, height, width)
  const fillValue = useFillColor(theme, fillType, fill)

  return (
    <svg
      width={sizeValues.width}
      height={sizeValues.height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>{title}</title>
      <path
        d='M12.4735 5.1972C12.4115 5.13471 12.3378 5.08512 12.2565 5.05127C12.1753 5.01743 12.0881 5 12.0001 5C11.9121 5 11.825 5.01743 11.7437 5.05127C11.6625 5.08512 11.5888 5.13471 11.5268 5.1972L6.56013 10.1705L4.47346 8.0772C4.40911 8.01504 4.33315 7.96616 4.24992 7.93336C4.16668 7.90056 4.0778 7.88447 3.98834 7.88602C3.89889 7.88757 3.81062 7.90672 3.72857 7.94238C3.64651 7.97804 3.57229 8.02952 3.51013 8.09387C3.44797 8.15821 3.39909 8.23417 3.36629 8.31741C3.33349 8.40065 3.3174 8.48953 3.31895 8.57898C3.3205 8.66844 3.33965 8.75671 3.37531 8.83876C3.41097 8.92082 3.46245 8.99504 3.5268 9.0572L6.0868 11.6172C6.14877 11.6797 6.22251 11.7293 6.30374 11.7631C6.38498 11.797 6.47212 11.8144 6.56013 11.8144C6.64814 11.8144 6.73527 11.797 6.81651 11.7631C6.89775 11.7293 6.97149 11.6797 7.03346 11.6172L12.4735 6.1772C12.5411 6.11477 12.5951 6.039 12.6321 5.95467C12.669 5.87034 12.6881 5.77927 12.6881 5.6872C12.6881 5.59513 12.669 5.50406 12.6321 5.41973C12.5951 5.33539 12.5411 5.25963 12.4735 5.1972Z'
        fill={fillValue}
      />
    </svg>
  )
}
