import { readableColor } from 'polished'
import styled from 'styled-components'

import { Button } from '@tiltify/ui/components/Button'
import { Hamburger } from '@tiltify/ui/icons/Hamburger'

const StyledHamburgerWrapper = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${({ theme }) => `(min-width: ${theme.breakpoints[1]})`} {
    display: none;
  }

  color: ${({ theme }) => readableColor(theme.backgroundAccent)};

  svg {
    height: 24px;
    width: 24px;
    g {
      stroke: ${({ theme }) => readableColor(theme.backgroundAccent)};
    }
  }
`

const StyledHamburger = styled(Hamburger)`
  path {
    stroke: ${({ theme }) => {
      if (theme?.colors?.black?.core) {
        return theme.isDark ? theme.colors.blue[300] : theme.colors.blue.core
      }
      return theme.textColor
    }};
  }
`

export const MenuButton = ({ toggleMenu, onKeyDown }: any) => {
  return (
    <StyledHamburgerWrapper onClick={toggleMenu} onKeyDown={onKeyDown}>
      <StyledHamburger id='hamburger' />
    </StyledHamburgerWrapper>
  )
}
