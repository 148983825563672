import { useRef, useState } from 'react'
import styled from 'styled-components'

import { Avatar } from '@tiltify/ui/components/Avatar'
import { Button } from '@tiltify/ui/components/Button'
import { useOnOutsideClick } from '@tiltify/ui/hooks/useOnOutsideClick'

import { useAuthenticationState } from '../../../contexts/AuthenticationContext'
import { AuthOptions } from './AuthOptions'
import { MenuButton } from './MenuButton'
import { UserMenuList } from './UserMenuList'

const StyledUserMenuWrapper = styled.div`
  position: relative;
`

const StyledFullMenu = styled.div`
  @media ${({ theme }) => `(max-width: ${theme.breakpoints[1]})`} {
    display: none;
  }
`

const StyledSVG = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  bottom: 0;
  left: 0;
  margin: 0 auto;
  padding-top: 15px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  display: block;
`

const StyledButton = styled(Button)`
  & ${StyledSVG} {
    display: none;
  }

  &:hover {
    & ${StyledSVG} {
      display: block;
    }
  }
`

const getAvatar = (user: AuthenticatedUser) => {
  return user && user.avatar
    ? user.avatar.src
    : 'https://assets.tiltify.com/assets/default-avatar.png'
}

export const UserMenu = ({
  childrenLeft,
  childrenRight,
  authButtons,
  loginMenu = true,
  navType = 'frontend',
  preview = false,
  userMenuData,
}: any) => {
  const ref = useRef()
  const { authenticatedUser: user } = useAuthenticationState()
  const [menuVisible, setMenuVisible] = useState(false)

  const closeMenu = () => {
    if (menuVisible) setMenuVisible(false)
  }

  useOnOutsideClick(ref, closeMenu)

  const onKeyDown = (event: any) => {
    if (
      event.key === 'ArrowDown' ||
      event.key === 'ArrowUp' ||
      event.key === 'Enter' ||
      event.key === ' '
    ) {
      event.preventDefault()
      setMenuVisible((prev) => !prev)
    }
  }

  if (!user && !loginMenu) return null

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <StyledUserMenuWrapper ref={ref}>
      <StyledFullMenu>
        {(!user || preview) && loginMenu ? authButtons || <AuthOptions /> : null}
        {user && !preview ? (
          <StyledButton
            onClick={() => setMenuVisible((prev) => !prev)}
            onKeyDown={onKeyDown}
            aria-expanded={menuVisible}
          >
            <Avatar src={getAvatar(user)} height={40} width={40} shape='square' />
            <StyledSVG>
              <svg height='10' viewBox='0 0 17 10' width='17' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M8.204 9.98c-.294.034-.6-.063-.827-.29L.31 2.624c-.387-.387-.393-1.025-.002-1.416C.7.814 1.332.817 1.724 1.21L8.1 7.585l6.377-6.377c.386-.388 1.025-.393 1.416-.003.393.393.39 1.025-.002 1.416L8.825 9.69c-.172.172-.393.27-.62.29z'
                  fill='#FFF'
                  fillRule='evenodd'
                />
              </svg>
            </StyledSVG>
          </StyledButton>
        ) : null}
      </StyledFullMenu>
      <MenuButton toggleMenu={() => setMenuVisible((prev) => !prev)} onKeyDown={onKeyDown} />
      {menuVisible ? (
        <UserMenuList
          childrenLeft={childrenLeft}
          childrenRight={childrenRight}
          authButtons={authButtons}
          loginMenu={loginMenu}
          navType={navType}
          toggleMenu={() => setMenuVisible((prev) => !prev)}
          user={user}
          userMenuData={userMenuData}
        />
      ) : null}
    </StyledUserMenuWrapper>
  )
}
