import { useTheme } from 'styled-components'

import { useFillColor, useSize } from './IconHooks'

export const CaretFirst = ({
  fill,
  fillType = 'black.50',
  size = 'small',
  height,
  width,
  title = 'First',
}: TiltifyIcon) => {
  const theme = useTheme()
  const sizeValues = useSize(size, height, width)
  const fillValue = useFillColor(theme, fillType, fill)

  return (
    <svg
      width={sizeValues.width}
      height={sizeValues.height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>{title}</title>
      <path
        d='M9.4158 8.2458L12.9558 4.7058C13.142 4.51844 13.2466 4.26498 13.2466 4.0008C13.2466 3.73661 13.142 3.48316 12.9558 3.2958C12.8628 3.20207 12.7522 3.12768 12.6304 3.07691C12.5085 3.02614 12.3778 3 12.2458 3C12.1138 3 11.9831 3.02614 11.8612 3.07691C11.7394 3.12768 11.6288 3.20207 11.5358 3.2958L7.2958 7.5358C7.20207 7.62876 7.12768 7.73936 7.07691 7.86122C7.02614 7.98308 7 8.11379 7 8.2458C7 8.37781 7.02614 8.50852 7.07691 8.63037C7.12768 8.75223 7.20207 8.86283 7.2958 8.9558L11.5358 13.2458C11.6292 13.3385 11.7401 13.4118 11.8619 13.4616C11.9837 13.5113 12.1142 13.5366 12.2458 13.5358C12.3774 13.5366 12.5079 13.5113 12.6297 13.4616C12.7515 13.4118 12.8624 13.3385 12.9558 13.2458C13.142 13.0584 13.2466 12.805 13.2466 12.5408C13.2466 12.2766 13.142 12.0232 12.9558 11.8358L9.4158 8.2458Z'
        fill={fillValue}
      />
      <path
        d='M4.4158 8.2458L7.9558 4.7058C8.14205 4.51844 8.24659 4.26498 8.24659 4.0008C8.24659 3.73661 8.14205 3.48316 7.9558 3.2958C7.86283 3.20207 7.75223 3.12768 7.63037 3.07691C7.50852 3.02614 7.37781 3 7.2458 3C7.11379 3 6.98308 3.02614 6.86122 3.07691C6.73936 3.12768 6.62876 3.20207 6.5358 3.2958L2.2958 7.5358C2.20207 7.62876 2.12768 7.73936 2.07691 7.86122C2.02614 7.98308 2 8.11379 2 8.2458C2 8.37781 2.02614 8.50852 2.07691 8.63037C2.12768 8.75223 2.20207 8.86283 2.2958 8.9558L6.5358 13.2458C6.62924 13.3385 6.74005 13.4118 6.86189 13.4616C6.98373 13.5113 7.11419 13.5366 7.2458 13.5358C7.3774 13.5366 7.50787 13.5113 7.6297 13.4616C7.75154 13.4118 7.86236 13.3385 7.9558 13.2458C8.14205 13.0584 8.24659 12.805 8.24659 12.5408C8.24659 12.2766 8.14205 12.0232 7.9558 11.8358L4.4158 8.2458Z'
        fill={fillValue}
      />
    </svg>
  )
}
