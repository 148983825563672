declare global {
  interface Window {
    gtag: any
    dataLayer: any
  }

  interface Tracker {
    trackingId: string
    gaOptions: {
      name: string
      userId: string | number
    }
  }
}
export const useGA = () => {
  const initGA = (trackingId: any) => {
    if (process.env.NODE_ENV === 'production') {
      if (!window.gtag) {
        const script = document.createElement('script')
        script.async = true
        script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`

        document.body.appendChild(script)

        window.dataLayer = window.dataLayer || []
        window.gtag = function gtag() {
          // DO NOT REMOVE ARGUMENTS EVER! This is needed for google analytics to work with multiple trackers
          // eslint-disable-next-line
          window.dataLayer.push(arguments)
        }
        window.gtag('js', new Date())
      }
      window.gtag('config', trackingId, { name: 'default', send_page_view: false })
    }
  }

  const addTracker = (tracker: any) => {
    if (process.env.NODE_ENV === 'production') {
      if (Array.isArray(tracker)) {
        tracker.forEach((config) => {
          window.gtag('config', config.trackingId, config.gaOptions)
        })
      }
    }
  }

  const pageView = (additionalTrackers = []) => {
    if (process.env.NODE_ENV === 'production') {
      window.gtag('event', 'page_view', {
        page_location: `${window.location.pathname + window.location.search}`,
      })

      additionalTrackers.forEach((tracker) => {
        window.gtag('event', 'page_view', {
          page_location: `${window.location.pathname + window.location.search}`,
          send_to: tracker,
        })
      })
    }
  }

  // this appears to only be used for UA keys so it may not be useful after the switch to GA4
  const setCustomDimension = (causeId: any, fundraisingEventId: any, campaignId: any) => {
    if (process.env.NODE_ENV === 'production') {
      if (causeId) {
        window.gtag('set', 'dimension1', `${causeId}`)
      }
      if (fundraisingEventId) {
        window.gtag('set', 'dimension2', `${fundraisingEventId}`)
      }
      if (campaignId) {
        window.gtag('set', 'dimension3', `${campaignId}`)
      }
    }
  }

  const trackEvent = (category: any, action: any, options = {}, additionalTrackers = []) => {
    if (process.env.NODE_ENV === 'production') {
      window.gtag('event', 'custom_event', {
        event_category: category,
        event_label: action,
      })

      additionalTrackers.forEach((tracker) => {
        window.gtag('event', 'custom_event', {
          event_category: category,
          event_label: action,
          // @ts-expect-error TS(2339): Property 'trackingID' does not exist on type 'neve... Remove this comment to see the full error message
          send_to: tracker.trackingID,
          ...options,
        })
      })
    }
  }

  return { initGA, pageView, trackEvent, addTracker, setCustomDimension }
}
