import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { createLink } from 'apollo-absinthe-upload-link'

import { baseTiltifyUrl, getSubdomainUrl } from './tiltifyUrl'

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
  },
  query: {
    fetchPolicy: 'no-cache',
  },
  mutate: {
    fetchPolicy: 'no-cache',
  },
}

export const setupPublicClient = async () => {
  const cache = new InMemoryCache()
  const link = new HttpLink({
    uri: process.env.REACT_APP_PUBLIC_API_URL,
    credentials: 'include',
  })

  const client = new ApolloClient({
    cache,
    link,
    // @ts-expect-error TS(2322): Type '{ watchQuery: { fetchPolicy: string; }; quer... Remove this comment to see the full error message
    defaultOptions,
  })
  return client
}

export const setupCauseClient = () => {
  const cache = new InMemoryCache()
  const link = createLink({
    uri: `${process.env.REACT_APP_CAUSE_API_URL}/gql`,
    credentials: 'include',
  })

  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      const error401 = graphQLErrors.some(({ message, path }) => {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        if (message === '401' && path[0] !== 'updateEmail' && path[0] !== 'changePassword') {
          return true
        }
        return false
      })

      if (error401)
        window.location.href = getSubdomainUrl(`${baseTiltifyUrl()}/causes/login`, 'app')
    }
  })

  const client = new ApolloClient({
    cache,
    link: ApolloLink.from([errorLink, link]),
    // @ts-expect-error TS(2322): Type '{ watchQuery: { fetchPolicy: string; }; quer... Remove this comment to see the full error message
    defaultOptions,
  })
  return client
}

export const setupAdminClient = () => {
  const cache = new InMemoryCache()
  const link = createLink({
    uri: `${process.env.REACT_APP_ADMIN_API_URL}/gql`,
    credentials: 'include',
  })

  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      const error401 = graphQLErrors.some(({ message }) => {
        if (message === '401') {
          return true
        }
        return false
      })

      if (error401) window.location.href = baseTiltifyUrl()
    }
  })

  const client = new ApolloClient({
    cache,
    link: ApolloLink.from([errorLink, link]),
    // @ts-expect-error TS(2322): Type '{ watchQuery: { fetchPolicy: string; }; quer... Remove this comment to see the full error message
    defaultOptions,
  })
  return client
}

export const setupUserClientSync = () => {
  const cache = new InMemoryCache()
  const link = createLink({
    uri: `${process.env.REACT_APP_DASHBOARD_API_URL}/gql`,
    credentials: 'include',
  })

  const client = new ApolloClient({
    cache,
    link,
    // @ts-expect-error TS(2322): Type '{ watchQuery: { fetchPolicy: string; }; quer... Remove this comment to see the full error message
    defaultOptions,
  })
  return client
}

export const setupUserClient = async () => {
  const cache = new InMemoryCache()
  const link = createLink({
    uri: `${process.env.REACT_APP_DASHBOARD_API_URL}/gql`,
    credentials: 'include',
  })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      const error401 = graphQLErrors.some(({ message }) => {
        if (message === '401') {
          return true
        }
        return false
      })

      if (error401) window.location.reload()
      // @ts-expect-error TS(2339): Property 'statusCode' does not exist on type 'Erro... Remove this comment to see the full error message
    } else if (networkError && networkError?.statusCode === 403) {
      window.location.reload()
    }
  })

  const client = new ApolloClient({
    cache,
    link: ApolloLink.from([errorLink, link]),
    // @ts-expect-error TS(2322): Type '{ watchQuery: { fetchPolicy: string; }; quer... Remove this comment to see the full error message
    defaultOptions,
  })
  return client
}
