import React, { useCallback, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'

import { dark as darkTheme, light as lightTheme } from '../themes'

const ThemeContext = React.createContext<any>({})

const ToggleThemeProvider = ({
  children,
  dark = darkTheme,
  light = lightTheme,
  localStorageModifier = '',
  themeOverride = null,
}: {
  children: React.ReactNode | React.ReactNode[]
  dark?: any
  light?: any
  localStorageModifier?: string
  themeOverride?: any
}) => {
  const LOCAL_STORAGE_KEY = `@@tiltify/ui/theme${localStorageModifier}`
  const DEFAULT_THEME = 'dark'
  const [isDark, toggleTheme] = React.useState(!themeOverride)

  const validate = (theme: string) => {
    if (theme === 'dark') return 'dark'
    if (theme === 'light') return 'light'
    return 'dark'
  }

  const setInitialTheme = useCallback((theme: string) => {
    if (validate(theme) === 'dark') {
      toggleTheme(true)
    } else {
      toggleTheme(false)
    }
  }, [])

  const loadTheme = useCallback(() => {
    try {
      const value = window.localStorage.getItem(LOCAL_STORAGE_KEY)
      const theme = validate(value || '')
      setInitialTheme(theme)
      return theme
    } catch {
      return validate(DEFAULT_THEME)
    }
  }, [setInitialTheme, LOCAL_STORAGE_KEY])

  useEffect(() => {
    const theme = loadTheme()
    setInitialTheme(theme)
  }, [loadTheme, setInitialTheme])

  useEffect(() => {
    if (themeOverride) toggleTheme(false)
  }, [themeOverride])

  useEffect(() => {
    const body = document.querySelector('body')

    if (body) {
      if (!isDark) {
        body.classList.remove('dark')
        body.classList.add('light')
      } else {
        body.classList.remove('light')
        body.classList.add('dark')
      }
    }
  }, [isDark])

  const saveTheme = (theme: string) => {
    try {
      window.localStorage.setItem(LOCAL_STORAGE_KEY, validate(theme))
    } catch {
      return
    }
  }

  const toggle = () => {
    toggleTheme(!isDark)
    saveTheme(isDark ? 'light' : 'dark')
  }

  const themeContext = {
    toggle,
    isDark,
  }

  return (
    <ThemeContext.Provider value={themeContext}>
      <ThemeProvider theme={themeOverride || (isDark ? dark : light)}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  )
}

const useThemeContext = () => {
  const context = React.useContext(ThemeContext)
  if (context === undefined) {
    throw new Error('useThemeContext must be used within a ToggleThemeProvider')
  }
  return context
}

export { ToggleThemeProvider, useThemeContext }
