import React, { forwardRef, useImperativeHandle, useEffect, useRef } from 'react'

const styles = `
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  z-index: 999;
`

/**
 * FreshdeskWidget: This widget is to allow users to get help from support articles or to submit a ticket.
 * The widget is not designed to be dynamically added and removed so ignore the code because its very jank.
 */

declare global {
  interface Window {
    FreshdeskWidget: () => void
    fwSettings: {
      widget_id: number
    }
    FreshworksWidget: (
      action: string,
      entity?: string,
      options?: Record<string, unknown> | string[]
    ) => void
  }
}

export const FreshdeskWidget = forwardRef(
  (
    {
      autofill,
      children,
      close,
      ...rest
    }: {
      autofill: { email: string; custom_fields: Record<string, string | undefined> }
      close?: () => void
      children: React.ReactNode
    },
    ref
  ) => {
    const localRef = useRef<HTMLButtonElement>(null)
    const intervalRef = useRef<ReturnType<typeof setInterval>>()
    useImperativeHandle(ref, () => localRef.current)

    /* eslint-disable */
    useEffect(() => {
      if (!window.FreshdeskWidget) {
        window.fwSettings = {
          widget_id: 43000002335,
        }
        // @ts-expect-error - this is freshworks code and we don't want to type it
        !(function () {
          if (typeof window.FreshworksWidget !== 'function') {
            const n = function () {
              n.q.push(arguments)
            }
            ;(n.q = [] as any), (window.FreshworksWidget = n)
          }
        })()
      }
      return () => {
        const overlay = document.querySelector('#freshdesk-overlay')
        if (overlay) {
          closeHelp()
        }
        clearInterval(intervalRef.current)
      }
    }, [])
    /* eslint-enable */

    useEffect(() => {
      window.FreshworksWidget('prefill', 'ticketForm', {
        email: autofill?.email,
        custom_fields: {
          cf_url: window.location.href,
          ...autofill?.custom_fields,
        },
      })
      window.FreshworksWidget('disable', 'ticketForm', ['custom_fields.cf_url'])
    }, [autofill])

    const handleClick = () => {
      if (!document.querySelector('#freshworks-frame')) addFreshdeskScript()
      window.FreshworksWidget('open')
      if (!document.querySelector('#freshdesk-overlay')) appendOverlay()
      if (!intervalRef.current) {
        intervalRef.current = setInterval(() => {
          if (!document.querySelector('#freshworks-frame-wrapper')) {
            if (close) close()
            const overlay = document.querySelector('#freshdesk-overlay')
            if (overlay) {
              closeHelp()
            }
          }
        }, 500)
      }
    }

    const addFreshdeskScript = () => {
      const script = document.createElement('script')
      script.src = 'https://widget.freshworks.com/widgets/43000002335.js'
      script.type = 'text/javascript'
      document.body.appendChild(script)
      window.FreshworksWidget('hide', 'launcher')
    }

    const appendOverlay = () => {
      const Overlay = document.createElement('div')
      Overlay.id = 'freshdesk-overlay'
      Overlay.setAttribute('style', styles)
      Overlay.addEventListener('click', closeHelp)
      document.body.append(Overlay)
    }

    const closeHelp = () => {
      window.FreshworksWidget('close')
      document.querySelector('#freshdesk-overlay')?.removeEventListener('click', closeHelp)
      const node = document.querySelector('#freshdesk-overlay')
      if (node) document.body.removeChild(node)
    }

    return (
      <button onClick={handleClick} {...rest} ref={localRef}>
        {children}
      </button>
    )
  }
)

FreshdeskWidget.displayName = 'FreshdeskWidget'
