import { rem } from 'polished'
import { forwardRef, useImperativeHandle, useRef } from 'react'
import styled, { css } from 'styled-components'

import { Amplify } from '../../icons/Amplify'
import { Archive } from '../../icons/Archive'
import { ArrowLeft } from '../../icons/ArrowLeft'
import { ArrowRight } from '../../icons/ArrowRight'
import { Calendar } from '../../icons/Calendar'
import { CaretDown } from '../../icons/CaretDown'
import { CaretFirst } from '../../icons/CaretFirst'
import { CaretLeft } from '../../icons/CaretLeft'
import { CaretRight } from '../../icons/CaretRight'
import { CaretUp } from '../../icons/CaretUp'
import { Checkmark } from '../../icons/Checkmark'
import { Copy } from '../../icons/Copy'
import { Download } from '../../icons/Download'
import { Ellipsis } from '../../icons/Ellipsis'
import { Envelope } from '../../icons/Envelope'
import { Eye } from '../../icons/Eye'
import { Filter } from '../../icons/Filter'
import { Fullscreen } from '../../icons/Fullscreen'
import { Plus } from '../../icons/Plus'
import { Popout } from '../../icons/Popout'
import { Refresh } from '../../icons/Refresh'
import { Search } from '../../icons/Search'
import { Share } from '../../icons/Share'
import { Team } from '../../icons/Team'
import { Trash } from '../../icons/Trash'
import { Upload } from '../../icons/Upload'
import { Bank } from '../../icons/payments/Bank'
import { Facebook } from '../../icons/social/Facebook'
import { Twitter } from '../../icons/social/Twitter'
import {
  StyledOutlineButton,
  StyledOutlineLink,
  StyledSolidButton,
  StyledSolidLink,
  StyledTextButton,
  StyledTextLink,
} from './styles'

const IconWrapperRight = styled.span<{ iconOnly: boolean }>`
  ${({ iconOnly }) =>
    !iconOnly &&
    css`
      margin-left: ${rem(10)};
    `}
  display: flex;
`

const IconWrapperLeft = styled.span<{ iconOnly: boolean }>`
  ${({ iconOnly }) =>
    !iconOnly &&
    css`
      margin-right: ${rem(10)};
    `}
  display: flex;
`

type ButtonIcon =
  | 'archive'
  | 'arrow-left'
  | 'arrow-right'
  | 'calendar'
  | 'caret-down'
  | 'caret-left'
  | 'caret-right'
  | 'caret-up'
  | 'caret-first'
  | 'check'
  | 'copy'
  | 'plus'
  | 'popout'
  | 'search'
  | 'trash'
  | 'ellipsis'
  | 'eye'
  | 'facebook'
  | 'twitter'
  | 'download'
  | 'team'
  | 'filter'
  | 'refresh'
  | 'amplify'
  | 'envelope'
  | 'upload'
  | 'bank'
  | 'fullscreen'
  | 'share'

const RENDERS: Record<ButtonIcon, React.ComponentType<TiltifyIcon>> = {
  archive: Archive,
  'arrow-right': ArrowRight,
  'arrow-left': ArrowLeft,
  calendar: Calendar,
  'caret-down': CaretDown,
  'caret-left': CaretLeft,
  'caret-right': CaretRight,
  'caret-up': CaretUp,
  'caret-first': CaretFirst,
  check: Checkmark,
  copy: Copy,
  download: Download,
  ellipsis: Ellipsis,
  eye: Eye,
  popout: Popout,
  plus: Plus,
  search: Search,
  team: Team,
  trash: Trash,
  twitter: Twitter,
  facebook: Facebook,
  filter: Filter,
  refresh: Refresh,
  amplify: Amplify,
  envelope: Envelope,
  upload: Upload,
  bank: Bank,
  fullscreen: Fullscreen,
  share: Share,
}

const RenderIcon = ({
  icon,
  text,
  iconAnimate,
}: {
  icon: ButtonIcon
  text: string
  iconAnimate?: boolean
}) => {
  const Icon = RENDERS[icon]
  return <Icon title={text || undefined} iconAnimate={iconAnimate} />
}

interface ButtonProps {
  buttonStyle?: 'solid' | 'outline' | 'text'
  className?: string
  disabled?: boolean
  form?: string
  forwardedAs?: any
  href?: string
  iconAnimate?: boolean
  icon?: ButtonIcon
  iconOnly?: boolean
  iconPosition?: 'left' | 'right'
  id?: string
  intent?: 'primary' | 'secondary' | 'danger' | 'donate'
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  size?: 'small' | 'medium'
  text: string
  to?: string
  type?: 'button' | 'submit' | 'reset'
}

export const Button = forwardRef(
  (
    {
      buttonStyle = 'solid',
      disabled = false,
      forwardedAs,
      href,
      icon,
      iconOnly = false,
      iconPosition = 'right',
      iconAnimate = false,
      id,
      intent = 'primary',
      onClick,
      size = 'medium',
      text,
      to,
      type = 'button',
      className,
      ...props
    }: ButtonProps,
    ref
  ) => {
    const localRef = useRef(null)
    useImperativeHandle(ref, () => localRef.current)
    if (href) {
      switch (buttonStyle) {
        case 'solid':
          return (
            <StyledSolidLink
              id={id}
              disabled={disabled}
              $iconOnly={iconOnly}
              intent={intent}
              size={size}
              href={href}
              target='_blank'
              rel='noopener noreferrer'
              ref={localRef}
              className={className}
              {...props}
            >
              {icon && iconPosition === 'left' && (
                <IconWrapperLeft iconOnly={iconOnly}>
                  <RenderIcon icon={icon} text={iconOnly ? text : ''} iconAnimate={iconAnimate} />
                </IconWrapperLeft>
              )}
              {!iconOnly ? text : null}
              {icon && iconPosition === 'right' && (
                <IconWrapperRight iconOnly={iconOnly}>
                  <RenderIcon icon={icon} text={iconOnly ? text : ''} iconAnimate={iconAnimate} />
                </IconWrapperRight>
              )}
            </StyledSolidLink>
          )
        case 'outline':
          return (
            <StyledOutlineLink
              id={id}
              disabled={disabled}
              $iconOnly={iconOnly}
              intent={intent}
              size={size}
              href={href}
              target='_blank'
              rel='noopener noreferrer'
              ref={localRef}
              className={className}
              {...props}
            >
              {icon && iconPosition === 'left' && (
                <IconWrapperLeft iconOnly={iconOnly}>
                  <RenderIcon icon={icon} text={iconOnly ? text : ''} iconAnimate={iconAnimate} />
                </IconWrapperLeft>
              )}
              {!iconOnly ? text : null}
              {icon && iconPosition === 'right' && (
                <IconWrapperRight iconOnly={iconOnly}>
                  <RenderIcon icon={icon} text={iconOnly ? text : ''} iconAnimate={iconAnimate} />
                </IconWrapperRight>
              )}
            </StyledOutlineLink>
          )
        case 'text':
          return (
            <StyledTextLink
              id={id}
              disabled={disabled}
              $iconOnly={iconOnly}
              intent={intent}
              size={size}
              href={href}
              target='_blank'
              rel='noopener noreferrer'
              ref={localRef}
              className={className}
              {...props}
            >
              {icon && iconPosition === 'left' && (
                <IconWrapperLeft iconOnly={iconOnly}>
                  <RenderIcon icon={icon} text={iconOnly ? text : ''} iconAnimate={iconAnimate} />
                </IconWrapperLeft>
              )}
              {!iconOnly ? text : null}
              {icon && iconPosition === 'right' && (
                <IconWrapperRight iconOnly={iconOnly}>
                  <RenderIcon icon={icon} text={iconOnly ? text : ''} iconAnimate={iconAnimate} />
                </IconWrapperRight>
              )}
            </StyledTextLink>
          )
        default:
          return null
      }
    }
    switch (buttonStyle) {
      case 'solid':
        return (
          <StyledSolidButton
            id={id}
            disabled={disabled}
            $iconOnly={iconOnly}
            intent={intent}
            size={size}
            onClick={onClick}
            ref={localRef}
            as={forwardedAs}
            to={to}
            type={type}
            className={className}
            {...props}
          >
            {icon && iconPosition === 'left' && (
              <IconWrapperLeft iconOnly={iconOnly}>
                <RenderIcon icon={icon} text={iconOnly ? text : ''} iconAnimate={iconAnimate} />
              </IconWrapperLeft>
            )}
            {!iconOnly ? text : null}
            {icon && iconPosition === 'right' && (
              <IconWrapperRight iconOnly={iconOnly}>
                <RenderIcon icon={icon} text={iconOnly ? text : ''} iconAnimate={iconAnimate} />
              </IconWrapperRight>
            )}
          </StyledSolidButton>
        )
      case 'outline':
        return (
          <StyledOutlineButton
            id={id}
            disabled={disabled}
            $iconOnly={iconOnly}
            intent={intent}
            size={size}
            onClick={onClick}
            ref={localRef}
            as={forwardedAs}
            to={to}
            type={type}
            className={className}
            {...props}
          >
            {icon && iconPosition === 'left' && (
              <IconWrapperLeft iconOnly={iconOnly}>
                <RenderIcon icon={icon} text={iconOnly ? text : ''} iconAnimate={iconAnimate} />
              </IconWrapperLeft>
            )}
            {!iconOnly ? text : null}
            {icon && iconPosition === 'right' && (
              <IconWrapperRight iconOnly={iconOnly}>
                <RenderIcon icon={icon} text={iconOnly ? text : ''} iconAnimate={iconAnimate} />
              </IconWrapperRight>
            )}
          </StyledOutlineButton>
        )
      case 'text':
        return (
          <StyledTextButton
            id={id}
            as={forwardedAs}
            to={to}
            disabled={disabled}
            $iconOnly={iconOnly}
            intent={intent}
            size={size}
            onClick={onClick}
            ref={localRef}
            type={type}
            className={className}
            {...props}
          >
            {icon && iconPosition === 'left' && (
              <IconWrapperLeft iconOnly={iconOnly}>
                <RenderIcon icon={icon} text={iconOnly ? text : ''} iconAnimate={iconAnimate} />
              </IconWrapperLeft>
            )}
            {!iconOnly ? text : null}
            {icon && iconPosition === 'right' && (
              <IconWrapperRight iconOnly={iconOnly}>
                <RenderIcon icon={icon} text={iconOnly ? text : ''} iconAnimate={iconAnimate} />
              </IconWrapperRight>
            )}
          </StyledTextButton>
        )
      default:
        return null
    }
  }
)

Button.displayName = 'Button'
