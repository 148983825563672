import { ApolloClient, InMemoryCache, useMutation } from '@apollo/client'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSearchParams } from 'react-router-dom'

import { statusMessages } from '@tiltify/translations'

import {
  useAuthenticationDispatch,
  useAuthenticationState,
} from '../../contexts/AuthenticationContext'
import { useNotificationContext } from '../../contexts/NotificationContextProvider'
import { useRecaptchaContext } from '../../contexts/RecaptchaContext'
import { update_user_profile } from '../../gql/user'
import AuthenticationService from '../../services/ApiService/AuthenticationService'
import { graphQLErrorParser } from '../../utils'
import { Code } from './Code'
import { CompleteProfile } from './CompleteProfile'
import { MFAVerify } from './MFAVerify'
import { SigninForm } from './SigninForm'
import { Upgrade } from './Upgrade'

export const AuthenticationForm = ({
  enforceFundraiser,
  hideHeading = false,
}: {
  enforceFundraiser?: boolean
  hideHeading?: boolean
}) => {
  const service = new AuthenticationService()
  const [showComplete, setShowComplete] = useState(false)
  const [showUpgrade, setShowUpgrade] = useState(false)
  const [showCode, setShowCode] = useState(false)
  const [showVerify, setShowVerify] = useState(false)
  const [email, setEmail] = useState('')
  const [verifyError, setVerifyError] = useState(false)

  const [completeErrors, setCompleteErrors] = useState()
  const [submitting, setSubmitting] = useState(false)
  const { formatMessage } = useIntl()
  const childWindow = useRef<Window | null>(null)
  const [params] = useSearchParams()
  const [redirect_token] = useState(params.get('redirect_token') || '')
  const [redirect_url] = useState(params.get('redirect_url') || '')
  const { authenticatedUser: user, refetch } = useAuthenticationState()
  const authenticationDispatch = useAuthenticationDispatch()
  const { triggerNotification } = useNotificationContext()
  const { executeAsync } = useRecaptchaContext()

  const client = new ApolloClient({
    uri: `${process.env.REACT_APP_DASHBOARD_API_URL}`,
    cache: new InMemoryCache(),
    credentials: 'include',
  })

  useEffect(() => {
    setVerifyError(false)
  }, [showCode])

  useEffect(() => {
    if (user && !user.profileComplete && user.roles?.fundraiser) {
      setShowComplete(true)
    } else {
      if (user && !user.roles?.fundraiser && enforceFundraiser) {
        setShowUpgrade(true)
      } else {
        if (user && (user.profileComplete || !user.roles?.fundraiser)) {
          if (redirect_url) {
            window.location.replace(redirect_url)
          } else if (redirect_token) {
            window.location.replace(atob(redirect_token))
          } else {
            authenticationDispatch({
              type: useAuthenticationDispatch.ACTION_TYPES.SHOW_AUTHENTICATION,
              item: false,
            })
          }
        }
      }
    }
  }, [user])

  const [updateUserProfile] = useMutation(update_user_profile, {
    onCompleted(data) {
      const user = data.updateUserProfile
      authenticationDispatch({
        type: useAuthenticationDispatch.ACTION_TYPES.UPDATE_USER,
        item: user,
      })

      if (redirect_url) {
        window.location.replace(redirect_url)
      } else {
        authenticationDispatch({
          type: useAuthenticationDispatch.ACTION_TYPES.SHOW_AUTHENTICATION,
          item: false,
        })
      }
    },
    onError({ graphQLErrors }) {
      const errors = graphQLErrorParser(graphQLErrors)
      triggerNotification(errors?.defaultError, true)
      if (errors?.formErrors) {
        setCompleteErrors(errors.formErrors)
      } else triggerNotification(formatMessage(statusMessages.error), true)
    },
    client,
  })

  const handleCompleteProfile = (values: any) => {
    updateUserProfile({ variables: { ...values } })
  }

  const sendOTP = async ({ email }: { email: string }) => {
    setSubmitting(true)
    const captcha = (await executeAsync()) as string

    if (!captcha) {
      setSubmitting(false)
      return triggerNotification('Recaptcha failure, Please try again', true)
    }

    return service
      .sendOTP(email, captcha)
      .then((result) => {
        setSubmitting(false)
        if (result.status === 200) {
          return result.json()
        }
      })
      .then((res) => {
        if (res.errors) {
          return triggerNotification(res.errors, true)
        } else {
          setEmail(email)
          triggerNotification(`Please check your email`)
          setShowCode(true)
        }
      })
      .catch(() => {
        setSubmitting(false)
        triggerNotification('Something went wrong', true)
      })
  }

  const verifyOTP = ({ code }: { code: string }) => {
    setSubmitting(true)
    setVerifyError(false)
    return service
      .verifyOTP(code)
      .then((result) => {
        setSubmitting(false)
        if (result.status === 200) {
          return result.json()
        } else if (result.status === 418) {
          setShowCode(false)
          setShowVerify(true)
        }
        setVerifyError(true)
      })
      .then((result) => {
        authenticationDispatch({
          type: useAuthenticationDispatch.ACTION_TYPES.UPDATE_USER,
          item: result.user,
        })
        if (result.user && refetch) {
          refetch()
        }
      })
      .catch(() => {
        setSubmitting(false)
      })
  }

  const handleSocialRedirect = () => {
    if (redirect_token) {
      return { type: 'token', value: redirect_token }
    }
    if (redirect_url) {
      return { type: 'token', value: btoa(redirect_url) }
    }
    return {
      type: 'token',
      value: btoa(window.location.href),
    }
  }

  const closeChild = (event: MessageEvent) => {
    if (childWindow.current && event.data === 'closeTiltifyLogin') {
      if (refetch) {
        refetch()
      }
      childWindow.current.close()
    }
  }

  const openChild = useCallback((url: string) => {
    const height = 500
    const width = 375
    const y = window.outerHeight / 2 + window.screenY - height / 2
    const x = window.outerWidth / 2 + window.screenX - width / 2

    childWindow.current = window.open(
      url,
      'tiltifyWindow',
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${y}, left=${x}`
    )
  }, [])

  useEffect(() => {
    window.addEventListener('message', closeChild, false)
    return () => {
      window.removeEventListener('message', closeChild)
    }
  }, [openChild])

  if (showVerify) {
    return (
      <MFAVerify
        refetch={refetch}
        setShowVerify={setShowVerify}
        onSuccess={(user) => {
          authenticationDispatch({
            type: useAuthenticationDispatch.ACTION_TYPES.UPDATE_USER,
            item: user,
          })
          if (redirect_url) {
            window.location.replace(redirect_url)
          } else {
            authenticationDispatch({
              type: useAuthenticationDispatch.ACTION_TYPES.SHOW_AUTHENTICATION,
              item: false,
            })
          }
        }}
      />
    )
  }

  if (showComplete) {
    return (
      <CompleteProfile user={user} errors={completeErrors} onComplete={handleCompleteProfile} />
    )
  }

  if (showUpgrade) {
    return <Upgrade user={user} />
  }

  if (showCode) {
    return (
      <Code
        setShowCode={setShowCode}
        onSubmit={verifyOTP}
        email={email}
        submitting={submitting}
        error={verifyError}
        handleResend={() => sendOTP({ email })}
      />
    )
  }

  return (
    <SigninForm
      sendOTP={sendOTP}
      submitting={submitting}
      handleRedirect={handleSocialRedirect}
      openChild={openChild}
      hideHeading={hideHeading}
    />
  )
}
