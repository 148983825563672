export function Snapchat({
  ariaHidden,
  stroke,
  fill,
  focusable,
  height,
  title,
  width,
  isDark,
  ...rest
}: any) {
  const h = height || '1rem'
  const w = width || '1rem'

  return (
    <svg
      viewBox='0 0 24 24'
      width={w}
      height={h}
      aria-hidden={ariaHidden}
      focusable={focusable}
      fill={fill}
      stroke={stroke}
      {...rest}
    >
      {title ? <title>{title}</title> : null}
      <path
        d='M5.829 4.533c-.6 1.344-.363 3.752-.267 5.436-.648.359-1.48-.271-1.951-.271-.49 0-1.075.322-1.167.802-.066.346.089.85 1.201 1.289.43.17 1.453.37 1.69.928.333.784-1.71 4.403-4.918 4.931-.251.041-.43.265-.416.519.056.975 2.242 1.357 3.211 1.507.099.134.179.7.306 1.131.057.193.204.424.582.424.493 0 1.312-.38 2.738-.144 1.398.233 2.712 2.215 5.235 2.215 2.345 0 3.744-1.991 5.09-2.215.779-.129 1.448-.088 2.196.058.515.101.977.157 1.124-.349.129-.437.208-.992.305-1.123.96-.149 3.156-.53 3.211-1.505.014-.254-.165-.477-.416-.519-3.154-.52-5.259-4.128-4.918-4.931.236-.557 1.252-.755 1.69-.928.814-.321 1.222-.716 1.213-1.173-.011-.585-.715-.934-1.233-.934-.527 0-1.284.624-1.897.286.096-1.698.332-4.095-.267-5.438-1.135-2.543-3.66-3.829-6.184-3.829-2.508 0-5.014 1.268-6.158 3.833z'
        strokeWidth='1'
        stroke={isDark ? '#fff' : '#111'}
        fill='#fff'
      />
    </svg>
  )
}

Snapchat.defaultProps = {
  ariaHidden: false,
  focusable: false,
  stroke: '#111',
  fill: '#000',
}
