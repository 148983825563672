import { MediaIframeEmbed } from '../MediaIframeEmbed'

export const Twitch = ({ channel, ...rest }: any) => {
  if (!channel) return null
  return (
    <MediaIframeEmbed
      src={`https://player.twitch.tv/?channel=${channel}&parent=${window.location.hostname}`}
      {...rest}
    />
  )
}
