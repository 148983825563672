export function Website({
  ariaHidden,
  stroke,
  fill,
  focusable,
  height,
  title,
  width,
  isDark,
  ...rest
}: any) {
  const h = height || '1rem'
  const w = width || '1rem'

  return (
    <svg
      viewBox='0 0 18 18'
      width={w}
      height={h}
      aria-hidden={ariaHidden}
      focusable={focusable}
      fill={isDark ? '#fff' : fill}
      stroke={stroke}
      {...rest}
    >
      {title ? <title>{title}</title> : null}
      <path
        d='M6.8787 12.5355l5.6568-5.6568-1.4142-1.4142-5.6568 5.6568 1.4142 1.4142zm-3.6063 2.192c-1.6263-1.6263-1.6263-4.172 0-5.7982L5.3938 6.808 4.0503 5.4645 1.929 7.5858c-2.3335 2.3334-2.3335 6.1518 0 8.4853 2.3334 2.3335 6.1518 2.3335 8.4852 0l2.1213-2.1213-1.3435-1.3435-2.1213 2.1214c-1.6263 1.6263-4.172 1.6263-5.7983 0zM7.5858 1.929L5.4645 4.0502 6.808 5.3938l2.1213-2.1214c1.6263-1.6263 4.172-1.6263 5.7983 0 1.6263 1.6264 1.6263 4.172 0 5.7983l-2.1214 2.1213 1.3435 1.3435 2.1214-2.1213c2.3335-2.3334 2.3335-6.1518 0-8.4853-2.3334-2.3335-6.1518-2.3335-8.4852 0z'
        fillRule='nonzero'
        stroke='none'
      />
    </svg>
  )
}

Website.defaultProps = {
  ariaHidden: false,
  focusable: false,
  stroke: '#000',
  fill: '#000',
}
