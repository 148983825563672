import { camelCase } from 'lodash'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const graphQLErrorParser = (graphQLErrors: any) => {
  if (!graphQLErrors || graphQLErrors.length === 0) return null

  const formErrors = graphQLErrors
    .filter((error: { key: string; message: string }) => !!error.key && error.message)
    .map((error: { key: string; message: string }) => ({
      key: camelCase(error.key),
      message: error.message,
    }))
  const generalErrors = graphQLErrors.map((error: { key: string; message: string }) => {
    if (error.key) return `${error.key} - ${error.message}`
    return `${error.message}`
  })
  const defaultError = generalErrors?.[0] || null
  return { defaultError, generalErrors, formErrors }
}
