import { rem } from 'polished'
import styled, { css } from 'styled-components'

type SizeType = Record<
  string,
  {
    'font-size': string
    'line-height': string
    padding: string
    'letter-spacing': string
    'border-radius': string
  }
>

const SIZE: SizeType = {
  small: {
    'font-size': rem(14),
    'line-height': '1rem',
    padding: `${rem(7)} ${rem(11)}`,
    'letter-spacing': '.25px',
    'border-radius': '6px',
  },
  medium: {
    'line-height': '1rem',
    padding: `${rem(11)} ${rem(19)}`,
    'letter-spacing': '0',
    'font-size': rem(16),
    'border-radius': '8px',
  },
}

const CSSReset = css`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  -webkit-appearance: none !important;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.transition[0]}
`

interface StyledButtonType {
  size: string
  intent: string
  disabled: boolean
  $iconOnly: boolean
}

const SolidButtonStyles = css<StyledButtonType>`
  ${CSSReset}

  font-weight: 500;
  font-family: 'Inter', sans-serif;
  border-radius: 8px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  ${({ disabled }) => (disabled ? 'pointer-events: none;' : null)}

  ${({ size }) => SIZE[size]};
  ${({ $iconOnly, size }) => {
    if ($iconOnly)
      return size === 'small'
        ? css`
            padding: ${rem(8)};
          `
        : css`
            padding: ${rem(12)};
          `
    return ''
  }}

  ${({ disabled, intent, theme }) => {
    switch (intent) {
      case 'primary':
        return css`
          color: ${theme.isDark ? theme.colors.black.core : theme.colors.white.core};
          background-color: ${theme.isDark ? theme.colors.blue[100] : theme.colors.blue.core};
          border: 1px solid ${theme.isDark ? theme.colors.blue[100] : theme.colors.blue.core};

          & path {
            fill: ${theme.isDark ? theme.colors.black[600] : theme.colors.black[50]};
          }

          &:hover {
            background-color: ${theme.isDark ? theme.colors.blue[200] : theme.colors.blue[700]};
            border-color: ${theme.isDark ? theme.colors.blue[200] : theme.colors.blue[700]};
          }

          &:focus {
            box-shadow: 0px 0px 8px ${theme.isDark ? theme.colors.blue[100] : '#475cf6'};
            border-color: ${theme.isDark ? theme.colors.blue[200] : theme.colors.white.core};
          }

          ${disabled
            ? css`
                background-color: ${theme.isDark
                  ? theme.colors.black[600]
                  : theme.colors.black[100]};
                border-color: ${theme.isDark ? theme.colors.black[600] : theme.colors.black[100]};
                color: ${theme.isDark ? theme.colors.black[300] : theme.colors.black[500]};

                & path {
                  fill: ${theme.isDark ? theme.colors.black[700] : theme.colors.black[600]};
                }
              `
            : ''}
        `
      case 'secondary':
        return css`
          color: ${theme.isDark ? theme.colors.black[100] : theme.colors.white.core};
          background-color: ${theme.colors.black[700]};
          border: 1px solid ${theme.colors.black[600]};

          & path {
            fill: ${theme.isDark ? theme.colors.black[50] : theme.colors.black[50]};
          }

          &:hover,
          &:focus {
            background-color: ${theme.colors.black[800]};
            border-color: ${theme.colors.black[800]};
          }

          ${({ theme }) =>
            theme.isDark &&
            css`
              &:focus {
                box-shadow: theme.depth[8];
              }
            `}

          &:focus {
            box-shadow: 0px 0px 8px ${theme.colors.blue.core};
            border-color: #fff;
          }

          ${disabled
            ? css`
                background-color: ${theme.colors.black[100]};
                border-color: ${theme.colors.black[100]};
                color: ${theme.colors.black[500]};

                & path {
                  fill: ${theme.colors.black[600]};
                }
              `
            : ''}
        `
      case 'danger':
        return css`
          color: ${theme.colors.white.core};
          background-color: ${theme.isDark ? theme.colors.error[800] : theme.colors.error.core};
          border: 1px solid ${theme.isDark ? theme.colors.error[800] : theme.colors.error.core};

          & path {
            fill: ${theme.colors.black[50]};
          }

          &:hover {
            background-color: ${theme.isDark ? theme.colors.error[700] : theme.colors.error[800]};
            border-color: ${theme.isDark ? theme.colors.error[700] : theme.colors.error[800]};
          }

          &:focus {
            ${theme.isDark &&
            css`
              background-color: ${theme.colors.error[700]};
            `}
            box-shadow: 0px 0px 8px ${theme.isDark
              ? theme.colors.error[100]
              : theme.colors.error.core};
            border-color: ${theme.colors.white.core};
          }

          ${disabled
            ? css`
                background-color: ${theme.isDark
                  ? theme.colors.black[600]
                  : theme.colors.black[300]};
                border-color: ${theme.isDark ? theme.colors.black[600] : theme.colors.black[300]};
                color: ${theme.isDark ? theme.colors.black[300] : theme.colors.white.core};
              `
            : ''}
        `
      case 'donate':
        return css`
          color: ${theme.colors.green[900]};
          background-color: ${theme.colors.green.core};
          border: 1px solid ${theme.colors.green.core};

          & path {
            fill: ${theme.colors.black[600]};
          }

          &:hover {
            background-color: ${theme.colors.green[400]};
            border-color: ${theme.colors.green[400]};
          }

          &:focus {
            box-shadow: 0px 0px 8px ${theme.colors.green[700]};
            border-color: #fff;
          }

          ${disabled
            ? css`
                background-color: ${theme.isDark
                  ? theme.colors.black[500]
                  : theme.colors.black[100]};
                border-color: ${theme.isDark ? theme.colors.black[500] : theme.colors.black[100]};
                color: ${theme.isDark ? theme.colors.black[300] : theme.colors.black[400]};

                ${theme.isDark &&
                css`
                  path {
                    fill: ${theme.colors.black[50]};
                  }
                `}
              `
            : ''}
        `
      default:
        return css``
    }
  }}
`

const OutlineButtonStyles = css<StyledButtonType>`
  ${CSSReset}

  font-weight: 500;
  font-family: 'Inter', sans-serif;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  ${({ disabled }) => (disabled ? 'pointer-events: none;' : null)}

  ${({ size }) => SIZE[size]};
  ${({ $iconOnly, size }) => {
    if ($iconOnly)
      return size === 'small'
        ? css`
            padding: ${rem(8)};
          `
        : css`
            padding: ${rem(12)};
          `
    return ''
  }}

  ${({ disabled, intent, theme }) => {
    switch (intent) {
      case 'primary':
        return css`
          color: ${theme.isDark ? theme.colors.blue[100] : theme.colors.blue.core};
          background-color: transparent;
          border: 1px solid ${theme.isDark ? theme.colors.blue[100] : theme.colors.blue.core};

          & path {
            fill: ${theme.isDark ? theme.colors.blue[200] : theme.colors.blue.core};
          }

          &:hover {
            background-color: ${theme.isDark ? theme.colors.black[800] : theme.colors.blue[100]};
            border-color: ${theme.isDark ? theme.colors.blue[200] : theme.colors.blue.core};
            box-shadow:
              0px 6px 16px rgba(50, 53, 58, 0.13),
              0px 1px 1px rgba(50, 53, 58, 0.1);
          }

          &:focus {
            background-color: ${theme.isDark ? theme.colors.black[800] : theme.colors.blue[100]};
            box-shadow: 0px 0px 8px ${theme.isDark ? theme.colors.blue[100] : '#475cf6'};
            border-color: ${theme.isDark ? theme.colors.blue[200] : theme.colors.blue.core};
          }

          ${disabled
            ? css`
                background-color: transparent;
                border-color: ${theme.isDark ? theme.colors.black[500] : theme.colors.black[400]};
                color: ${theme.isDark ? theme.colors.black[500] : theme.colors.black[400]};

                & path {
                  fill: ${theme.isDark ? theme.colors.black[600] : theme.colors.black[600]};
                }
              `
            : null}
        `
      case 'secondary':
        return css`
          color: ${theme.isDark ? theme.colors.black[100] : theme.colors.black[600]};
          background-color: transparent;
          border: 1px solid ${theme.isDark ? theme.colors.black[400] : theme.colors.black[200]};

          & path {
            fill: ${theme.isDark ? theme.colors.black[50] : theme.colors.black[600]};
          }

          &:hover {
            box-shadow:
              0px 6px 16px rgba(50, 53, 58, 0.13),
              0px 1px 1px rgba(50, 53, 58, 0.1);
            background-color: ${theme.isDark ? theme.colors.black[700] : theme.colors.black[50]};
            border-color: ${theme.isDark ? theme.colors.black[200] : theme.colors.black[600]};
          }

          &:focus {
            ${theme.isDark &&
            css`
              color: ${theme.colors.black[50]};
            `}
            background-color: ${theme.isDark ? theme.colors.black[600] : theme.colors.black[50]};
            border-color: ${theme.isDark ? theme.colors.black[50] : theme.colors.black[600]};
            box-shadow: 0px 0px 8px
              ${theme.isDark ? theme.colors.black[50] : theme.colors.blue.core};
          }

          ${disabled
            ? css`
                background-color: ${theme.colors.black[100]};
                border-color: ${theme.isDark ? theme.colors.black[600] : theme.colors.black[400]};
                color: ${theme.isDark ? theme.colors.black[600] : theme.colors.black[400]};

                ${theme.isDark &&
                css`
                  & path {
                    fill: ${theme.colors.black[600]};
                  }
                `}
              `
            : null}
        `
      default:
        return css``
    }
  }}
`

const TextButtonStyles = css<StyledButtonType>`
  ${CSSReset}

  font-weight: 500;
  font-family: 'Inter', sans-serif;
  text-decoration: underline;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  ${({ disabled }) => (disabled ? 'pointer-events: none;' : null)}

  ${({ size }) => SIZE[size]};
  ${({ $iconOnly, size }) => {
    if ($iconOnly)
      return size === 'small'
        ? css`
            padding: ${rem(7)};
          `
        : css`
            padding: ${rem(11)};
          `
    return ''
  }}

  ${({ disabled, intent, theme }) => {
    switch (intent) {
      case 'primary':
        return css`
          color: ${theme.isDark ? theme.colors.blue[100] : theme.colors.blue.core};
          background-color: transparent;
          border: 1px solid transparent;

          & path {
            fill: ${theme.isDark ? theme.colors.blue[200] : theme.colors.blue.core};
          }

          &:hover {
            background-color: ${theme.isDark ? theme.colors.black[800] : theme.colors.blue[50]};
            text-decoration: none;
          }

          &:focus {
            background-color: ${theme.isDark ? theme.colors.black.core : theme.colors.blue[100]};
            text-decoration: none;
            ${theme.isDark &&
            css`
              box-shadow: 0px 0px 8px ${theme.colors.blue[100]};
            `}
          }

          ${disabled
            ? css`
                background-color: transparent;
                color: ${theme.isDark ? theme.colors.black[500] : theme.colors.black[300]};
                text-decoration: none;

                & path {
                  fill: ${theme.colors.black[600]};
                }
              `
            : null}
        `
      case 'secondary':
        return css`
          color: ${theme.isDark ? theme.colors.black[100] : theme.colors.black[600]};
          background-color: transparent;

          & path {
            fill: ${theme.isDark ? theme.colors.black[50] : theme.colors.black[600]};
          }

          &:hover {
            background-color: ${theme.isDark ? theme.colors.black[700] : theme.colors.black[50]};
            text-decoration: none;
            ${theme.isDark &&
            css`
              color: ${theme.colors.black[50]};
            `}
          }

          &:focus {
            background-color: ${theme.isDark ? theme.colors.black[700] : theme.colors.black[100]};
            text-decoration: none;
            ${theme.isDark &&
            css`
              box-shadow: 0px 0px 8px ${theme.colors.black[50]};
              color: ${theme.colors.black[50]};
            `}
          }

          ${disabled
            ? css`
                background-color: transparent;
                color: ${theme.isDark ? theme.colors.black[600] : theme.colors.black[400]};
                text-decoration: none;
                ${theme.isDark &&
                css`
                  & path {
                    fill: ${theme.colors.black[600]};
                  }
                `}
              `
            : null}
        `

      case 'danger':
        return css`
          color: ${theme.isDark ? theme.colors.error[200] : theme.colors.error.core};
          background-color: transparent;

          & path {
            fill: ${theme.isDark ? theme.colors.error[200] : theme.colors.error.core};
          }

          &:hover {
            background-color: ${theme.isDark ? theme.colors.error[800] : theme.colors.error[50]};
            text-decoration: none;
          }

          &:focus {
            background-color: ${theme.isDark ? theme.colors.error[700] : theme.colors.error[100]};
            text-decoration: none;
            ${theme.isDark &&
            css`
              box-shadow: 0px 0px 8px ${theme.colors.error[50]};
            `}
          }

          ${disabled
            ? css`
                background-color: transparent;
                color: ${theme.isDark ? theme.colors.black[500] : theme.colors.black[400]};
                text-decoration: none;
                ${theme.isDark &&
                css`
                  & path {
                    fill: ${theme.colors.black[600]};
                  }
                `}
              `
            : null}
        `
      default:
        return css``
    }
  }}
`

export const StyledSolidButton = styled.button`
  ${SolidButtonStyles}
`

export const StyledOutlineButton = styled.button`
  ${OutlineButtonStyles}
`

export const StyledTextButton = styled.button`
  ${TextButtonStyles}
`

export const StyledSolidLink = styled.a<StyledButtonType>`
  ${SolidButtonStyles}
  text-decoration: none;
  width: fit-content;

  ${({ disabled, intent, theme }) => {
    switch (intent) {
      case 'primary':
        if (disabled) {
          return css`
            background-color: ${theme.isDark ? theme.colors.black[600] : theme.colors.black[100]};
            border-color: ${theme.isDark ? theme.colors.black[600] : theme.colors.black[100]};
            color: ${theme.isDark ? theme.colors.black[300] : theme.colors.black[500]};

            & path {
              fill: ${theme.isDark ? theme.colors.black[700] : theme.colors.black[600]};
            }
          `
        }

        return css``
      case 'secondary':
        if (disabled) {
          return css`
            background-color: ${theme.isDark ? theme.colors.black[600] : theme.colors.black[100]};
            border-color: ${theme.isDark ? theme.colors.black[600] : theme.colors.black[100]};
            color: ${theme.isDark ? theme.colors.black[300] : theme.colors.black[500]};

            & path {
              fill: ${theme.isDark ? theme.colors.black[50] : theme.colors.black[600]};
            }
          `
        }
        return css``
      case 'danger':
        if (disabled) {
          return css`
            background-color: ${theme.isDark ? theme.colors.black[600] : theme.colors.black[300]};
            border-color: ${theme.isDark ? theme.colors.black[600] : theme.colors.black[300]};
            color: ${theme.isDark ? theme.colors.black[300] : theme.colors.white.core};
          `
        }

        return css``
      case 'donate':
        if (disabled) {
          return css`
            background-color: ${theme.isDark ? theme.colors.black[500] : theme.colors.black[100]};
            border-color: ${theme.isDark ? theme.colors.black[500] : theme.colors.black[100]};
            color: ${theme.isDark ? theme.colors.black[300] : theme.colors.black[400]};

            ${theme.isDark &&
            css`
              & path {
                fill: ${theme.colors.black[50]};
              }
            `}
          `
        }
        return css``
      default:
        return css``
    }
  }}
`

export const StyledOutlineLink = styled.a`
  ${OutlineButtonStyles}
  text-decoration: none;
  width: fit-content;

  ${({ disabled, intent, theme }) => {
    switch (intent) {
      case 'primary':
        if (disabled) {
          return css`
            background-color: transparent;
            border-color: ${theme.isDark ? theme.colors.black[500] : theme.colors.black[400]};
            color: ${theme.isDark ? theme.colors.black[500] : theme.colors.black[400]};

            & path {
              fill: ${theme.isDark ? theme.colors.black[600] : theme.colors.black[600]};
            }
          `
        }

        return css``
      case 'secondary':
        if (disabled) {
          return css`
            background-color: transparent;
            border-color: ${theme.isDark ? theme.colors.black[600] : theme.colors.black[400]};
            color: ${theme.isDark ? theme.colors.black[600] : theme.colors.black[400]};

            ${theme.isDark &&
            css`
              & path {
                fill: ${theme.colors.black[600]};
              }
            `}
          `
        }
        return css``
      default:
        return css``
    }
  }}
`

export const StyledTextLink = styled.a<StyledButtonType>`
  ${TextButtonStyles}
  width: fit-content;
  ${({ disabled, intent, theme }) => {
    switch (intent) {
      case 'primary':
        if (disabled) {
          return css`
            background-color: transparent;
            color: ${theme.isDark ? theme.colors.black[500] : theme.colors.black[300]};
            text-decoration: none;

            & path {
              fill: ${theme.colors.black[600]};
            }
          `
        }

        return css``
      case 'secondary':
        if (disabled) {
          return css`
            background-color: transparent;
            color: ${theme.isDark ? theme.colors.black[600] : theme.colors.black[400]};
            text-decoration: none;
            ${theme.isDark &&
            css`
              & path {
                fill: ${theme.colors.black[600]};
              }
            `}
          `
        }
        return css``
      default:
        return css``
    }
  }}
`
