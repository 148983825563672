import { useTheme } from 'styled-components'

import { useFillColor, useSize } from './IconHooks'

export const Filter = ({
  fill,
  fillType = 'black.50',
  size = 'small',
  height,
  width,
  title = 'Filter',
}: TiltifyIcon) => {
  const theme = useTheme()
  const sizeValues = useSize(size, height, width)
  const fillValue = useFillColor(theme, fillType, fill)

  return (
    <svg
      width={sizeValues.width}
      height={sizeValues.height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>{title}</title>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.728434 0.719456C0.837673 0.484013 1.07363 0.333374 1.33318 0.333374H14.6665C14.9261 0.333374 15.162 0.484013 15.2713 0.719456C15.3805 0.954899 15.3432 1.23234 15.1756 1.43052L9.99985 7.55081V13C9.99985 13.2311 9.88021 13.4457 9.68367 13.5671C9.48712 13.6886 9.2417 13.6997 9.03504 13.5963L6.36837 12.263C6.14251 12.1501 5.99985 11.9192 5.99985 11.6667V7.55081L0.824133 1.43052C0.656534 1.23234 0.619195 0.954899 0.728434 0.719456ZM2.77005 1.66671L7.17556 6.87622C7.27734 6.99657 7.33318 7.14909 7.33318 7.30671V11.2547L8.66651 11.9214V7.30671C8.66651 7.14909 8.72236 6.99657 8.82413 6.87622L13.2296 1.66671H2.77005Z'
        fill={fillValue}
      />
    </svg>
  )
}
