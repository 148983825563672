import styled from 'styled-components'

import { Button } from '@tiltify/design/components/Button'

import {
  useAuthenticationDispatch,
  useAuthenticationState,
} from '../../../../contexts/AuthenticationContext'

const StyledAuth = styled.div`
  display: flex;
`

export const AuthOptions = () => {
  const { loading } = useAuthenticationState()
  const authenticationDispatch = useAuthenticationDispatch()

  const handleLoginButton = () => {
    authenticationDispatch({
      type: useAuthenticationDispatch.ACTION_TYPES.SHOW_AUTHENTICATION,
      item: true,
    })
  }

  if (loading) return null

  return (
    <StyledAuth id='gtm_auth-buttons'>
      <Button id='signInButton' buttonStyle='solid' text='Sign in' onClick={handleLoginButton} />
    </StyledAuth>
  )
}
