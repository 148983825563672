import { useTheme } from 'styled-components'

import { useFillColor, useSize } from './IconHooks'

export const CaretUp = ({
  fill,
  fillType = 'black.50',
  height,
  width,
  size = 'small',
  title = 'Up',
}: TiltifyIcon) => {
  const theme = useTheme()
  const sizeValues = useSize(size, height, width)
  const fillValue = useFillColor(theme, fillType, fill)

  return (
    <svg
      width={sizeValues.width}
      height={sizeValues.height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>{title}</title>
      <path
        d='M7.5358 5.2958L3.2958 9.5358C3.20207 9.62876 3.12768 9.73936 3.07691 9.86122C3.02614 9.98308 3 10.1138 3 10.2458C3 10.3778 3.02614 10.5085 3.07691 10.6304C3.12768 10.7522 3.20207 10.8628 3.2958 10.9558C3.48316 11.142 3.73661 11.2466 4.0008 11.2466C4.26498 11.2466 4.51844 11.142 4.7058 10.9558L8.2458 7.4158L11.7858 10.9558C11.9732 11.142 12.2266 11.2466 12.4908 11.2466C12.755 11.2466 13.0084 11.142 13.1958 10.9558C13.2885 10.8624 13.3618 10.7515 13.4116 10.6297C13.4613 10.5079 13.4866 10.3774 13.4858 10.2458C13.4866 10.1142 13.4613 9.98373 13.4116 9.86189C13.3618 9.74005 13.2885 9.62924 13.1958 9.5358L8.9558 5.2958C8.86283 5.20207 8.75223 5.12768 8.63037 5.07691C8.50852 5.02614 8.37781 5 8.2458 5C8.11379 5 7.98308 5.02614 7.86122 5.07691C7.73936 5.12768 7.62876 5.20207 7.5358 5.2958Z'
        fill={fillValue}
      />
    </svg>
  )
}
