import { HTMLAttributes, ReactNode } from 'react'
import styled from 'styled-components'

interface ProgressProps extends HTMLAttributes<HTMLDivElement> {
  width: number
  widget: boolean
  height: number
  background: string | (() => string)
  radius: number
  progressBarStyle: '' | 'diagonal'
}

interface BarWrapperProps extends HTMLAttributes<HTMLDivElement> {
  width: number
  radius: number
  customBarWrapper: boolean
  barHeight: number
  height: number
}

interface BarProps extends HTMLAttributes<HTMLDivElement> {
  width?: number
  foreground: string | (() => string)
  radius: number
  percent: number
}

const Progress = styled.div<ProgressProps>`
  width: ${({ width }) => width}%;
  ${({ widget, height }) => widget && `height: ${height}px`};
  min-height: ${({ height }) => height}px;
  background: ${({ background }) => background};
  border-radius: ${({ radius }) => radius}px;
  position: relative;
  transform-origin: 100%;
  display: flex;
  align-items: center;
  border: solid 1px transparent;

  ${({ progressBarStyle }) =>
    progressBarStyle &&
    `
      transform: skewX(-35deg);
    `}
`

const BarWrapper = styled.div<BarWrapperProps>`
  height: 100%;
  width: ${({ width }) => width}%;
  border-radius: ${({ radius }) => radius}px;
  overflow: hidden;
  position: absolute;

  ${({ customBarWrapper, barHeight }) =>
    customBarWrapper &&
    `
    height: ${barHeight}%;
    width: calc(100% - 10px);
    left: 5px;
  `}
`

const Bar = styled.div<BarProps>`
  background: ${({ foreground }) => foreground};
  border-radius: 0 ${({ radius, percent }) => (percent >= 1 ? 0 : `${radius}px`)}
    ${({ radius, percent }) => (percent >= 1 ? 0 : `${radius}px`)} 0;
  transform: scaleX(${({ percent }) => percent});
  transform-origin: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 500ms;
`

const ChildWrapper = styled.div`
  float: left;
  width: 100%;
  z-index: 1;
`

interface ProgressBarProps {
  height?: number
  width?: number
  percent?: number
  background?: string | (() => string)
  foreground?: string | (() => string)
  children?: ReactNode
  radius?: number
  widget?: boolean
  progressBarStyle?: '' | 'diagonal'
  barHeight?: number
  customBarWrapper?: boolean
  style?: {
    border?: string
    bottom?: number
    margin?: number
    height?: string | number
    width?: string
    left?: string
    outline?: string
    flex?: number
    zIndex?: number
    marginTop?: string
    marginBottom?: string
    position?: any
    maxWidth?: string
  }
}

export const ProgressBar = ({
  height = 8,
  width = 100,
  percent = 0,
  background = 'rgba(129,190,244, .1)',
  foreground = 'rgba(129, 190, 244, 0.2)',
  children,
  radius = 12,
  widget = false,
  progressBarStyle = '',
  barHeight = 100,
  customBarWrapper = false,
  ...rest
}: ProgressBarProps) => {
  if (percent < 0) percent = 0
  if (percent > 1) percent = 1

  return (
    <Progress
      radius={radius}
      width={width}
      height={height}
      background={background}
      widget={widget}
      progressBarStyle={progressBarStyle}
      role='progressbar'
      aria-label='progress bar'
      {...rest}
    >
      <BarWrapper
        customBarWrapper={customBarWrapper}
        barHeight={barHeight}
        radius={radius}
        width={width}
        height={height}
      >
        <Bar id='bar' radius={radius} percent={percent} foreground={foreground} />
      </BarWrapper>
      <ChildWrapper>{children}</ChildWrapper>
    </Progress>
  )
}
