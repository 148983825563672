import bit from '../../assets/Games2023/8_bit_armies.jpg'
import hike from '../../assets/Games2023/a_short_hike.jpg'
import story from '../../assets/Games2023/a_story_beside.jpg'
import ace from '../../assets/Games2023/Aces_and_Adventures.jpg'
import alekon from '../../assets/Games2023/Alekon.jpg'
import arcade from '../../assets/Games2023/Arcade_Spirits.jpg'
import astronimo from '../../assets/Games2023/Astronimo.jpg'
import battlestar from '../../assets/Games2023/Battlestar_Galactica_Deadlock.jpg'
import beyond from '../../assets/Games2023/Beyond_Blue.jpg'
import buggos from '../../assets/Games2023/Buggos.jpg'
import candy from '../../assets/Games2023/Candy_Disaster.jpg'
import cat from '../../assets/Games2023/Cat_Quest.jpg'
import cook from '../../assets/Games2023/cook.jpg'
import cosmo from '../../assets/Games2023/Cosmo_Quickstop.jpg'
import death from '../../assets/Games2023/Death_and_Taxes.jpg'
import deep from '../../assets/Games2023/Deepest_Chamber_Resurrection.jpg'
import deploy from '../../assets/Games2023/DEPLOYMENT.jpg'
import earth from '../../assets/Games2023/Earthlock.jpg'
import entropy from '../../assets/Games2023/entropy.jpg'
import eyes from '../../assets/Games2023/Eyes_in_the_Dark.jpg'
import feed from '../../assets/Games2023/Feed_All_Monsters.jpg'
import profit from '../../assets/Games2023/Final_Profit.jpg'
import fly from '../../assets/Games2023/Fly_Punch_Boom.jpg'
import space from '../../assets/Games2023/space.jpg'
import godfall from '../../assets/Games2023/Godfall.jpg'
import golfie from '../../assets/Games2023/Golfie.jpg'
import growbot from '../../assets/Games2023/Growbot.jpg'
import harmony from '../../assets/Games2023/Harmony_Odyssey.jpg'
import heavy from '../../assets/Games2023/Heavy_Burden.jpg'
import deserts from '../../assets/Games2023/Homeworld_Deserts_of_Kharak.jpg'
import homeworld from '../../assets/Games2023/Homeworld_Remastered_Collection.jpg'
import ikonie from '../../assets/Games2023/Ikonei_Island.jpg'
import imp from '../../assets/Games2023/Imp_of_the_Sun.jpg'
import lacuna from '../../assets/Games2023/Lacuna.jpg'
import long from '../../assets/Games2023/Longvinter.jpg'
import loud from '../../assets/Games2023/LOUD_My_Road_to_Fame.jpg'
import make from '../../assets/Games2023/make_way.jpg'
import marble from '../../assets/Games2023/MArble_world.jpg'
import mars from '../../assets/Games2023/Mars_Horizon.jpg'
import meta from '../../assets/Games2023/MetaPhysical.jpg'
import monster from '../../assets/Games2023/Monster_Prom.jpg'
import paradise from '../../assets/Games2023/Paradise_Marsh.jpg'
import patch from '../../assets/Games2023/Patch_Quest.jpg'
import projections from '../../assets/Games2023/PROJECTIONS.jpg'
import repella from '../../assets/Games2023/Repella_Fella.jpg'
import run from '../../assets/Games2023/Run.jpg'
import rusty from '../../assets/Games2023/Rusty_Lake_Hotel.jpg'
import sapiens from '../../assets/Games2023/Sapiens.jpg'
import sephonie from '../../assets/Games2023/Sephonie.jpg'
import sifu from '../../assets/Games2023/SIFU.jpg'
import signs from '../../assets/Games2023/Signs_of_the_Sojourner.jpg'
import silicon from '../../assets/Games2023/Silicon_Zeroes.jpg'
import silt from '../../assets/Games2023/SILT.jpg'
import slap from '../../assets/Games2023/Slap_City.jpg'
import slice from '../../assets/Games2023/Slice_of_Sea.jpg'
import spiritIsland from '../../assets/Games2023/spirit.jpg'
import start from '../../assets/Games2023/start_again.jpg'
import cable from '../../assets/Games2023/Super_Cable_Boy.jpg'
import superhot from '../../assets/Games2023/SUPERHOT.jpg'
import tabs from '../../assets/Games2023/tabs.jpg'
import lantern from '../../assets/Games2023/The_Red_Lantern.jpg'
import thief from '../../assets/Games2023/Thief_Simulator.jpg'
import tinylife from '../../assets/Games2023/Tiny_Life.jpg'
import tinytopia from '../../assets/Games2023/Tinytopia.jpg'
import toodee from '../../assets/Games2023/Toodee.jpg'
import train from '../../assets/Games2023/Train.jpg'
import trifox from '../../assets/Games2023/Trifox.jpg'
import tunguska from '../../assets/Games2023/Tunguska.jpg'
import turbo from '../../assets/Games2023/turbo_golf.jpg'
import valthirian from '../../assets/Games2023/Valthirian.jpg'
import vermillion from '../../assets/Games2023/Vermillion.jpg'
import war from '../../assets/Games2023/war_are_the_dwarves.jpg'
import whispers from '../../assets/Games2023/whispers.jpg'
import witch from '../../assets/Games2023/Witchtastic.jpg'
import xenonauts from '../../assets/Games2023/Xenonauts.jpg'
import zapling from '../../assets/Games2023/Zapling_Bygone.jpg'
import zeepkist from '../../assets/Games2023/Zeepkist.jpg'
import displate from '../../assets/Games2023/Displate.png'
import glitch from '../../assets/Games2023/glitch.jpg'
import mcc from '../../assets/Games2023/mcc.png'
import plateup from '../../assets/Games2023/plateup.png'
import fanatical from '../../assets/Games2023/fanatical.png'

export const games = [
  {
    name: 'Aces & Adventures',
    detail: 'Deal Life and Death',
    image: ace,
    dev: 'Yogscast Games',
    link: 'https://store.steampowered.com/app/1815570/Aces__Adventures/',
  },
  {
    name: 'Totally Accurate Battle Simulator',
    detail: 'Totally Accurate Battles',
    image: tabs,
    dev: 'Landfall',
    link: 'https://store.steampowered.com/app/508440/Totally_Accurate_Battle_Simulator/',
  },
  {
    name: 'Superhot',
    detail: 'Stylishly Freeze Time',
    image: superhot,
    dev: 'SUPERHOT Team',
    link: 'https://store.steampowered.com/app/322500/SUPERHOT/',
  },
  {
    name: 'The Entropy Centre',
    detail: 'Do Some Science',
    image: entropy,
    dev: 'Playstack',
    link: 'https://store.steampowered.com/app/1730590/The_Entropy_Centre/',
  },
  {
    name: 'Toodee and Topdee',
    detail: 'Mind-melt with Friend',
    image: toodee,
    dev: 'dietzribi',
    link: 'https://store.steampowered.com/app/1303950/Toodee_and_Topdee/',
  },
  {
    name: 'A Short Hike',
    detail: 'Chill Cozy Climbing',
    image: hike,
    dev: 'adamgryu',
    link: 'https://store.steampowered.com/app/1055540/A_Short_Hike/',
  },
  {
    name: 'Longvinter',
    detail: 'Cute Loot Craft Steal',
    image: long,
    dev: 'Uuvana Studios',
    link: 'https://store.steampowered.com/app/1635450/Longvinter/',
  },
  {
    name: 'Sapiens',
    detail: 'Caveman Colony Sim',
    image: sapiens,
    dev: 'Majic Jungle',
    link: 'https://store.steampowered.com/app/1060230/Sapiens/',
  },
  {
    name: 'Patch Quest',
    detail: 'Monster Taming bullet-hell',
    image: patch,
    dev: 'Curve Digital',
    link: 'https://store.steampowered.com/app/1347970/Patch_Quest/',
  },
  {
    name: 'Xenonauts',
    detail: 'Tactical Alien Resistance',
    image: xenonauts,
    dev: 'Goldhawk Interactive',
    link: 'https://store.steampowered.com/app/223830/Xenonauts/',
  },
  {
    name: 'Marble World',
    detail: 'Marbles Marbles Marbles',
    image: marble,
    dev: 'Vector Interactive',
    link: 'https://store.steampowered.com/app/1491340/Marble_World/',
  },
  {
    name: 'Make Way',
    detail: 'Chaotic Creative Racing',
    image: make,
    dev: 'Secret Mode',
    link: 'https://store.steampowered.com/app/1445790/Make_Way/',
  },
  {
    name: 'Thief Simulator',
    detail: 'Steal Things',
    image: thief,
    dev: 'PlayWay',
    link: 'https://store.steampowered.com/app/704850/Thief_Simulator/',
  },
  {
    name: 'Monster Prom',
    detail: 'Date a Monster',
    image: monster,
    dev: 'Beautiful Glitch',
    link: 'https://store.steampowered.com/app/743450/Monster_Prom/',
  },
  {
    name: 'Astronimo',
    detail: 'Build Bots Assemble',
    image: astronimo,
    dev: 'Thunderful',
    link: 'https://store.steampowered.com/app/1808640/Astronimo/',
  },
  {
    name: 'Lacuna',
    detail: 'Pixel Noir Detective',
    image: lacuna,
    dev: 'Assemble Entertainment',
    link: 'https://store.steampowered.com/app/1364100/Lacuna__A_SciFi_Noir_Adventure/',
  },
  {
    name: '8-bit Armies',
    detail: 'Blocky Pixel Strategy',
    image: bit,
    dev: 'Petroglyph',
    link: 'https://store.steampowered.com/app/427250/8Bit_Armies/',
  },
  {
    name: 'A Story Beside',
    detail: 'Heartwarming Cozy Journey',
    image: story,
    dev: 'Wayward Prophet',
    link: 'https://store.steampowered.com/app/634410/A_Story_Beside/',
  },
  {
    name: 'Alekon',
    detail: 'Photograph Cute Creatures',
    image: alekon,
    dev: 'The Alekon Company',
    link: 'https://store.steampowered.com/app/1479390/Alekon/',
  },
  {
    name: 'Arcade Spirits',
    detail: 'Retro-gaming Dating Sim',
    image: arcade,
    dev: 'PQube',
    link: 'https://store.steampowered.com/app/910630/Arcade_Spirits/',
  },
  {
    name: 'Battlestar Galactica: Deadlock',
    detail: 'Anti-Cylon Tactics',
    image: battlestar,
    dev: 'Slitherine Ltd',
    link: 'https://store.steampowered.com/app/544610/Battlestar_Galactica_Deadlock/',
  },
  {
    name: 'Beyond Blue',
    detail: 'Deep Sea Exploration',
    image: beyond,
    dev: 'E-Line Media',
    link: 'https://store.steampowered.com/app/883360/Beyond_Blue/',
  },
  {
    name: 'Buggos',
    detail: 'Awesome Auto-battler',
    image: buggos,
    dev: 'Intrepid Marmot',
    link: 'https://store.steampowered.com/app/789660/Buggos/',
  },
  {
    name: 'Candy Disaster Tower Defense',
    detail: 'Crazy Trap Combining',
    image: candy,
    dev: 'Erabit',
    link: 'https://store.steampowered.com/app/1231460/Candy_Disaster__Tower_Defense/',
  },
  {
    name: 'Cat Quest',
    detail: 'Adorable Cat RPG',
    image: cat,
    dev: 'Kepler Interactive',
    link: 'https://store.steampowered.com/app/593280/Cat_Quest/',
  },
  {
    name: 'Cook',
    detail: 'Cooking Typing Management',
    image: cook,
    dev: 'Vertigo Gaming Inc',
    link: 'https://store.steampowered.com/app/1000030/Cook_Serve_Delicious_3/',
  },
  {
    name: "Cosmo's Quickstop",
    detail: 'Frantic Alien Cleanup',
    image: cosmo,
    dev: 'Big Sir Games',
    link: 'https://store.steampowered.com/app/724680/Cosmos_Quickstop/',
  },
  {
    name: 'Death and Taxes',
    detail: "Grim Reaper's Office Job",
    image: death,
    dev: 'Placeholder Gameworks',
    link: 'https://store.steampowered.com/app/1166290/Death_and_Taxes/',
  },
  {
    name: 'Deepest Chamber: Resurrection',
    detail: 'Gritty Card Battler',
    image: deep,
    dev: 'Balcony Softworks / Those Awesome Guys',
    link: 'https://store.steampowered.com/app/1552080/Deepest_Chamber_Resurrection/',
  },
  {
    name: 'DEPLOYMENT',
    detail: 'Intense Competitive Arena',
    image: deploy,
    dev: 'Whale Rock Games',
    link: 'https://store.steampowered.com/app/755980/DEPLOYMENT/',
  },
  {
    name: 'EARTHLOCK',
    detail: 'Indie Retro JRPG',
    image: earth,
    dev: 'Snowcastle Games',
    link: 'https://store.steampowered.com/app/761030/EARTHLOCK/',
  },
  {
    name: 'Eyes in the Dark',
    detail: 'Unique Stylish Rogue-lite',
    image: eyes,
    dev: 'Gearbox Publishing',
    link: 'https://store.steampowered.com/app/1039360/Eyes_in_the_Dark/',
  },
  {
    name: 'Feed All Monsters',
    detail: 'Feed All Monsters',
    image: feed,
    dev: 'DU&I',
    link: 'https://store.steampowered.com/app/1597720/Feed_All_Monsters/',
  },
  {
    name: 'Final Profit: A Shop RPG',
    detail: 'Item Shop Tycoon',
    image: profit,
    dev: 'Brent Arnold',
    link: 'https://store.steampowered.com/app/1705140/Final_Profit_A_Shop_RPG/',
  },
  {
    name: 'Fly Punch Boom!',
    detail: 'Insane Anime Fight',
    image: fly,
    dev: 'Jollypunch Games',
    link: 'https://store.steampowered.com/app/1051960/Fly_Punch_Boom/',
  },
  {
    name: 'Godfall Ultimate Edition',
    detail: 'Godly Hack-and-Slash',
    image: godfall,
    dev: 'Gearbox Publishing',
    link: 'https://store.steampowered.com/app/928960/Godfall_Ultimate_Edition/',
  },
  {
    name: 'Golfie',
    detail: 'Hole-in-one Yourself',
    image: golfie,
    dev: 'Yogscast Games',
    link: 'https://store.steampowered.com/app/1579020/Golfie/',
  },
  {
    name: 'Growbot',
    detail: 'Ridiculously Adorable Puzzles',
    image: growbot,
    dev: 'Application Systems Heidelberg',
    link: 'https://store.steampowered.com/app/661680/Growbot/',
  },
  {
    name: "Harmony's Odyssey",
    detail: 'Solve Cute Dioramas',
    image: harmony,
    dev: 'Neverland Entertainment',
    link: 'https://store.steampowered.com/app/1531490/Harmonys_Odyssey/',
  },
  {
    name: 'Heavy Burden',
    detail: 'Atmospheric Mindful Puzzles',
    image: heavy,
    dev: 'Whale Rock Games',
    link: 'https://store.steampowered.com/app/2070000/Heavy_Burden/',
  },
  {
    name: 'Homeworld Remastered Collection',
    detail: 'Legendary Space Strategy',
    image: homeworld,
    dev: 'Gearbox Publishing',
    link: 'https://store.steampowered.com/app/244160/Homeworld_Remastered_Collection/',
  },
  {
    name: 'Homeworld: Deserts of Kharak',
    detail: 'Classic Sci-fi RTS',
    image: deserts,
    dev: 'Gearbox Publishing',
    link: 'https://store.steampowered.com/app/281610/Homeworld_Deserts_of_Kharak/',
  },
  {
    name: 'Ikonei Island',
    detail: 'Cozy Island Together',
    image: ikonie,
    dev: 'Snowcastle Games',
    link: 'https://store.steampowered.com/app/1550730/Ikonei_Island_An_Earthlock_Adventure/',
  },
  {
    name: 'Imp of the Sun',
    detail: 'Charming Incan Metroidvania',
    image: imp,
    dev: 'Fireshine Games',
    link: 'https://store.steampowered.com/app/1535780/Imp_of_the_Sun/',
  },
  {
    name: 'LOUD: My Road to Fame',
    detail: 'Roaring Rhythm Rocker',
    image: loud,
    dev: 'Hyperstrange',
    link: 'https://store.steampowered.com/app/1838290/LOUD_My_Road_to_Fame/',
  },
  {
    name: 'Mars Horizon',
    detail: 'Space Programme Simulator',
    image: mars,
    dev: 'The Irregular Corporation',
    link: 'https://store.steampowered.com/app/765810/Mars_Horizon/',
  },
  {
    name: 'MetaPhysical',
    detail: 'Supernatural Ghost Hunt',
    image: meta,
    dev: 'Paper Promises Programming',
    link: 'https://store.steampowered.com/app/1568620/MetaPhysical/',
  },
  {
    name: 'Paradise Marsh',
    detail: 'Brilliant Bug Catching',
    image: paradise,
    dev: 'LazyEti',
    link: 'https://store.steampowered.com/app/1709170/Paradise_Marsh/',
  },
  {
    name: 'PlateUp! Cosmetic DLC',
    detail: 'Look Good While Cooking',
    image: plateup,
    dev: 'Yogscast Games',
    link: 'https://store.steampowered.com/app/1599600/PlateUp/',
  },
  {
    name: 'Projections',
    detail: 'Geometry Hack-and-Slash',
    image: projections,
    dev: 'Wolfgun',
    link: 'https://store.steampowered.com/app/1348800/PROJECTIONS/',
  },
  {
    name: 'Repella Fella',
    detail: 'Dark Comedy Story',
    image: repella,
    dev: '2 Left Thumbs',
    link: 'https://store.steampowered.com/app/1008860/Repella_Fella/',
  },
  {
    name: 'Run Prop, Run! Complete',
    detail: 'Bouncy Prop Hunt',
    image: run,
    dev: 'PlayTogether Studio',
    link: 'https://store.steampowered.com/app/1475400/Run_Prop_Run/',
  },
  {
    name: 'Rusty Lake: Hotel',
    detail: 'Creepy Cool Puzzler',
    image: rusty,
    dev: 'Rusty Lake Games',
    link: 'https://store.steampowered.com/app/435120/Rusty_Lake_Hotel/',
  },
  {
    name: 'Sephonie',
    detail: 'Inspired Classic Platformer',
    image: sephonie,
    dev: 'Melos Han-Tani, Marina Kittaka / Analgesic Productions',
    link: 'https://store.steampowered.com/app/1248840/Sephonie/',
  },
  {
    name: 'Signs of the Sojourner',
    detail: 'Creative Deckbuilding Journey',
    image: signs,
    dev: 'Echodog Games',
    link: 'https://store.steampowered.com/app/1058690/Signs_of_the_Sojourner/',
  },
  {
    name: 'Silicon Zeroes',
    detail: 'Indie Programming Puzzles',
    image: silicon,
    dev: 'PleasingFungus Games',
    link: 'https://store.steampowered.com/app/684270/Silicon_Zeroes/',
  },
  {
    name: 'SILT',
    detail: 'Moody Atmopheric Puzzles',
    image: silt,
    dev: 'Fireshine Games',
    link: 'https://store.steampowered.com/app/1325890/SILT/',
  },
  {
    name: 'Slap City',
    detail: 'Seriously Fun Platforming',
    image: slap,
    dev: 'Ludosity',
    link: 'https://store.steampowered.com/app/725480/Slap_City/',
  },
  {
    name: 'Slice of Sea',
    detail: 'Peaceful Hand-drawn Puzzles',
    image: slice,
    dev: 'Mateusz Skutnik',
    link: 'https://store.steampowered.com/app/750290/Slice_of_Sea/',
  },
  {
    name: 'Space Gladiators',
    detail: 'Be a Space Potato',
    image: space,
    dev: 'Blobfish',
    link: 'https://store.steampowered.com/app/1144910/Space_Gladiators/',
  },
  {
    name: 'Spirit of the Island',
    detail: 'Co-op Farm with Friends',
    image: spiritIsland,
    dev: 'META Publishing',
    link: 'https://store.steampowered.com/app/1592110/Spirit_of_the_Island/',
  },
  {
    name: 'START AGAIN',
    detail: 'Time-looping Brilliance',
    image: start,
    dev: 'insertdisc5',
    link: 'https://store.steampowered.com/app/1691420/START_AGAIN_a_prologue/',
  },
  {
    name: 'Super Cable Boy',
    detail: 'Stylish Retro Platformer',
    image: cable,
    dev: 'Sørb',
    link: 'https://store.steampowered.com/app/1304420/Super_Cable_Boy/',
  },
  {
    name: 'The Red Lantern',
    detail: 'Dog Sledding Adventures',
    image: lantern,
    dev: 'Kepler Interactive',
    link: 'https://store.steampowered.com/app/1053710/The_Red_Lantern/',
  },
  {
    name: 'Tiny Life',
    detail: 'The Sims with Pixels',
    image: tinylife,
    dev: 'Top Hat Studios Inc',
    link: 'https://store.steampowered.com/app/1651490/Tiny_Life/',
  },
  {
    name: 'Tinytopia',
    detail: 'Build Small Towns',
    image: tinytopia,
    dev: 'Mastiff',
    link: 'https://store.steampowered.com/app/968550/Tinytopia/',
  },
  {
    name: 'Train Valley 2',
    detail: 'Trains Trains Trains',
    image: train,
    dev: 'META Publishing',
    link: 'https://store.steampowered.com/app/602320/Train_Valley_2/',
  },
  {
    name: 'Trifox',
    detail: 'Stylish Colourful 3D Platformer',
    image: trifox,
    dev: 'Big Sugar Games',
    link: 'https://store.steampowered.com/app/1211240/Trifox/',
  },
  {
    name: 'Tunguska: The Visitation',
    detail: 'Stealth Action RPG',
    image: tunguska,
    dev: 'Rotorist Workshop',
    link: 'https://store.steampowered.com/app/1601970/Tunguska_The_Visitation/',
  },
  {
    name: 'Turbo Golf Racing',
    detail: 'Racing... with Golf?!',
    image: turbo,
    dev: 'Secret Mode',
    link: 'https://store.steampowered.com/app/1324350/Turbo_Golf_Racing/',
  },
  {
    name: 'Valthirian Arc',
    detail: 'Hero School RPG',
    image: valthirian,
    dev: 'PQube',
    link: 'https://store.steampowered.com/app/785850/Valthirian_Arc_Hero_School_Story/',
  },
  {
    name: 'Vermillion VR',
    detail: 'Painting (In VR)',
    image: vermillion,
    dev: 'Thomas van den Berge',
    link: 'https://store.steampowered.com/app/1608400/Vermillion__VR_Painting/',
  },
  {
    name: 'We Are The Dwarves',
    detail: 'Tactical Dwarf Adventure',
    image: war,
    dev: 'Whale Rock Games',
    link: 'https://store.steampowered.com/app/395570/We_Are_The_Dwarves/',
  },
  {
    name: 'Whispers in the West',
    detail: 'Cowboy Murder Mystery',
    image: whispers,
    dev: 'Infinite Whys',
    link: 'https://store.steampowered.com/app/1868070/Whispers_in_the_West__Coop_Murder_Mystery/',
  },
  {
    name: 'Witchtastic',
    detail: 'Multiplayer Spooky Cooking',
    image: witch,
    dev: 'Application Systems Heidelberg',
    link: 'https://store.steampowered.com/app/1080010/Witchtastic/',
  },
  {
    name: 'Zapling Bygone',
    detail: 'Smart Slime Metroidvania',
    image: zapling,
    dev: '9FingerGames',
    link: 'https://store.steampowered.com/app/1489110/Zapling_Bygone/',
  },
  {
    name: 'Zeepkist',
    detail: 'Bouncy Downhill Driving',
    image: zeepkist,
    dev: 'Steelpan Interactive',
    link: 'https://store.steampowered.com/app/1440670/Zeepkist/',
  },
  {
    name: 'MCC Island (Minecraft Java Edition): Jingle Jam Rewards pack!',
    detail: 'Minecraft Minigame Pack',
    image: mcc,
    dev: 'Noxcrew',
    link: 'https://mcchampionship.com/',
  },
  {
    name: 'SIFU: Deluxe Cosmetic Pack',
    detail: 'SIFU: Deluxe Cosmetic Pack',
    image: sifu,
    dev: 'Sloclap',
    link: 'https://store.steampowered.com/app/2231950/Sifu_Deluxe_Cosmetic_Pack/',
  },
  {
    name: 'glitch busters',
    detail: 'Stuck on you',
    image: glitch,
    dev: 'Toylogic Inc.',
    link: 'https://store.steampowered.com/app/1616110/Glitch_Busters_Stuck_On_You/',
  },
  {
    name: 'Fanatical codes',
    detail: 'Spin the wheel',
    image: fanatical,
    dev: '',
    link: 'https://www.fanatical.com/en/redeem-code',
  },
  {
    name: 'Displate 40% off discount',
    detail: '40% off at displate (redeemable on the displate website)',
    image: displate,
    dev: '',
    link: 'https://displate.com/',
  },
]
