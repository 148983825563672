import { useTheme } from 'styled-components'

import { useFillColor, useSize } from './IconHooks'

export const Fullscreen = ({
  fill,
  fillType = 'black.50',
  size = 'small',
  height,
  width,
  title = 'Arrow left',
}: TiltifyIcon) => {
  const theme = useTheme()
  const sizeValues = useSize(size, height, width)
  const fillValue = useFillColor(theme, fillType, fill)

  return (
    <svg
      width={sizeValues.width}
      height={sizeValues.height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>{title}</title>
      <path
        d='M13.5282 10.8618C13.6532 10.7368 13.8228 10.6666 13.9996 10.6666C14.1764 10.6666 14.346 10.7368 14.471 10.8618C14.596 10.9869 14.6662 11.1564 14.6662 11.3333V13.9999C14.6652 14.087 14.6471 14.1731 14.6129 14.2533C14.5453 14.4162 14.4158 14.5456 14.2529 14.6132C14.1728 14.6474 14.0867 14.6655 13.9996 14.6666H11.3329C11.1561 14.6666 10.9865 14.5963 10.8615 14.4713C10.7365 14.3463 10.6662 14.1767 10.6662 13.9999C10.6662 13.8231 10.7365 13.6535 10.8615 13.5285C10.9865 13.4035 11.1561 13.3333 11.3329 13.3333H12.3929L8.85958 9.80658C8.73404 9.68105 8.66352 9.51079 8.66352 9.33325C8.66352 9.15572 8.73404 8.98545 8.85958 8.85992C8.92174 8.79776 8.99553 8.74845 9.07675 8.71481C9.15796 8.68117 9.24501 8.66386 9.33291 8.66386C9.42082 8.66386 9.50786 8.68117 9.58908 8.71481C9.67029 8.74845 9.74409 8.79776 9.80624 8.85992L13.3329 12.3933V11.3333C13.3329 11.1564 13.4032 10.9869 13.5282 10.8618Z'
        fill={fillValue}
      />
      <path
        d='M2.47118 5.13799C2.34615 5.26301 2.17658 5.33325 1.99977 5.33325C1.82296 5.33325 1.65339 5.26301 1.52837 5.13799C1.40334 5.01296 1.3331 4.8434 1.3331 4.66658V1.99992C1.33416 1.9128 1.35228 1.82673 1.38644 1.74659C1.45409 1.58369 1.58354 1.45424 1.74644 1.38659C1.82659 1.35242 1.91265 1.33431 1.99977 1.33325H4.66644C4.84325 1.33325 5.01282 1.40349 5.13784 1.52851C5.26287 1.65354 5.3331 1.82311 5.3331 1.99992C5.3331 2.17673 5.26287 2.3463 5.13784 2.47132C5.01282 2.59635 4.84325 2.66658 4.66644 2.66658H3.60644L7.13977 6.19325C7.26531 6.31879 7.33583 6.48905 7.33583 6.66658C7.33583 6.84412 7.26531 7.01438 7.13977 7.13992C7.07761 7.20208 7.00382 7.25138 6.9226 7.28502C6.84139 7.31866 6.75434 7.33598 6.66644 7.33598C6.57853 7.33598 6.49149 7.31866 6.41027 7.28502C6.32906 7.25138 6.25526 7.20208 6.1931 7.13992L2.66644 3.60658V4.66658C2.66644 4.8434 2.5962 5.01296 2.47118 5.13799Z'
        fill={fillValue}
      />
      <path
        d='M10.8616 2.47142C10.7366 2.34639 10.6663 2.17683 10.6663 2.00002C10.6663 1.8232 10.7366 1.65363 10.8616 1.52861C10.9866 1.40359 11.1562 1.33335 11.333 1.33335H13.9997C14.0868 1.3344 14.1729 1.35252 14.253 1.38668C14.4159 1.45433 14.5454 1.58378 14.613 1.74668C14.6472 1.82683 14.6653 1.9129 14.6663 2.00002V4.66668C14.6663 4.84349 14.5961 5.01306 14.4711 5.13808C14.3461 5.26311 14.1765 5.33335 13.9997 5.33335C13.8229 5.33335 13.6533 5.26311 13.5283 5.13808C13.4032 5.01306 13.333 4.84349 13.333 4.66668V3.60668L9.80634 7.14001C9.68081 7.26555 9.51054 7.33607 9.33301 7.33607C9.15547 7.33607 8.98521 7.26555 8.85967 7.14001C8.79751 7.07785 8.74821 7.00406 8.71457 6.92285C8.68093 6.84163 8.66361 6.75459 8.66361 6.66668C8.66361 6.57877 8.68093 6.49173 8.71457 6.41051C8.74821 6.3293 8.79751 6.25551 8.85967 6.19335L12.393 2.66668H11.333C11.1562 2.66668 10.9866 2.59644 10.8616 2.47142Z'
        fill={fillValue}
      />
      <path
        d='M5.13774 13.5284C5.26277 13.6534 5.33301 13.823 5.33301 13.9998C5.33301 14.1766 5.26277 14.3462 5.13774 14.4712C5.01272 14.5963 4.84315 14.6665 4.66634 14.6665H1.99967C1.91256 14.6654 1.82649 14.6473 1.74634 14.6132C1.58344 14.5455 1.45399 14.4161 1.38634 14.2532C1.35218 14.173 1.33406 14.0869 1.33301 13.9998V11.3332C1.33301 11.1563 1.40325 10.9868 1.52827 10.8618C1.65329 10.7367 1.82286 10.6665 1.99967 10.6665C2.17649 10.6665 2.34605 10.7367 2.47108 10.8618C2.5961 10.9868 2.66634 11.1563 2.66634 11.3332V12.3932L6.19301 8.85982C6.31854 8.73429 6.48881 8.66376 6.66634 8.66376C6.84387 8.66376 7.01414 8.73429 7.13967 8.85982C7.20183 8.92198 7.25114 8.99577 7.28478 9.07699C7.31842 9.1582 7.33573 9.24525 7.33573 9.33315C7.33573 9.42106 7.31842 9.50811 7.28478 9.58932C7.25114 9.67054 7.20183 9.74433 7.13967 9.80649L3.60634 13.3332H4.66634C4.84315 13.3332 5.01272 13.4034 5.13774 13.5284Z'
        fill={fillValue}
      />
    </svg>
  )
}
