export function Hamburger({ ariaHidden, stroke, fill, focusable, height, width, ...rest }: any) {
  const h = height || '1rem'
  const w = width || '1rem'

  return (
    <svg
      viewBox='0 0 31 22'
      width={w}
      height={h}
      aria-hidden={ariaHidden}
      focusable={focusable}
      fill={fill}
      stroke={stroke}
      {...rest}
    >
      <path d='M1.5 1h28M1.5 11h28M1.5 21h28' strokeWidth='2' fill='none' strokeLinecap='round' />
    </svg>
  )
}

Hamburger.defaultProps = {
  ariaHidden: false,
  focusable: false,
  stroke: '#000',
  fill: '#000',
}
