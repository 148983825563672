import { forwardRef, useImperativeHandle, useRef } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { Button } from '@tiltify/ui/components/Button'
import { userMenuMessages } from '@tiltify/ui/translations'
import { baseTiltifyUrl, getSubdomainUrl } from '@tiltify/ui/utils/tiltifyUrl'

const StyledButton = styled(Button)`
  display: flex;
  font-size: 0.8125rem !important;
  font-weight: normal !important;
  padding: 0.625rem 1.0625rem;
  border: none;
  color: ${({ theme }) => theme.textColor} !important;
`

export const FrontendUserDashboard = forwardRef((props, ref) => {
  const { formatMessage } = useIntl()
  const localRef = useRef()
  useImperativeHandle(ref, () => localRef.current)
  const baseURL = getSubdomainUrl(baseTiltifyUrl(), 'app')
  return (
    <StyledButton forwardedAs='a' href={`${baseURL}/hub`} ref={localRef}>
      {formatMessage(userMenuMessages.dashboard)}
    </StyledButton>
  )
})

FrontendUserDashboard.displayName = 'FrontendUserDashboard'
