import { useTheme } from 'styled-components'

import { useFillColor, useSize } from './IconHooks'

export const Eye = ({
  fill,
  fillType = 'black.50',
  size = 'small',
  height,
  width,
  title = 'Eye',
}: TiltifyIcon) => {
  const theme = useTheme()
  const sizeValues = useSize(size, height, width)
  const fillValue = useFillColor(theme, fillType, fill)

  return (
    <svg
      width={sizeValues.width}
      height={sizeValues.height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>{title}</title>
      <path
        d='M14.6134 7.37481C13.2667 4.3931 10.7334 2.54304 8.00004 2.54304C5.26671 2.54304 2.73338 4.3931 1.38671 7.37481C1.35 7.45502 1.33105 7.54159 1.33105 7.62911C1.33105 7.71663 1.35 7.8032 1.38671 7.88341C2.73338 10.8651 5.26671 12.7152 8.00004 12.7152C10.7334 12.7152 13.2667 10.8651 14.6134 7.88341C14.6501 7.8032 14.669 7.71663 14.669 7.62911C14.669 7.54159 14.6501 7.45502 14.6134 7.37481ZM8.00004 11.4437C5.88671 11.4437 3.88671 9.98778 2.73338 7.62911C3.88671 5.27044 5.88671 3.81456 8.00004 3.81456C10.1134 3.81456 12.1134 5.27044 13.2667 7.62911C12.1134 9.98778 10.1134 11.4437 8.00004 11.4437ZM8.00004 5.08607C7.47263 5.08607 6.95706 5.23522 6.51852 5.51465C6.07999 5.79409 5.7382 6.19125 5.53637 6.65593C5.33453 7.12061 5.28172 7.63193 5.38462 8.12523C5.48751 8.61853 5.74149 9.07166 6.11443 9.42731C6.48737 9.78296 6.96252 10.0252 7.4798 10.1233C7.99709 10.2214 8.53326 10.171 9.02053 9.97857C9.5078 9.78609 9.92428 9.46015 10.2173 9.04195C10.5103 8.62375 10.6667 8.13208 10.6667 7.62911C10.6667 6.95466 10.3858 6.30782 9.88566 5.83091C9.38556 5.354 8.70729 5.08607 8.00004 5.08607ZM8.00004 8.90063C7.73634 8.90063 7.47855 8.82606 7.25928 8.68634C7.04002 8.54662 6.86912 8.34804 6.7682 8.1157C6.66729 7.88336 6.64088 7.6277 6.69233 7.38105C6.74378 7.1344 6.87077 6.90784 7.05724 6.73001C7.24371 6.55219 7.48128 6.43109 7.73992 6.38202C7.99857 6.33296 8.26665 6.35814 8.51029 6.45438C8.75392 6.55062 8.96216 6.71359 9.10867 6.92269C9.25518 7.13179 9.33338 7.37763 9.33338 7.62911C9.33338 7.96634 9.1929 8.28975 8.94285 8.52821C8.6928 8.76667 8.35367 8.90063 8.00004 8.90063Z'
        fill={fillValue}
      />
    </svg>
  )
}
