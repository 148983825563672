export const Twitch = ({ fill, height = 16, width = 16 }: TiltifyIcon) => {
  return fill ? (
    <svg
      width='16'
      height='16'
      viewBox='0 0 2400 2800'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>Twitch</title>
      <g>
        <polygon
          fill='none'
          points='2200,1300 1800,1700 1400,1700 1050,2050 1050,1700 600,1700 600,200 2200,200'
        />
        <g>
          <g id='Layer_1-2' fill={fill}>
            <path
              fill={fill}
              d='M500,0L0,500v1800h600v500l500-500h400l900-900V0H500z M2200,1300l-400,400h-400l-350,350v-350H600V200h1600V1300z'
            />
            <rect fill={fill} x='1700' y='550' width='200' height='600' />
            <rect fill={fill} x='1150' y='550' width='200' height='600' />
          </g>
        </g>
      </g>
    </svg>
  ) : (
    <svg
      fill={fill}
      width={width}
      height={height}
      viewBox='0 0 2400 2800'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>Twitch</title>
      <g>
        <polygon
          fill='#FFFFFF'
          points='2200,1300 1800,1700 1400,1700 1050,2050 1050,1700 600,1700 600,200 2200,200'
        />
        <g>
          <g id='Layer_1-2' fill='#9146FF'>
            <path
              fill='#9146FF'
              d='M500,0L0,500v1800h600v500l500-500h400l900-900V0H500z M2200,1300l-400,400h-400l-350,350v-350H600V200h1600V1300z'
            />
            <rect fill='#9146FF' x='1700' y='550' width='200' height='600' />
            <rect fill='#9146FF' x='1150' y='550' width='200' height='600' />
          </g>
        </g>
      </g>
    </svg>
  )
}
