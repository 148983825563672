export const LinkedIn = ({
  ariaHidden,
  stroke,
  fill,
  focusable,
  height,
  title,
  width,
  isDark,
  ...rest
}: any) => {
  const h = height || '1rem'
  const w = width || '1rem'

  return (
    <svg
      width={w}
      height={h}
      viewBox='0 0 25 25'
      aria-hidden={ariaHidden}
      focusable={focusable}
      fill={isDark ? '#fff' : fill}
      stroke={stroke}
      {...rest}
    >
      {title ? <title>{title}</title> : null}
      <path
        d='M20.9008658,20.616342 L17.3156926,20.616342 L17.3156926,15.0018398 C17.3156926,13.662987 17.2917749,11.9395022 15.4510823,11.9395022 C13.5837662,11.9395022 13.2980519,13.3981602 13.2980519,14.904329 L13.2980519,20.6159091 L9.71298701,20.6159091 L9.71298701,9.0702381 L13.1546537,9.0702381 L13.1546537,10.6480519 L13.2029221,10.6480519 C13.9036797,9.44989177 15.2114719,8.73160173 16.5987013,8.78311688 C20.2323593,8.78311688 20.902381,11.1731602 20.902381,14.2825758 L20.9008658,20.616342 Z M5.66774892,7.49199134 C5.66753247,7.49199134 5.66742424,7.49199134 5.66731602,7.49199134 C4.52597403,7.49199134 3.58679654,6.55292208 3.58679654,5.41158009 C3.58679654,4.2702381 4.52597403,3.33106061 5.66731602,3.33106061 C6.80854978,3.33106061 7.74761905,4.27002165 7.7478355,5.41114719 C7.7478355,5.41125541 7.7478355,5.41136364 7.7478355,5.41158009 C7.7478355,6.55270563 6.80887446,7.49188312 5.66774892,7.49199134 M7.46028139,20.616342 L3.87142857,20.616342 L3.87142857,9.0702381 L7.46028139,9.0702381 L7.46028139,20.616342 Z M22.6880952,0.00173160173 L2.06980519,0.00173160173 C1.10194805,-0.0091991342 0.296212121,0.777813853 0.284415584,1.745671 L0.284415584,22.4495671 C0.295779221,23.4179654 1.10140693,24.2058442 2.06980519,24.195595 L22.6880952,24.195595 C23.6588745,24.207684 24.467316,23.4199134 24.480303,22.4495671 L24.480303,1.74426407 C24.467316,0.774350649 23.6577922,-0.0125541126 22.6880952,6.31784202e-05'
        fillRule='nonzero'
        stroke='none'
        strokeWidth='1'
      />
    </svg>
  )
}

LinkedIn.defaultProps = {
  ariaHidden: false,
  focusable: false,
  stroke: '#000',
  fill: '#0A66C2',
}
