import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client'
import { useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { NotificationContextProvider, ThemeToggleContextProvider } from '@tiltify/shared/contexts'
import { AuthenticationProvider } from '@tiltify/shared/contexts/AuthenticationContext'
import { FullWindowLoading, Helmet } from '@tiltify/ui'
import { IntlContextProvider } from '@tiltify/ui/contexts/IntlContext'
import { ToggleThemeProvider } from '@tiltify/ui/contexts/ThemeContext'
import { useGA } from '@tiltify/ui/hooks/useGA'
import { setupPublicClient } from '@tiltify/ui/utils'

import App from './App'
import { GlobalStyle } from './GlobalStyles'
import { RouteContextProvider } from './contexts/RouteContext'
import theme from './theme'

const container = document.getElementById('root')
const root = createRoot(container!)

const Index = (): JSX.Element => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>()
  const { initGA, pageView } = useGA()

  useEffect(() => {
    initGA(process.env.REACT_APP_TILTIFY_GA)
    pageView()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setupPublicClient()
      .then((res: ApolloClient<NormalizedCacheObject>) => setClient(res))
      .catch(() => {})
  }, [])

  if (!client) return <FullWindowLoading />

  return (
    <ApolloProvider client={client}>
      <RouteContextProvider>
        <BrowserRouter>
          <NotificationContextProvider>
            <AuthenticationProvider>
              <App />
            </AuthenticationProvider>
          </NotificationContextProvider>
        </BrowserRouter>
      </RouteContextProvider>
    </ApolloProvider>
  )
}

root.render(
  <>
    <Helmet description='Jingle Jam is fundraising on Tiltify' title='Jingle Jam' />
    <ToggleThemeProvider>
      <ThemeToggleContextProvider>
        {/* @ts-expect-error ignore */}
        <ThemeProvider theme={theme}>
          <IntlContextProvider>
            <Index />
          </IntlContextProvider>
        </ThemeProvider>
      </ThemeToggleContextProvider>
    </ToggleThemeProvider>
    <GlobalStyle />
  </>
)
