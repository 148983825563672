import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { rem } from 'polished'

import { TextInput } from '@tiltify/design/components/Forms/TextInput'
import { Button } from '@tiltify/design/components/Button'
import { ImageUploader } from '@tiltify/design/components/Forms/ImageUploader'
import { inputMessages } from '@tiltify/translations'

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(18)};
  align-items: flex-start;
  margin-bottom: 2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    align-items: center;
  }
`

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  width: 100%;
  max-width: 360px;

  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    justify-content: center;
  }
`

export const Inputs = ({ loading }: { loading: boolean }) => {
  const { formatMessage } = useIntl()

  return (
    <InputWrapper>
      <TextInput
        required
        name='username'
        label={formatMessage(inputMessages.username)}
        minLength={3}
        maxLength={56}
        fixedWidth={false}
      />
      <TextInput
        required
        name='firstName'
        label={formatMessage(inputMessages.firstName)}
        minLength={2}
        maxLength={56}
        fixedWidth={false}
      />
      <TextInput
        required
        name='lastName'
        label={formatMessage(inputMessages.lastName)}
        minLength={2}
        maxLength={56}
        fixedWidth={false}
      />
      <TextInput
        required
        name='bio'
        label={formatMessage(inputMessages.bio)}
        forwardedAs='textarea'
        fixedWidth={false}
      />
      <ImageUploader
        height={400}
        width={400}
        name='avatar'
        title={formatMessage(inputMessages.avatar)}
        helperText={formatMessage(inputMessages.suggestedAvatar)}
      />
      <StyledButtonWrapper>
        <Button
          type='submit'
          icon='arrow-right'
          text='Create a fundraiser account'
          disabled={loading}
        />
      </StyledButtonWrapper>
    </InputWrapper>
  )
}
