import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { Grid } from '@tiltify/design/layout'
import { CampaignCard } from '../CampaignCard'

import { AccentBar, Heading, MaxWidthWrapper } from 'components/styles'
import { useRouteContext } from 'contexts/RouteContext/index'

const StyledFundraisingEventCampaignSlider = styled(MaxWidthWrapper)`
  text-align: center;
  margin-bottom: 4rem;
`

const ViewAllButton = styled(Link)`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 1px;
  color: #e21350;
  border: 2px solid #e21350;
  border-radius: 100px;
  text-decoration: none;
  padding: 1rem;
`

interface ICampaignConnection {
  node: TiltifyCampaign
}

export const CampaignSliderContainer = (): JSX.Element | null => {
  const { fundraisingEvent } = useRouteContext()
  const [campaigns, setCampaigns] = useState<{ data: TiltifyCampaign[] }>()

  const setData = (edges: ICampaignConnection[]) => {
    setCampaigns({
      data: edges.map((edge: ICampaignConnection) => edge.node),
    })
  }

  useEffect(() => {
    if (fundraisingEvent?.publishedCampaigns?.edges) {
      const { edges } = fundraisingEvent.publishedCampaigns
      setData(edges)
    }
    // eslint-disable-next-line
  }, [fundraisingEvent])

  if (!campaigns || !campaigns?.data) return null
  if (campaigns.data.length === 0) return null

  return (
    <StyledFundraisingEventCampaignSlider>
      <Heading style={{ marginBottom: '2rem', textAlign: 'center' }}>
        Jingle Jam Fundraisers
      </Heading>
      <AccentBar />
      <div style={{ marginBottom: '3rem' }}>
        <Grid itemWidth={264}>
          {campaigns.data.slice(0, 12).map((campaign: TiltifyCampaign, index: number) => (
            <CampaignCard key={`campaign-${index}`} campaign={campaign} />
          ))}
        </Grid>
      </div>
      <ViewAllButton to='campaigns'>VIEW ALL FUNDRAISERS</ViewAllButton>
    </StyledFundraisingEventCampaignSlider>
  )
}
