export const Dark = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16 8C16 3.59071 12.4183 0 8 0C3.59071 0 0 3.58172 0 8C0 12.4093 3.58172 16 8 16C12.4093 16 16 12.4183 16 8ZM8 14.8571C4.21621 14.8571 1.14286 11.7814 1.14286 8C1.14286 4.21621 4.21859 1.14286 8 1.14286C11.7838 1.14286 14.8571 4.21859 14.8571 8C14.8571 11.7838 11.7814 14.8571 8 14.8571Z'
        fill='#1F2124'
      />
      <path
        d='M4.87713 8.56644L4.85719 9.70913C4.85443 9.8669 4.9801 9.99703 5.13787 9.99978C5.29564 10.0025 5.42578 9.87687 5.42853 9.7191L5.44848 8.57641C5.45123 8.41864 5.32556 8.28851 5.16779 8.28576C5.01002 8.283 4.87989 8.40867 4.87713 8.56644Z'
        fill='#1F2124'
      />
      <path
        d='M4.56662 9.42853L5.7093 9.44847C5.86707 9.45123 5.9972 9.32556 5.99996 9.16779C6.00271 9.01002 5.87704 8.87989 5.71927 8.87713L4.57659 8.85719C4.41882 8.85443 4.28869 8.9801 4.28593 9.13787C4.28318 9.29564 4.40885 9.42577 4.56662 9.42853Z'
        fill='#1F2124'
      />
      <path
        d='M7.16302 3.42359L7.14308 4.56627C7.14032 4.72404 7.26599 4.85417 7.42376 4.85693C7.58153 4.85968 7.71166 4.73401 7.71442 4.57624L7.73436 3.43356C7.73712 3.27579 7.61145 3.14565 7.45368 3.1429C7.29591 3.14015 7.16578 3.26581 7.16302 3.42359Z'
        fill='#1F2124'
      />
      <path
        d='M6.85226 4.28567L7.99495 4.30562C8.15272 4.30837 8.28285 4.1827 8.2856 4.02493C8.28836 3.86716 8.16269 3.73703 8.00492 3.73428L6.86223 3.71433C6.70446 3.71158 6.57433 3.83724 6.57158 3.99501C6.56882 4.15279 6.69449 4.28292 6.85226 4.28567Z'
        fill='#1F2124'
      />
      <path
        d='M3.89227 5.39061L4.68616 6.21271C4.79578 6.32622 4.97665 6.32937 5.09016 6.21976C5.20367 6.11015 5.20683 5.92927 5.09721 5.81576L4.30332 4.99366C4.1937 4.88015 4.01283 4.87699 3.89932 4.98661C3.78581 5.09622 3.78265 5.2771 3.89227 5.39061Z'
        fill='#1F2124'
      />
      <path
        d='M4.28216 6.21988L5.10427 5.42599C5.21777 5.31637 5.22093 5.1355 5.11132 5.02199C5.0017 4.90848 4.82083 4.90532 4.70732 5.01494L3.88522 5.80883C3.77171 5.91845 3.76855 6.09932 3.87816 6.21283C3.98778 6.32634 4.16865 6.3295 4.28216 6.21988Z'
        fill='#1F2124'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.10856 9.1791C7.34615 9.476 7.642 9.73448 7.99117 9.93608C9.49438 10.804 11.4165 10.2889 12.2844 8.78571C13.1523 7.28251 12.6372 5.36037 11.134 4.49249C10.7849 4.2909 10.4131 4.16392 10.0372 4.10661C10.8224 5.08788 10.9712 6.48882 10.3049 7.64286C9.63863 8.79689 8.35098 9.36851 7.10856 9.1791Z'
        fill='#1F2124'
      />
      <path
        d='M3.42861 14.2857H12.5715C12.8871 14.2857 13.1429 14.0299 13.1429 13.7143C13.1429 13.3987 12.8871 13.1429 12.5715 13.1429H3.42861C3.11302 13.1429 2.85718 13.3987 2.85718 13.7143C2.85718 14.0299 3.11302 14.2857 3.42861 14.2857Z'
        fill='#1F2124'
      />
    </svg>
  )
}
