import styled, { css } from 'styled-components'

const Wrapper = styled.div<{ $personalStyle: boolean }>`
  background: ${({ $personalStyle }) =>
    $personalStyle ? 'transparent' : css`linear-gradient(135deg, #e9190f 0%, #ff0f80 100%)`};
  border: 2px solid
    ${({ $personalStyle, theme }) =>
      $personalStyle ? theme.colors.black.core : theme.colors.white.core};
  border-radius: 5px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.05em;
  padding: 3px;
  color: ${({ $personalStyle, theme }) =>
    $personalStyle ? theme.colors.black.core : theme.colors.white.core};
`
export const Multiplier = ({
  count,
  personalStyle = false,
}: {
  count: number
  personalStyle?: boolean
}) => {
  return <Wrapper $personalStyle={personalStyle}>{count}x</Wrapper>
}
