import { useEffect } from 'react'
import ReactDOM from 'react-dom'

/**
 * A wrapper around react-portal to allow rendering of Portals while the child
 * component is mounted. This handles the opening of closing of the portal
 * directly with the React lifecycle. Portals created using this wrapper are
 * always considered opened.
 */

export const Portal = ({
  scrollLock,
  children,
  id = 'modal-root',
}: {
  scrollLock?: boolean
  children: JSX.Element
  id?: string
}) => {
  const portalRoot = document.getElementById(id)

  useEffect(() => {
    if (scrollLock) document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [scrollLock])

  if (!portalRoot) return null

  return ReactDOM.createPortal(children, portalRoot)
}
