import styled from 'styled-components'
import { rem } from 'polished'

const Wrapper = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 264px;
  height: 123px;
  position: relative;
  border-radius: 6px;

  img {
    border-radius: 6px;
  }

  @media (max-width: 500px) {
    max-width: 100%;
  }

  &&:hover .overlay {
    opacity: 1;
  }
`

const Image = styled.div<{ src: string }>`
  border-radius: 6px;
  background-image: url('${({ src }) => src}');
  background-size: cover;
  background-position: center;
  height: 123px;
  width: 264px;
`

const Overlay = styled.div`
  transition: opacity 0.5s;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.pink};
  opacity: 0;
  cursor: pointer;
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
`
const Name = styled.p`
  font-size: ${rem(18)};
  line-height: 24px;
  font-weight: bold;
  margin: 0;
`
const Detail = styled.p`
  font-size: 1rem;
  line-height: 22px;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 11px;
  word-break: break-word;
`
const Dev = styled.p`
  font-size: 1rem;
  line-height: 18px;
  font-weight: bold;
  margin: 0;
`

export const SingleGame = ({
  image,
  name,
  detail,
  dev,
  link,
}: {
  image: string
  name: string
  detail: string
  dev: string
  link: string
}): JSX.Element => {
  return (
    <Wrapper href={link} target='_blank' rel='noopener noreferrer'>
      <Image src={image} />
      <Overlay className='overlay'>
        <Name>{name}</Name>
        <Detail>{detail}</Detail>
        <Dev>{dev}</Dev>
      </Overlay>
    </Wrapper>
  )
}
