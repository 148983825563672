import styled, { css } from 'styled-components'

import { Paragraph } from '../Paragraph'
import { Success } from '../../icons/Success'
import { Close } from '../../icons/Close'
import { Information } from '../../icons/Information'
import { Warning } from '../../icons/Warning'

const successStyles = css`
  background: ${({ theme }) => theme.colors.green[50]};
  border-color: ${({ theme }) => theme.colors.green.core};
`

const errorStyles = css`
  background: ${({ theme }) => theme.colors.error[50]};
  border-color: ${({ theme }) => theme.colors.error.core};
`

const normalStyles = css`
  background: ${({ theme }) => theme.colors.blue[50]};
  border-color: ${({ theme }) => theme.colors.blue.core};
`

const NotificationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
`

const NotificationInner = styled.div<{ type: string }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 4px;
  width: 323px;
  border: 1px solid;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;

  ${({ type }) => {
    switch (type) {
      case 'success':
        return successStyles
      case 'error':
        return errorStyles
      default:
        return normalStyles
    }
  }}
`

const NotificationLeft = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px;
  gap: 10px;

  width: 32px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`

const NotificationContent = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 235px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`

const NotificationRight = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  gap: 10px;

  width: 32px;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
`

const NotificationTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 8px;
  gap: 10px;
  flex: none;
  order: 0;
  flex-grow: 0;
`

const StyledTitle = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.black[800]};
`

const StyledMessage = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.black[600]};
`

const NotificationText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  gap: 10px;
  width: 190px;
  flex: none;
  order: 1;
  flex-grow: 0;
`

const CloseButton = styled.button`
  display: flex;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`

interface INotification {
  message?: string
  setShow?: (value: boolean) => void
  title: string
  type: 'success' | 'error' | 'info'
}

const getIcon = (type: string) => {
  switch (type) {
    case 'success':
      return <Success size='large' />
    case 'error':
      return <Warning size='large' />
    default:
      return <Information size='large' fillType='blue.core' />
  }
}

export const Notification = ({ type, title, message, setShow }: INotification): JSX.Element => {
  return (
    <NotificationWrapper>
      <NotificationInner type={type}>
        <NotificationLeft>{getIcon(type)}</NotificationLeft>
        <NotificationContent>
          <NotificationTitle>
            <StyledTitle size='small'>{title}</StyledTitle>
          </NotificationTitle>
          {message ? (
            <NotificationText>
              <StyledMessage size='small'>{message}</StyledMessage>
            </NotificationText>
          ) : null}
        </NotificationContent>
        <>
          {setShow && (
            <NotificationRight>
              <CloseButton onClick={() => setShow(false)}>
                <Close fillType='black.400' />
              </CloseButton>
            </NotificationRight>
          )}
        </>
      </NotificationInner>
    </NotificationWrapper>
  )
}
