import { useState, Fragment } from 'react'
import styled from 'styled-components'

import { useOffsetPagination } from '@tiltify/ui/hooks/useOffsetPagination'
import { PageLoading, PaginatedControls } from '@tiltify/ui'
import { Grid } from '@tiltify/design/layout'
import { CampaignCard } from '../CampaignCard'
import { MaxWidthWrapper } from 'components/styles'
import { get_campaigns_by_fundraising_event_id } from 'gql/fundraisingEvent'

const StyledLoading = styled.div`
  height: 100vh;
`

const ControlWrapper = styled.div`
  margin-top: 1rem;
`

const StyledPaginatedControls = styled(PaginatedControls)`
  button {
    border-color: ${({ theme }) => theme.brown};

    &:disabled {
      opacity: 0.4;
    }
  }

  button svg,
  button svg g {
    stroke: ${({ theme }) => theme.brown};
  }
`

export const CampaignGrid = ({
  fundraisingEvent,
  searchValue,
}: {
  fundraisingEvent: TiltifyPublicFundraisingEvent
  searchValue: string | null
}): JSX.Element | null => {
  const [staticVariables] = useState({
    limit: 16,
    regionId: searchValue ? Number(searchValue) : null,
    publicId: fundraisingEvent?.publicId,
  })

  const { data, loading, canGoNext, canGoPrev, goToFirstPage, goToNext, goToPrev } =
    useOffsetPagination(
      get_campaigns_by_fundraising_event_id,
      null,
      'fundraisingEvent.publishedCampaigns',
      16,
      staticVariables
    )

  if (loading) {
    return (
      <StyledLoading>
        <PageLoading />
      </StyledLoading>
    )
  }

  if (!data?.fundraisingEvent?.publishedCampaigns?.edges) return null

  return (
    <MaxWidthWrapper>
      <Grid itemWidth={264}>
        {data?.fundraisingEvent?.publishedCampaigns?.edges?.map(
          (edge: { node: TiltifyCampaign }) => (
            <Fragment key={edge.node.publicId}>
              <CampaignCard key={`campaign-${edge.node.publicId}`} campaign={edge.node} />
            </Fragment>
          )
        )}
      </Grid>
      <ControlWrapper>
        {(canGoNext || canGoPrev) && (
          <StyledPaginatedControls
            firstPage={goToFirstPage}
            prevPage={goToPrev}
            nextPage={goToNext}
            canGoPrev={canGoNext}
            canGoNext={canGoPrev}
          />
        )}
      </ControlWrapper>
    </MaxWidthWrapper>
  )
}
