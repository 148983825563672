import React from 'react'
import styled, { css } from 'styled-components'
import { rem } from 'polished'

export const styledScrollbar = css`
  /* width */
  ::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background-color: #e3e3e5;

    &:hover {
      box-shadow: inset 8px 0 1px -8px rgba(0, 0, 0, 0.4);
    }
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;

    &:hover {
      background-color: rgba(0, 0, 0, 0.6);
    }

    &:active {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
`

const StyledGrid = styled.div<{ itemWidth: number; stretch: boolean }>`
  display: grid;
  column-gap: ${rem(30)};
  row-gap: ${rem(30)};
  grid-template-columns: repeat(
    ${({ stretch }) => (stretch ? 'auto-fit' : 'auto-fill')},
    minmax(${({ itemWidth }) => itemWidth}px, 1fr)
  );

  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    grid-template-columns: 1fr;
  }
`

export const ContentWrapper = styled.div<{fullWidth?:boolean}>`
  max-width: ${({ theme, fullWidth }) => !fullWidth ?  theme.contentMaxWidth : '100%'};
  flex: 1;
  padding: 0 ${rem(20)};

  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    padding: 0 ${rem(12)};
  }
`

interface IGrid {
  itemWidth: number
  children: React.ReactNode
  className?: string
  stretch?: boolean
}
export const Grid = ({ children, className, itemWidth = 100, stretch = false }: IGrid) => {
  return (
    <StyledGrid className={className} itemWidth={itemWidth} stretch={stretch}>
      {children}
    </StyledGrid>
  )
}
