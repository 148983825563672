export default class AuthenticationService {
  fetch(path: string, options: { method: string; body?: Record<string, unknown> }) {
    return fetch(`${process.env.REACT_APP_ID_TILTIFY_URL}/${path}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      method: options.method,
      body: JSON.stringify(options.body),
    }).then((response) => response)
  }

  signInUser(params: {
    user: { email: string; password: string }
    redirectObject: { type: string; value: string } | null
  }) {
    const options = {
      method: 'POST',
      body: params,
    }

    if (params.redirectObject) {
      return this.fetch(
        `user/login?redirect_${params.redirectObject.type}=${params.redirectObject.value}`,
        options
      )
    }

    return this.fetch('user/login', options)
  }

  signOutUser() {
    const options = {
      method: 'DELETE',
    }
    // Catch the expected 401 from a successful logout
    return this.fetch('user/logout', options).catch(() => {})
  }

  changeEmail(params: { user: { email: string } }) {
    const options = {
      method: 'PATCH',
      body: params,
    }
    return this.fetch('user/update_email', options)
  }

  confirmEmail(token: any) {
    const options = {
      method: 'PATCH',
    }
    return this.fetch(`/user/confirmation/${token}`, options)
  }

  passwordlessSignIn(email: string, redirectObject: { type: string; value: string } | null) {
    const options = {
      method: 'POST',
      body: { email },
    }

    if (redirectObject) {
      return this.fetch(
        `user/token/create?redirect_${redirectObject.type}=${redirectObject.value}`,
        options
      )
    }

    return this.fetch('user/token/create', options)
  }

  sendOTP(email: string, captcha: string) {
    const options = {
      method: 'POST',
      body: { email, captcha },
    }

    return this.fetch('user/otp/send', options)
  }

  verifyOTP(code: string) {
    const options = {
      method: 'POST',
      body: { code },
    }

    return this.fetch('user/otp/verify', options)
  }

  mfaCreate() {
    const options = {
      method: 'GET',
    }
    return this.fetch(`user/mfa/create`, options)
  }

  mfaConnect(params: { code: string }) {
    const options = {
      method: 'POST',
      body: params,
    }
    return this.fetch(`user/mfa/connect`, options)
  }

  mfaDelete(params: { code: string }) {
    const options = {
      method: 'POST',
      body: params,
    }
    return this.fetch(`user/mfa/delete`, options)
  }

  mfaVerify(params: any) {
    const options = {
      method: 'POST',
      body: params,
    }
    return this.fetch(`user/mfa/verify`, options)
  }

  generateAmplifyURL(
    email: string,
    scope_public_id: string,
    share_email_with_cause: boolean,
    share_email_with_fundraiser: boolean
  ) {
    const options = {
      method: 'POST',
      body: { email, scope_public_id, share_email_with_cause, share_email_with_fundraiser },
    }
    return this.fetch('user/share-url/create', options)
  }
}
