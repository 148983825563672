import styled from 'styled-components/macro'
import { rem } from 'polished'

import { Heading, MaxWidthWrapper } from 'components/styles'
import { Social } from '@tiltify/ui'
import JingleJamBlue from 'assets/JJBlue2023.png'
import pattern from 'assets/RedBackground.png'

const Wrapper = styled.div`
  background: ${({ theme }) => theme.pink};
  text-align: center;
  color: ${({ theme }) => theme.whiteText};
`

const Pattern = styled.div`
  background: url(${pattern});
  height: 170px;
  clip-path: polygon(100% 0, 100% 100%, 50% 50%, 0 100%, 0 0);
`
const SocialHeading = styled(Heading)`
  color: ${({ theme }) => theme.whiteText};
  font-size: ${rem(24)};
  font-weight: bold;
  line-height: 36px;
  text-align: center;
  letter-spacing: 1px;
  margin-top: -30px;
`

const Container = styled.div`
  display: flex;
  max-width: 1200px;
  padding-bottom: ${rem(100)};
  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    padding: 1rem;
  }
`

const SocialButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${rem(27)};
  a {
    background: white;
  }
`

const CopyWrapper = styled.div`
  max-width: 640px;
  text-align: left;
  margin-right: ${rem(45)};
  @media (max-width: 500px) {
    width: 100%;
    max-width: auto;
    text-align: center;
    margin-right: 0;
  }
`

const AboutCopy = styled.p`
  font-weight: normal;
  font-size: ${rem(18)};
  line-height: 36px;
  letter-spacing: 1px;
`
const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${rem(30)};
  margin-top: 1rem;

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;

    svg {
      margin-bottom: 1rem;
    }
  }

  img {
    width: 200px;
  }
`
const Years = styled.div`
  font-weight: bold;
  font-size: 102px;
  line-height: 85px;
  letter-spacing: 1px;
  margin-bottom: 1rem;
  @media (max-width: 500px) {
    font-size: 74px;
  }
`
const Raised = styled.div`
  font-weight: bold;
  font-size: 57px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 1px;
  margin-bottom: 1rem;
  @media (max-width: 500px) {
    font-size: 36px;
  }
`
const Charities = styled.div`
  font-weight: bold;
  font-size: 32px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 1px;
  @media (max-width: 500px) {
    line-height: 30px;
    font-size: 24px;
  }
`

export const About = (): JSX.Element => {
  const social = {
    // twitch: 'yogscast',
    twitter: 'jinglejam',
    // facebook: 'yogscast',
    // youtube: 'UCH-_hzb2ILSCo9ftVSnrCIQ',
    discord: 'https://discord.gg/dsCsJJcvAx',
    instagram: 'jinglejamofficial',
    linkedIn: 'jinglejam',
  }
  const socialArray = Object.entries(social)

  return (
    <Wrapper>
      <Pattern />
      <MaxWidthWrapper>
        <SocialHeading>About Jingle Jam</SocialHeading>
        <Container>
          <CopyWrapper>
            <AboutCopy>
              The Jingle Jam is the world’s biggest games charity event. Since the Yogscast created
              the Jingle Jam in 2011, the event has raised over £23 million, all for charitable
              projects in the UK and across the world. Help us celebrate by donating to our
              incredible charities and raising money by fundraising yourself!
            </AboutCopy>
          </CopyWrapper>
          <div>
            <LogoWrapper>
              <img src={JingleJamBlue} alt='blue jingle jam 2023 logo' />
            </LogoWrapper>
            <Years>10 YEARS</Years>
            <Raised>£23m+ RAISED</Raised>
            <Charities>37 DIFFERENT CHARITIES</Charities>
            <SocialButtonWrapper>
              {socialArray.map((item) => {
                if (item[0] !== '__typename') {
                  const colorOverride = null
                  return (
                    <Social
                      colorOverride={colorOverride}
                      border
                      src={item[1]}
                      network={item[0]}
                      key={item[0]}
                    />
                  )
                }
                return null
              })}
            </SocialButtonWrapper>
          </div>
        </Container>
      </MaxWidthWrapper>
    </Wrapper>
  )
}
