import { useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { rem } from 'polished'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import { Avatar } from '@tiltify/design/components/Avatar'
import { Multiplier } from '@tiltify/design/components/Multiplier'
import { Paragraph } from '@tiltify/design/components/Paragraph'
import { ProgressBar } from '@tiltify/design/components/ProgressBar'
import { Currency } from '@tiltify/design/components/Currency'
import color from './color.svg'
import { getCampaignUrl } from '@tiltify/shared/utils'

const MultiplierText = styled.div`
  position: absolute;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.white.core};
  padding: 2px 6px;
  background: linear-gradient(135deg, #e9190f 0%, #ff0f80 100%);
  box-shadow: ${({ theme }) => theme.depth[32]};
  border-radius: 3px;
  opacity: 0;
  right: 30px;
  top: -8px;
  transition: all 0.5s;
`

const StyledCampaignCard = styled.a`
  ${({ theme }) => theme.transition[0]}
  backdrop-filter: blur(20px);
  background-color: ${({ theme }) => theme.colors.white.core};
  background: ${({ theme }) => theme.colors.white.core};
  border-radius: 0.5rem;
  box-shadow: ${({ theme }) => theme.depth[4]};
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.black.core};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  max-width: 592px;
  min-height: 250px;
  overflow: hidden;
  padding: 1rem;
  position: relative;
  text-decoration: none;
  border: 1px solid;
  border-image-source: linear-gradient(146.22deg, #ffffff 8.92%, rgba(255, 255, 255, 0.6) 79.96%);
  transition: all 0.2s ease-in-out;
  text-align: left;

  &:hover {
    box-shadow: ${({ theme }) => theme.depth[8]};
  }

  &:hover ${MultiplierText} {
    opacity: 1;
    transform: translateY(11px);
  }
`

const ColorBackground = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  z-index: -1;
`

const ContentWrapper = styled.div`
  isolation: isolate;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  height: 100%;
`

const AvatarWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 1rem;
`

const Plus = styled.span`
  color: #000;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
`

const StyledUserName = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.25px;
  margin: 0;
`

const StyledCampaignName = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.25px;
  margin-top: 0.5rem;
`

const StyledMultiplier = styled.div`
  top: 15px;
  right: 15px;
  position: absolute;
  z-index: 2;
  display: flex;
  width: 100%;
  justify-content: flex-end;
`

const StyledLive = styled.div`
  box-sizing: border-box;
  padding: 2px 6px;
  top: 1rem;
  right: 1rem;
  position: absolute;
  z-index: 2;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.error.core};
  text-align: center;
  color: #fff;
  font-size: ${rem(13)};
  line-height: 1rem;
  text-transform: uppercase;
`

const AmountWrapper = styled.div`
  color: ${({ theme }) => theme.colors.black.core};
  display: flex;
  flex-direction: column;
  line-height: 1.15;
  text-align: left;
  font-size: 0.875rem;
  line-height: ${rem(17)};
  font-weight: 700;
`

interface CampaignCardProps {
  campaign?: TiltifyCampaign
  loading?: boolean
  progressColor?: string
}

export const CampaignCard = ({
  campaign,
  loading,
  progressColor,
}: CampaignCardProps): JSX.Element | null => {
  const theme = useTheme()
  const [raised, setRaised] = useState('0')
  const [currency, setCurrency] = useState('USD')
  const [percent, setPercent] = useState(0)

  const userAvatar =
    campaign?.avatar?.src || campaign?.team?.avatar?.src || campaign?.user?.avatar?.src

  useEffect(() => {
    if (campaign) {
      setRaised(String(campaign.totalAmountRaised.value))
      setCurrency(campaign.totalAmountRaised.currency ?? 'GBP')

      let width =
        Number(campaign.totalAmountRaised.value) > 0 && Number(campaign.goal.value)
          ? Number(campaign.totalAmountRaised.value) / Number(campaign.goal.value)
          : 0

      if (width > 1) width = 1

      setPercent(width)
    }
  }, [campaign])

  if (loading) {
    return (
      <SkeletonTheme
        baseColor={theme.isDark ? theme.colors.black[700] : '#d4dce466'}
        highlightColor={theme.isDark ? theme.colors.black[800] : ''}
      >
        <StyledCampaignCard as='div'>
          <ContentWrapper>
            <div>
              <AvatarWrapper>
                <Skeleton height={48} width={48} /> <Plus>+</Plus>
                <Skeleton height={48} width={48} />
              </AvatarWrapper>
              <StyledUserName>
                <Skeleton />
              </StyledUserName>
              <Paragraph size='small'>
                <Skeleton />
              </Paragraph>
              <StyledCampaignName>
                <Skeleton />
              </StyledCampaignName>
              <StyledCampaignName>
                <Skeleton />
              </StyledCampaignName>
            </div>
            <div>
              <AmountWrapper>
                <Skeleton width={50} />
              </AmountWrapper>
              <Skeleton />
            </div>
          </ContentWrapper>
        </StyledCampaignCard>
      </SkeletonTheme>
    )
  }

  if (!campaign || loading) return null

  const content = (
    <ContentWrapper>
      <div>
        <AvatarWrapper>
          {userAvatar && (
            <Avatar src={userAvatar} height={48} width={48} alt={`${campaign.name} avatar`} />
          )}
          <Plus>+</Plus>
          {campaign?.cause?.avatar?.src && (
            <Avatar
              src={campaign.cause.avatar.src}
              height={48}
              width={48}
              alt={`${campaign.cause.name} avatar`}
            />
          )}
          {campaign.live && !campaign.matchCount ? <StyledLive>Live</StyledLive> : null}
          {campaign.matchCount > 0 ? (
            <StyledMultiplier>
              {/* <MultiplierText>Donation match in progress</MultiplierText> */}
              <Multiplier count={campaign.matchCount + 1} />
            </StyledMultiplier>
          ) : null}
        </AvatarWrapper>

        <StyledUserName>{campaign?.team?.name || campaign?.user?.username}</StyledUserName>
        <Paragraph size='small'>{campaign.cause.name}</Paragraph>
        <StyledCampaignName>{campaign.name}</StyledCampaignName>
      </div>
      <div>
        <AmountWrapper>
          <Currency currency={currency} value={raised} />
        </AmountWrapper>

        <ProgressBar
          background={theme.colors.black[100]}
          foreground={progressColor || theme.colors.green.core}
          percent={percent}
          height={6}
        />
      </div>
    </ContentWrapper>
  )

  return (
    <StyledCampaignCard href={getCampaignUrl(campaign)}>
      {content} <ColorBackground src={color} alt='color' />
    </StyledCampaignCard>
  )
}
