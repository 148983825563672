import { defineMessages } from 'react-intl'

export const authenticationMessages = defineMessages({
  already: {
    id: 'Authentication.Already',
    defaultMessage: 'Already have an account?',
    description: 'text for the signin flow change',
  },
  welcomeBack: {
    id: 'Authentication.WelcomeBack',
    defaultMessage: 'Welcome back to Tiltify!',
    description: 'Heading text for login page',
  },
  orLoginWith: {
    id: 'Authentication.OrLoginWith',
    defaultMessage: 'or log in with',
    description: 'Text for the social login options',
  },
  orSignupWith: {
    id: 'Authentication.OrSingupWith',
    defaultMessage: 'or sign up with',
    description: 'Text for the social signup options',
  },
  causeLogin: {
    id: 'Authentication.CauseLogin',
    defaultMessage: 'Looking for your cause dashboard?',
    description: 'Label text to sign in as a cause.',
  },
  causeSignUpLabel: {
    id: 'Authentication.CauseSignUpLabel',
    defaultMessage: 'Looking to register as a cause?',
    description: 'Label text to sign up as a cause.',
  },
  changePassword: {
    id: 'Authentication.ChangePassword',
    defaultMessage:
      'An email will be sent to the address associated with this account with details on how to proceed.',
    description: 'Description for change password button',
  },
  createAccount: {
    id: 'Authentication.SignUp.Create',
    defaultMessage: 'Create account',
    description: 'The button text to create a new account.',
  },
  createAccountHeadingDonor: {
    id: 'Authentication.SignUp.CreateHeadingDonor',
    defaultMessage: 'Create a donor account',
    description: 'The heading text to create a new account.',
  },
  createAccountHeadingFundraiser: {
    id: 'Authentication.SignUp.CreateHeadingFundraiser',
    defaultMessage: 'Create a fundraiser account',
    description: 'The heading text to create a new account.',
  },
  createACauseAccount: {
    id: 'Authentication.CreateACauseAccount',
    defaultMessage: 'Create a cause account',
    description: 'Button text to sign up as a cause.',
  },
  createAnAccount: {
    id: 'Authentication.CreateAnAccount',
    defaultMessage: 'Create an account',
    description: 'Button text to create a new account.',
  },
  emailConfirmed: {
    id: 'Authentication.EmailConfirmed',
    defaultMessage: 'The email address has been confirmed.',
    description: 'The status text when successfully confirming an email.',
  },
  emailAddressConfirmed: {
    id: 'Authentication.EmailAddressConfirmed',
    defaultMessage: 'Email address confirmed!',
    description: 'The heading text when successfully confirming an email.',
  },
  emailAddressConfirmedDescription: {
    id: 'Authentication.EmailAddressConfirmedDescription',
    defaultMessage: 'Thanks for verifying your email address. You’re all set to go!',
    description: 'The heading text when successfully confirming an email.',
  },
  yourDashboard: {
    id: 'Authentication.YourDashboard',
    defaultMessage: 'Your dashboard',
    description: 'Text to return to dashboard.',
  },
  goToHomepage: {
    id: 'Authentication.GoToHomepage',
    defaultMessage: 'Go to home page',
    description: 'Text to return to homepage.',
  },
  forgot: {
    id: 'Authentication.Login.Forgot',
    defaultMessage: 'Forgot your password?',
    description: 'The button text to reset password.',
  },
  fundraiserDonor: {
    id: 'Authentication.FundraiserDonor',
    defaultMessage: 'Fundraiser/Donor',
    description: 'Button text to sign up as a fundraiser/donor.',
  },
  fundraiserSignUpLabel: {
    id: 'Authentication.FundraiserSignUpLabel',
    defaultMessage: 'If you want to start fundraising or record your donations, start here',
    description: 'Label text to sign up as a fundraiser/donor.',
  },
  login: {
    id: 'Authentication.Login',
    defaultMessage: 'Log in',
    description: 'Heading text to log in.',
  },
  logout: {
    id: 'Authentication.Logout',
    defaultMessage: 'Log out',
    description: 'Heading text to log out.',
  },
  loginDescription: {
    id: 'Authentication.LoginDescription',
    defaultMessage: 'You can log in with your email address, or one of our partner platforms below',
    description: 'Description text to log in.',
  },
  new: {
    id: 'Authentication.New',
    defaultMessage: 'New here?',
    description: 'Text for being a new user',
  },
  resetPassword: {
    id: 'Authentication.ResetPassword',
    defaultMessage: 'reset password',
    description: 'The button text to reset password.',
  },
  signUpText: {
    id: 'Authentication.SignUp',
    defaultMessage: 'Sign up',
    description: 'Text for sign up link',
  },
  TOS: {
    id: 'Authentication.SignUp.TOS',
    defaultMessage:
      'By signing up, you agree to our <tos>Terms of Service</tos> and <privacy>Privacy Policy</privacy>',
    description: 'Terms of Service messages displayed to user during sign up.',
  },
  completeProfileDescription: {
    id: 'Authentication.CompleteProfileDescription',
    defaultMessage: 'In order to complete your Tiltify account we need a little more information.',
    description: 'The text describing why the user must complete their profile.',
  },
  completeProfileHeading: {
    id: 'Authentication.CompleteProfileHeading',
    defaultMessage: 'Complete your account',
    description: 'Heading for complete profile',
  },
  completeProfile: {
    id: 'Authentication.CompleteProfile',
    defaultMessage: 'Complete Profile',
    description: 'Text for complete profile button',
  },
  upgradeHeading: {
    id: 'Authentication.UpgradeHeading',
    defaultMessage: 'Complete your fundraiser profile',
    description: 'Heading text for the upgrade panel',
  },
  upgradePOne: {
    id: 'Authentication.UpgradePOne',
    defaultMessage: 'We need a few more details in order to create your fundraiser profile.',
    description: 'Paragraph one for the upgrade panel',
  },
  upgradePTwo: {
    id: 'Authentication.UpgradePTwo',
    defaultMessage: 'All details below will appear on your profile.',
    description: 'Paragraph two for the upgrade panel',
  },
  defaultBio: {
    id: 'Authentication.DefaultBio',
    defaultMessage:
      'Follow all my campaigns and fundraising efforts right here on my Tiltify fundraising profile!',
    description: 'Placeholder bio for upgrade panel',
  },
})
