import {
  ApolloClient,
  ApolloError,
  ApolloQueryResult,
  InMemoryCache,
  OperationVariables,
  gql,
  useQuery,
} from '@apollo/client'
import { createContext, useContext, useEffect, useReducer } from 'react'
import { useNavigate } from 'react-router-dom'

import { Modal } from '@tiltify/design/components/Modal'

import { AuthenticationForm } from '../../components/AuthenticationForm'
import { useNotificationContext } from '../NotificationContextProvider'
import { RecaptchaProvider } from '../RecaptchaContext'
import { getInitialScope } from './getInitialScope'
import { ACTION_TYPES, Actions, authenticationReducer } from './reducer'

export const get_authed_user = gql`
  query get_authed_user {
    authenticatedUser {
      id
      username
      email
      slug
      firstName
      lastName
      jobTitle
      contactPhoneNumber
      confirmedAt
      canCreateCause
      profileComplete
      auctionPermissions
      mfaEnabled
      pendingVerification
      verifiedAt
      unreadCommunicationMessageCount
      features
      thirdPartyImport {
        previouslyClaimed
        hasUnclaimedCampaigns
        hasUnclaimedDonations
      }
      claimableFundraiserRewards {
        id
      }
      roles {
        donor
        fundraiser
      }
      connectedAccounts {
        id
        username
        provider
      }
      avatar {
        src
        alt
        width
        height
      }
      causes {
        id
        name
        features
        usageType
        currency
        subscription {
          currentPlan {
            id
            planTier
          }
        }
        avatar {
          src
          alt
          width
          height
        }
        userPermissions {
          key
          name
          description
        }
      }
      rejectedCauses {
        id
      }
    }
  }
`

interface IAuthenticationContext {
  authenticatedUser: AuthenticatedUser | null
  loading: boolean
  scope: UserScopeType
  shouldSetScope: boolean
  error?: ApolloError | string
  showAuthentication: boolean
  refetch?: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<{ data: AuthenticatedUser | null }>>
}

type AuthenticationDispatch = (action: Actions) => void

const AuthenticationContext = createContext<IAuthenticationContext>({
  loading: true,
  authenticatedUser: null,
  showAuthentication: false,
  scope: 'USER',
  shouldSetScope: true,
})
const AuthenticationDispatchContext = createContext<AuthenticationDispatch>(() => undefined)

export const client = new ApolloClient({
  uri: `${process.env.REACT_APP_DASHBOARD_API_URL}`,
  cache: new InMemoryCache(),
  credentials: 'include',
})

const AuthenticationProvider = ({
  children,
  prefix = '',
  testMode = false,
}: {
  children: JSX.Element
  prefix?: string
  testMode?: boolean
}) => {
  const { data, loading, error, refetch, startPolling, stopPolling } = useQuery(get_authed_user, {
    skip: window?.parent?.name === 'tiltifyWindow' && !!window.opener,
    client: testMode ? undefined : client,
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'ignore',
  })

  if (data?.authenticatedUser) {
    startPolling(300000)
  } else {
    stopPolling()
  }

  useEffect(() => {
    if (window?.parent?.name === 'tiltifyWindow' && window.opener) {
      window.opener.postMessage('closeTiltifyLogin', window.location.origin)
    }
  }, [])

  if (window?.parent?.name === 'tiltifyWindow' && window.opener) return null

  return (
    <AuthenticationContainer prefix={prefix} queryData={{ data, loading, error, refetch }}>
      {children}
    </AuthenticationContainer>
  )
}

const AuthenticationContainer = ({
  children,
  prefix = '',
  queryData,
}: {
  children: JSX.Element
  prefix?: string
  queryData: {
    data: { authenticatedUser: AuthenticatedUser | null }
    loading: boolean
    error?: ApolloError | string
    refetch: (
      variables?: Partial<OperationVariables> | undefined
    ) => Promise<ApolloQueryResult<{ data: AuthenticatedUser | null }>>
  }
}) => {
  const { triggerNotification } = useNotificationContext()
  const navigate = useNavigate()

  const [state, dispatch] = useReducer(authenticationReducer, {
    authenticatedUser: queryData?.data?.authenticatedUser,
    loading: queryData.loading,
    error: queryData.error,
    refetch: queryData.refetch,
    showAuthentication: false,
    scope: 'USER',
    shouldSetScope: true,
  })

  const forceSetScope = () => {
    dispatch({
      type: ACTION_TYPES.SHOULD_SET_USER_SCOPE,
      item: true,
    })
  }

  useEffect(() => {
    if (!queryData.loading && queryData.data) {
      const {
        data: { authenticatedUser },
        loading,
        error,
      } = queryData

      if (!state.authenticatedUser) {
        dispatch({
          type: ACTION_TYPES.SHOULD_SET_USER_SCOPE,
          item: false,
        })
        dispatch({
          type: ACTION_TYPES.SET_USER_SCOPE,
          item: getInitialScope(authenticatedUser, window.location.pathname, forceSetScope),
        })
        dispatch({
          type: ACTION_TYPES.INIT_CONTEXT,
          item: {
            authenticatedUser: authenticatedUser?.id ? authenticatedUser : null,
            loading,
            error,
          },
        })
      } else if (queryData?.data?.authenticatedUser) {
        if (
          state.shouldSetScope ||
          state.authenticatedUser?.id !== queryData?.data?.authenticatedUser?.id
        ) {
          dispatch({
            type: ACTION_TYPES.SET_USER_SCOPE,
            item: getInitialScope(
              queryData.data.authenticatedUser,
              window.location.pathname,
              forceSetScope
            ),
          })
        }
        dispatch({
          type: ACTION_TYPES.UPDATE_USER,
          item: queryData.data.authenticatedUser,
        })
      } else {
        triggerNotification('You have been logged out of your current session.')
        dispatch({
          type: ACTION_TYPES.INIT_CONTEXT,
          item: {
            authenticatedUser: null,
            loading,
            error,
          },
        })
      }
    }
  }, [queryData])

  useEffect(() => {
    if (state.authenticatedUser) {
      if (!state.authenticatedUser.profileComplete && state.authenticatedUser.roles?.fundraiser) {
        navigate(`${prefix}/login`)
      }
    }
  }, [state.authenticatedUser])

  const setIsVisible = (isVisible: boolean) => {
    dispatch({
      type: ACTION_TYPES.SHOW_AUTHENTICATION,
      item: isVisible,
    })
  }

  return (
    <AuthenticationContext.Provider value={state}>
      <AuthenticationDispatchContext.Provider value={dispatch}>
        {state.showAuthentication && (
          <RecaptchaProvider>
            <Modal
              setIsVisible={setIsVisible}
              title='Sign in or create an account'
              skipOutsideClick
            >
              <AuthenticationForm hideHeading />
            </Modal>
          </RecaptchaProvider>
        )}
        {children}
      </AuthenticationDispatchContext.Provider>
    </AuthenticationContext.Provider>
  )
}

const useAuthenticationState = () => {
  const context = useContext(AuthenticationContext)
  if (context === undefined) {
    throw new Error('useAuthenticationState must be used within a AuthenticationProvider')
  }
  return context
}

const useAuthenticationDispatch = () => {
  const context = useContext(AuthenticationDispatchContext)
  if (context === undefined) {
    throw new Error('useAuthenticationDispatch must be used within a AuthenticationProvider')
  }
  return context
}

useAuthenticationDispatch.ACTION_TYPES = ACTION_TYPES

export { AuthenticationProvider, useAuthenticationState, useAuthenticationDispatch }
