import styled from 'styled-components'

import { IframeEmbed } from '../IframeEmbed'

const StyledIframe = styled(IframeEmbed)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`

export const MediaIframeEmbed = ({ src, ...rest }: any) => {
  return <StyledIframe src={src} {...rest} />
}
