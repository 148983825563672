import { useTheme } from 'styled-components'

import { useFillColor, useSize } from './IconHooks'

export const Information = ({
  fill,
  fillType = 'black.50',
  size = 'small',
  height,
  width,
  title = 'Information',
}: TiltifyIcon) => {
  const theme = useTheme()
  const sizeValues = useSize(size, height, width)
  const fillValue = useFillColor(theme, fillType, fill)

  return (
    <svg
      width={sizeValues.width}
      height={sizeValues.height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>{title}</title>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.99998 1.33334C6.68144 1.33334 5.39251 1.72433 4.29618 2.45687C3.19985 3.18941 2.34537 4.23061 1.84079 5.44878C1.3362 6.66695 1.20418 8.0074 1.46141 9.3006C1.71865 10.5938 2.35359 11.7817 3.28594 12.714C4.21829 13.6464 5.40617 14.2813 6.69938 14.5386C7.99259 14.7958 9.33303 14.6638 10.5512 14.1592C11.7694 13.6546 12.8106 12.8001 13.5431 11.7038C14.2757 10.6075 14.6666 9.31854 14.6666 8C14.6666 7.12452 14.4942 6.25762 14.1592 5.44878C13.8241 4.63994 13.3331 3.90501 12.714 3.28596C12.095 2.6669 11.36 2.17584 10.5512 1.84081C9.74237 1.50577 8.87546 1.33334 7.99998 1.33334ZM7 5C7 4.44772 7.44772 4 8 4C8.55228 4 9 4.44772 9 5C9 5.55228 8.55228 6 8 6C7.44772 6 7 5.55228 7 5ZM7 8C7 7.44772 7.44772 7 8 7C8.55228 7 9 7.44772 9 8V11C9 11.5523 8.55228 12 8 12C7.44772 12 7 11.5523 7 11V8Z'
        fill={fillValue}
      />
    </svg>
  )
}
