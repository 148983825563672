import { NotificationBanner } from '@tiltify/design/components/Notification/NotificationBanner'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`
const StyledInput = styled.input<{ $error: boolean }>`
  width: 100%;
  height: 100%;
  top: 0px;
  bottom: 0px;
  left: 0px;
  position: absolute;
  border: none;
  font-size: 32px;
  text-align: center;
  background-color: transparent;
  outline: none;
`

const Box = styled.div<{ $focused: boolean; $disabled: boolean }>`
  border: ${({ theme, $focused }) =>
    $focused ? `2px solid ${theme.colors.blue.core}` : `2px solid ${theme.colors.black[400]}`};
  ${({ theme, $disabled }) => $disabled && `background: ${theme.colors.black[50]}`};
  border-radius: 4px;
  height: 68px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  font-weight: 700;
  position: relative;
  box-sizing: border-box;

  &:hover {
    cursor: pointer;
  }
`

const Wrapper = styled.div`
  margin-top: 24px;
  display: inline-block;
  position: relative;
  display: flex;
  gap: 1rem;
`

const CODE_LENGTH = new Array(6).fill(0)

interface CodeInputProps {
  onSubmit: ({ code }: { code: string }) => void
  disabled: boolean
  error: boolean
}

export const CodeInput = ({ onSubmit, disabled, error }: CodeInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [value, setValue] = useState('')
  const [focused, setFocused] = useState(false)
  const selectedIndex = value.length < CODE_LENGTH.length ? value.length : CODE_LENGTH.length - 1
  const hideInput = !(value.length < CODE_LENGTH.length)
  const [initialError, setInitialError] = useState(error)

  const handleClick = () => {
    if (!inputRef.current) return
    inputRef.current.focus()
  }

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (value.length >= CODE_LENGTH.length) return null
    return setValue((value + event.target.value).slice(0, CODE_LENGTH.length))
  }

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace') {
      setValue(value.slice(0, value.length - 1))
    }
  }

  useEffect(() => {
    if (initialError && value.length > 0) setInitialError(false)
    if (value.length === CODE_LENGTH.length) {
      onSubmit({ code: value })
    }
  }, [value])

  useEffect(() => {
    if (error) {
      setInitialError(true)
      if (inputRef.current) inputRef.current.focus()
      setValue('')
    }
  }, [error])

  return (
    <InputWrapper>
      <Wrapper onClick={handleClick}>
        <StyledInput
          ref={inputRef}
          $error={initialError}
          type='tel'
          name='OTP'
          value=''
          onChange={handleValueChange}
          disabled={disabled}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onKeyUp={handleKeyUp}
          style={{
            width: '68px',
            top: '0px',
            bottom: '0px',
            left: `${selectedIndex * 84}px`,
            opacity: hideInput ? 0 : 1,
          }}
        />
        {CODE_LENGTH.map((v, index) => {
          return (
            <Box $focused={focused && selectedIndex === index} $disabled={disabled} key={index}>
              {value[index]}
            </Box>
          )
        })}
      </Wrapper>
      {initialError && (
        <div style={{ marginBlock: 16 }}>
          <NotificationBanner
            type='error'
            message='Incorrect code entered. Please re-enter or request a new code'
          />
        </div>
      )}
    </InputWrapper>
  )
}
