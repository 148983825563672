import { useTheme } from 'styled-components'

import { useFillColor, useSize } from './IconHooks'

export const CaretRight = ({
  fill,
  fillType = 'black.50',
  size = 'small',
  height,
  width,
  title = 'Right',
}: TiltifyIcon) => {
  const theme = useTheme()
  const sizeValues = useSize(size, height, width)
  const fillValue = useFillColor(theme, fillType, fill)

  return (
    <svg
      width={sizeValues.width}
      height={sizeValues.height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>{title}</title>
      <path
        d='M10.9508 7.5358L6.71079 3.2958C6.61783 3.20207 6.50723 3.12768 6.38537 3.07691C6.26351 3.02614 6.1328 3 6.00079 3C5.86878 3 5.73807 3.02614 5.61622 3.07691C5.49436 3.12768 5.38376 3.20207 5.29079 3.2958C5.10454 3.48316 5 3.73661 5 4.0008C5 4.26498 5.10454 4.51844 5.29079 4.7058L8.83079 8.2458L5.29079 11.7858C5.10454 11.9732 5 12.2266 5 12.4908C5 12.755 5.10454 13.0084 5.29079 13.1958C5.38423 13.2885 5.49505 13.3618 5.61689 13.4116C5.73872 13.4613 5.86919 13.4866 6.00079 13.4858C6.1324 13.4866 6.26286 13.4613 6.3847 13.4116C6.50654 13.3618 6.61735 13.2885 6.71079 13.1958L10.9508 8.9558C11.0445 8.86283 11.1189 8.75223 11.1697 8.63037C11.2205 8.50852 11.2466 8.37781 11.2466 8.2458C11.2466 8.11379 11.2205 7.98308 11.1697 7.86122C11.1189 7.73936 11.0445 7.62876 10.9508 7.5358Z'
        fill={fillValue}
      />
    </svg>
  )
}
