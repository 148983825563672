import styled from 'styled-components'
import { rem } from 'polished'

const ToggleWrapper = styled.div<{ center?: boolean; width?: number }>`
  background: #fff;
  border: solid 1px #e21350;
  box-sizing: border-box;
  border-radius: 100px;
  display: flex;
  justify-content: space-between;
  margin: ${({ center }) => (center ? 'auto' : '')};
  height: 52px;
  max-width: ${({ width }) => width}px;
  margin-bottom: ${rem(64)};
  position: relative;
`

const Toggle = styled.button<{ active: boolean; toggleWidth?: number }>`
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  color: #0a0607;
  padding: 12px 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;
  cursor: pointer;
  -webkit-appearance: none !important;
  transition: color ease 0.7s;
  position: relative;
  z-index: 2;
  width: ${({ toggleWidth }) => toggleWidth}px;
  white-space: nowrap;

  ${({ active }) =>
    active &&
    `
    color: #fff;
    `};
`

const MovingTab = styled.div<{ left?: string; toggleWidth?: number }>`
  background: #e21350;
  border-radius: 100px;
  border: solid 1px #e21350;
  z-index: 1;
  display: block;
  position: absolute;
  bottom: 0;
  left: ${({ left }) => left};
  top: 0;
  width: ${({ toggleWidth }) => toggleWidth}px;
  border-radius: 100px;
  transition: left 0.4s cubic-bezier(0.29, 1, 0.79, 1);
`

export const SlidingToggle = ({
  current,
  setCurrent,
  names,
  center,
  toggleWidth,
}: {
  current: string
  setCurrent: (value: string) => void
  names: { key: string; title: string }[]
  center?: boolean
  toggleWidth: number
}): JSX.Element => {
  const getPosition = (current: string) => {
    const position = names.findIndex((item: { key: string; title: string }) => item.key === current)
    return `${(100 / names.length) * position}%`
  }

  return (
    <ToggleWrapper center={center} width={toggleWidth * names.length}>
      {names.map((name: { key: string; title: string }, index: number) => (
        <Toggle
          type='button'
          key={index}
          active={current === name.key}
          onClick={() => setCurrent(name.key)}
          toggleWidth={toggleWidth}
        >
          {name.title}
        </Toggle>
      ))}
      <MovingTab left={getPosition(current)} toggleWidth={toggleWidth} />
    </ToggleWrapper>
  )
}
