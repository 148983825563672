import React, { useEffect } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useFormContext } from 'react-hook-form'

const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY
  ? process.env.REACT_APP_RECAPTCHA_SITE_KEY
  : process.env.STORYBOOK_RECAPTCHA_SITE_KEY

export function Recaptcha({
  invisible,
  setRef,
}: {
  invisible?: boolean
  setRef: React.RefObject<ReCAPTCHA>
}) {
  const { setValue, register } = useFormContext()

  useEffect(() => {
    register('captcha', { required: true })
  }, [])

  const handleChange = (token: string | null) => {
    setValue('captcha', token)
  }

  return (
    <ReCAPTCHA
      ref={setRef}
      sitekey={SITE_KEY || ''}
      onChange={handleChange}
      size={invisible ? 'invisible' : 'normal'}
    />
  )
}
