import styled from 'styled-components'
import { rem } from 'polished'

const ListItem = styled.li`
  list-style: none;
`

const ListItemLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => (theme.isDark ? theme.colors.black[100] : theme.colors.black[700])};
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: ${rem(19)};

  &:hover {
    color: ${({ theme }) => (theme.isDark ? theme.colors.blue[100] : theme.colors.blue.core)};
  }
`

interface NavItemProps {
  text: string
  url: string
}

export const NavItem = ({ text, url }: NavItemProps): JSX.Element => {
  return (
    <ListItem>
      <ListItemLink href={url}>{text}</ListItemLink>
    </ListItem>
  )
}
