import { useState, useRef } from 'react'

export const useCaptchaValue = (): [() => void, (value: string) => void] => {
  const valueRef = useRef<string | null>(null)
  const [valueState, setValueState] = useState<string | null>(null)

  const setCaptchaValue = (value: string) => {
    valueRef.current = value
    setValueState(value)
    return value
  }

  const getCaptchaValue = () => {
    return valueRef.current || valueState
  }

  return [getCaptchaValue, setCaptchaValue]
}
