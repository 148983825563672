import { useEffect } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { useIntl } from 'react-intl'
import { useFormContext } from 'react-hook-form'

import { EmailInput } from '@tiltify/design/components/Forms/EmailInput'
import { TextInput } from '@tiltify/design/components/Forms/TextInput'
import { Button } from '@tiltify/design/components/Button'
import { inputMessages } from '@tiltify/translations'

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(18)};
  align-items: flex-start;
`

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  width: 100%;
`

export const FormFields = ({ errors }: { errors: any }) => {
  const { formatMessage } = useIntl()
  const { setError } = useFormContext()

  useEffect(() => {
    if (errors) {
      Object.keys(errors).map((key) => {
        setError(key, { message: `${key} ${errors[key]}`, type: 'validate' })
      })
    }
  }, [errors])

  return (
    <InputWrapper>
      <EmailInput
        required
        name='email'
        label={formatMessage(inputMessages.email)}
        placeholder={formatMessage(inputMessages.emailPlaceholder)}
        fixedWidth={false}
      />
      <TextInput
        required
        name='firstName'
        label={formatMessage(inputMessages.firstName)}
        fixedWidth={false}
        minLength={2}
        maxLength={56}
      />
      <TextInput
        required
        name='lastName'
        label={formatMessage(inputMessages.lastName)}
        fixedWidth={false}
        minLength={2}
        maxLength={56}
      />
      <TextInput
        required
        name='username'
        label='Choose a username'
        fixedWidth={false}
        minLength={3}
        maxLength={56}
      />
      <StyledButtonWrapper>
        <Button type='submit' icon='arrow-right' text='Create account' />
      </StyledButtonWrapper>
    </InputWrapper>
  )
}
