import styled from 'styled-components'

const StyledSVG = styled.svg<{ fillColor: string; strokeColor: string }>`
  fill: ${({ fillColor }) => fillColor};
  stroke: ${({ strokeColor }) => strokeColor};
`

export function CaretDown({
  ariaHidden,
  stroke,
  fill,
  focusable,
  height,
  title,
  width,
  ...rest
}: any) {
  const h = height || '1rem'
  const w = width || '1rem'

  return (
    <StyledSVG
      viewBox='0 0 17 17'
      width={w}
      height={h}
      aria-hidden={ariaHidden}
      focusable={focusable}
      fillColor={fill}
      strokeColor={stroke}
      {...rest}
    >
      {title ? <title>{title}</title> : null}
      <path
        d='M8.204 9.98c-.294.034-.6-.063-.827-.29L.31 2.624C-.077 2.237-.083 1.6.308 1.208.7.814 1.332.818 1.724 1.21L8.1 7.585l6.377-6.377c.386-.388 1.025-.393 1.416-.003.393.393.39 1.025-.002 1.416L8.827 9.69c-.172.172-.393.27-.62.29z'
        stroke='none'
        fillRule='evenodd'
      />
    </StyledSVG>
  )
}

CaretDown.defaultProps = {
  ariaHidden: false,
  focusable: false,
  stroke: '#000',
  fill: '#000',
}
