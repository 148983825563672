import { useTheme } from 'styled-components'

import { useFillColor, useSize } from './IconHooks'

export const ArrowRight = ({
  fill,
  fillType = 'black.50',
  size = 'small',
  height,
  width,
  title = 'Arrow right',
}: TiltifyIcon) => {
  const theme = useTheme()
  const sizeValues = useSize(size, height, width)
  const fillValue = useFillColor(theme, fillType, fill)

  return (
    <svg
      width={sizeValues.width}
      height={sizeValues.height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>{title}</title>
      <path
        d='M2.83368 8.83336L10.1613 8.83336L7.41017 11.5728C7.25318 11.7296 7.16499 11.9422 7.16499 12.1639C7.16499 12.3857 7.25318 12.5983 7.41017 12.7551C7.56715 12.9119 7.78007 13 8.00208 13C8.22409 13 8.43701 12.9119 8.59399 12.7551L12.7624 8.59189C12.8383 8.5127 12.8978 8.41933 12.9375 8.31712C13.0208 8.1144 13.0208 7.88702 12.9375 7.6843C12.8978 7.5821 12.8383 7.48872 12.7624 7.40953L8.59399 3.2463C8.51649 3.16825 8.42428 3.10631 8.32269 3.06404C8.2211 3.02176 8.11213 3 8.00208 3C7.89202 3 7.78306 3.02176 7.68146 3.06404C7.57987 3.10631 7.48767 3.16825 7.41017 3.2463C7.33203 3.3237 7.27001 3.41579 7.22768 3.51726C7.18536 3.61873 7.16357 3.72756 7.16357 3.83748C7.16356 3.94739 7.18536 4.05623 7.22768 4.15769C7.27001 4.25916 7.33203 4.35125 7.41017 4.42865L10.1613 7.16806L2.83368 7.16806C2.61257 7.16806 2.40052 7.25579 2.24418 7.41194C2.08783 7.56809 2 7.77988 2 8.00071C2 8.22154 2.08783 8.43333 2.24418 8.58948C2.40052 8.74563 2.61257 8.83336 2.83368 8.83336Z'
        fill={fillValue}
      />
    </svg>
  )
}
