import React, { useEffect } from 'react'
import styled from 'styled-components'
import { rgba, readableColor } from 'polished'

const StyledItem = styled.li<{ active: boolean }>`
  ${({ active, theme }) => {
    if (!active) return
    return `
      background-color: ${rgba(theme.primary, 0.05)};
      color: ${readableColor(theme.backgroundColor)};
    `
  }}
`

export const UserMenuItem = ({ active, index, node, setActiveIndex, userMenuData }: any) => {
  const ref = React.createRef()

  useEffect(() => {
    if (!ref.current) return

    if (active) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      ref.current.focus()
    }
  }, [active])

  const handleMouseMove = () => {
    if (!active) setActiveIndex(index)
  }

  return (
    <StyledItem role='menuitem' active={active} onMouseMove={handleMouseMove}>
      {React.createElement(node, {
        ...userMenuData,
        ref: ref,
      })}
    </StyledItem>
  )
}
