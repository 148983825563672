import styled from 'styled-components'

import { Currency } from '@tiltify/ui'
import { baseTiltifyUrl } from '@tiltify/ui/utils'

const TopThreeWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto 2rem;
  width: 100%;
  gap: 2rem;

  @media (max-width: 1100px) {
    flex-direction: column;
  }
`

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  min-width: 300px;
`

const CopyWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #e21350;
  box-sizing: border-box;
  border-radius: 0 100px 100px 0;
  margin-left: -15px;
  z-index: 0;
  height: 64px;
  flex: 1;
  overflow: hidden;
  padding: 8px 2rem;
  text-overflow: ellipsis;
`

const Name = styled.p`
  margin: 0;
  margin-bottom: 4px;
  font-weight: bold;
  font-size: 18px;
  color: #0a0607;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
`

const NameLink = styled.a`
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #0a0607;
  margin-bottom: 8px;
  display: block;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
`

const AmountRaised = styled.div`
  font-size: 18px;
  line-height: 14px;
  color: #0a0607;
`

const Number = styled.div`
  font-weight: bold;
  background: #e21350;
  border: 1px solid #e21350;
  box-sizing: border-box;
  border-radius: 100px;
  height: 85px;
  min-width: 85px;
  color: #fff;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`

interface ILeaderboardInfo {
  amount: TiltifyCurrency
  amountRaised: string
  total: string
  avatar: TiltifyImage
  heat: number
  id: string
  name: string
  url: string
}

export const TopThree = ({
  c,
  currency,
  linkName,
}: {
  c: ILeaderboardInfo[]
  currency: string
  linkName: boolean
}): JSX.Element => {
  return (
    <TopThreeWrapper>
      {c[0] && (
        <ContentWrapper>
          <Number>1st</Number>
          <CopyWrapper>
            {linkName ? (
              <NameLink href={`${baseTiltifyUrl()}${c[0].url}`}>{c[0].name}</NameLink>
            ) : (
              <Name>{c[0].name}</Name>
            )}
            <AmountRaised>
              <Currency currency={currency || 'USD'} value={c[0].amountRaised || c[0].total} />
            </AmountRaised>
          </CopyWrapper>
        </ContentWrapper>
      )}
      {c[1] && (
        <ContentWrapper>
          <Number>2nd</Number>
          <CopyWrapper>
            {linkName ? (
              <NameLink href={`${baseTiltifyUrl()}${c[1].url}`}>{c[1].name}</NameLink>
            ) : (
              <Name>{c[1].name}</Name>
            )}
            <AmountRaised>
              <Currency currency={currency || 'USD'} value={c[1].amountRaised || c[1].total} />
            </AmountRaised>
          </CopyWrapper>
        </ContentWrapper>
      )}
      {c[2] && (
        <ContentWrapper>
          <Number>3rd</Number>
          <CopyWrapper>
            {linkName ? (
              <NameLink href={`${baseTiltifyUrl()}${c[2].url}`}>{c[2].name}</NameLink>
            ) : (
              <Name>{c[2].name}</Name>
            )}
            <AmountRaised>
              <Currency currency={currency || 'USD'} value={c[2].amountRaised || c[2].total} />
            </AmountRaised>
          </CopyWrapper>
        </ContentWrapper>
      )}
    </TopThreeWrapper>
  )
}
