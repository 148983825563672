import styled from 'styled-components'

import { Facebook } from '../../icons/Facebook'
import { Instagram } from '../../icons/Instagram'
import { Twitter } from '../../icons/Twitter'
import { Snapchat } from '../../icons/Snapchat'
import { Twitch } from '../../icons/Twitch'
import { DiscordSimple } from '../../icons/DiscordSimple'
import { Website } from '../../icons/Website'
import { Youtube } from '../../icons/Youtube'
import { Tiktok } from '../../icons/Tiktok'
import { LinkedIn } from '../../icons/LinkedIn'
import { Reddit } from '../../icons/Reddit'
import { Button } from '../Button'

const Wrapper = styled(Button)<{
  width: number
  height: number
  noBottomMargin: boolean
  borderRadius: number
  backgroundColor: string
  border: boolean
  color: string
  bgFill: string
  colorOverride: string
}>`
  display: flex;
  overflow: hidden;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  margin-right: ${({ noBottomMargin }) => (noBottomMargin ? '0px' : '1rem')};
  margin-bottom: ${({ noBottomMargin }) => (noBottomMargin ? '0px' : '1rem')};
  border-radius: ${({ borderRadius }) => `${borderRadius}px`};
  background-color: ${({ backgroundColor, border }) => (border ? null : backgroundColor)};
  background-color: ${({ border }) => border === false && 'transparent'};
  color: ${({ border, backgroundColor, color }) => (border ? backgroundColor : color)};
  color: ${({ backgroundColor, bgFill, color }) => (bgFill ? backgroundColor : color)};
  color: ${({ colorOverride }) => colorOverride && colorOverride};
  border: ${({ backgroundColor, border }) => (border ? `2px solid ${backgroundColor}` : null)};
  border: ${({ colorOverride, border }) => border && colorOverride && `2px solid ${colorOverride}`};
  box-sizing: border-box;

  svg {
    height: 100%;
    transform: scale(0.5);
    width: 100%;
  }

  @media (max-width: 554px) {
    margin-top: 5px;
  }
`

const RENDERS = {
  facebook: {
    icon: Facebook,
    color: '#1877F2',
    fill: '#fff',
    source: 'https://facebook.com/',
    title: 'Facebook',
  },
  instagram: {
    icon: Instagram,
    color: '#000000',
    fill: '#fff',
    source: 'https://instagram.com/',
    title: 'Instagram',
  },
  snapchat: {
    icon: Snapchat,
    color: '#000000',
    fill: '#fff',
    source: 'https://www.snapchat.com/add/',
    title: 'Snapchat',
  },
  twitter: {
    icon: Twitter,
    color: '#1DA1F2',
    fill: '#fff',
    source: 'https://twitter.com/',
    title: 'Twitter',
  },
  twitch: {
    icon: Twitch,
    color: '#6441a4',
    fill: '#fff',
    source: 'https://www.twitch.tv/',
    title: 'Twitch',
  },
  website: {
    icon: Website,
    color: '#f2f2f2',
    fill: '#000',
    source: '',
    title: 'Website',
  },
  youtube: {
    icon: Youtube,
    color: '#FF0000',
    fill: '#fff',
    source: 'https://youtube.com/channel/',
    title: 'YouTube',
  },
  discord: {
    icon: DiscordSimple,
    color: '#5865F2',
    fill: '#fff',
    source: '',
    title: 'Discord',
  },
  tiktok: {
    icon: Tiktok,
    color: '#000000',
    fill: '#fff',
    source: 'https://www.tiktok.com/@',
    title: 'TikTok',
  },
  linkedIn: {
    icon: LinkedIn,
    color: '#0A66C2',
    fill: '#fff',
    source: 'https://www.linkedin.com/',
    title: 'LinkedIn',
  },
  reddit: {
    icon: Reddit,
    color: '#FF4500',
    fill: '#fff',
    source: 'https://www.reddit.com/',
    title: 'Reddit',
  },
}

export const Social = ({
  network,
  src,
  border,
  borderRadius,
  colorOverride,
  secondaryColorOverride,
  width,
  height,
  noBottomMargin,
  isDark,
  ...rest
}: any) => {
  if (!network || !src) return null

  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const backgroundColor = RENDERS[network]?.color
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const color = RENDERS[network]?.fill
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const Icon = RENDERS[network]?.icon
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const href = `${RENDERS[network]?.source}${src}`
  let formattedLink
  if (href && src !== 'disabled') formattedLink = href.indexOf('//') > -1 ? href : `//${href}`

  const getProps = () => {
    const baseProps = {
      width,
      height,
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      title: RENDERS[network]?.title,
      isDark,
    }

    switch (network) {
      case 'reddit':
        return {
          ...baseProps,
          fill: colorOverride || backgroundColor,
          fill2: secondaryColorOverride || color,
        }
      default:
        return baseProps
    }
  }
  return (
    <Wrapper
      as='a'
      target='_blank'
      rel='noopener noreferrer'
      href={formattedLink}
      border={border}
      borderRadius={borderRadius}
      colorOverride={colorOverride}
      color={color}
      backgroundColor={backgroundColor}
      width={width}
      height={height}
      noBottomMargin={noBottomMargin}
      bgFill
      {...rest}
    >
      <Icon {...getProps()} />
    </Wrapper>
  )
}

Social.defaultProps = {
  width: 48,
  height: 48,
  borderRadius: 4,
  noBottomMargin: false,
  bgFill: false,
}
