import { get } from 'lodash'
import { rem } from 'polished'
import { useCallback, useEffect, useRef, useState } from 'react'
import ReactAvatarEditor from 'react-avatar-editor'
import { useDropzone } from 'react-dropzone'
import { useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'

import { useNotificationContext } from '@tiltify/shared/contexts/NotificationContextProvider'

import { Close } from '../../../icons/Close'
import { Image } from '../../../icons/Image'
import { Setup } from '../../../icons/Setup'
import { Button } from '../../Button'
import { Modal } from '../../Modal'
import { StyledHelperMessage, StyledLabel } from '../styles'
import './toblob-polyfill'

const Container = styled.div<{
  disabled?: boolean
  error?: boolean
  isDragActive?: boolean
  small: boolean
  $fullWidth: boolean
}>`
  border: dashed 1px
    ${({ theme, error, isDragActive }) =>
      error
        ? theme.colors.error.core
        : isDragActive
          ? theme.colors.blue.core
          : theme.colors.black[400]};
  border-radius: 4px;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 0.25rem;
  max-width: ${({ $fullWidth }) => ($fullWidth ? '100%' : '360px')};
  ${({ disabled }) => disabled && 'pointer-events: none; opacity: 0.3;'};
  padding: 2rem;
  box-sizing: border-box;
  min-height: 210px;
  align-items: center;

  ${({ theme, isDragActive }) => isDragActive && `background-color: ${theme.colors.blue[50]};`}

  ${({ small }) =>
    small &&
    `
    min-height: 48px;
    height: 48px;
    padding: 14px;
  `}

  &:hover {
    cursor: pointer;
  }
`

const PreviewWrapper = styled.div`
  img {
    max-height: 100px;
    max-width: 100%;
  }
`

const StyledUploadText = styled.div<{ $small?: boolean }>`
  padding: ${({ $small }) => ($small ? 0 : 1)}rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

const StyledDragSpan = styled.span<{ small?: boolean }>`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: ${rem(19)};
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.colors.black[500]};

  ${({ small, theme }) =>
    small &&
    `
  
    display: flex;
  align-items: center;
  gap: 0.25rem;
 
  span {
    color: ${theme.colors.blue.core};
  }
  `}
`

const StyledFilesSpan = styled.span`
  font-size: ${rem(14)};
  font-style: italic;
  font-weight: 400;
  line-height: ${rem(19)};
  color: ${({ theme }) => theme.colors.black[500]};
`

const EditContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${rem(27)} 0;
  width: 480px;

  @media ${({ theme }) => `(max-width: ${theme.breakpoints[0]})`} {
    width: 100%;
  }
`

const OuterBox = styled.div<{ imageRatio: number }>`
  position: relative;
  width: 100%;
  padding-top: ${({ imageRatio }) => imageRatio}%;
  margin-bottom: 1rem;
`
const InnerBox = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;

  && canvas {
    max-width: 100%;
    max-height: 100%;
  }
`

const UploadWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.green[50]};
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 360px;
  box-sizing: border-box;
  border-radius: 4px;
  height: 50px;
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.black[700]};
  line-height: 1.5rem;

  && div {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 75%;
    white-space: nowrap;
  }
`

const Remove = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
`

interface IImageUploader {
  height: number
  width: number
  title: string
  name: string
  disabled?: boolean
  required?: boolean
  helperText?: string
  deps?: string | string[]
  small?: boolean
  fullWidth?: boolean
}

export const ImageUploader = ({
  height,
  width,
  title,
  name,
  disabled,
  required,
  helperText,
  deps,
  small = false,
  fullWidth = false,
}: IImageUploader) => {
  const [image, setImage] = useState<any>()
  const [type, setType] = useState<any>()
  const [scale, setScale] = useState(1)
  const [isVisible, setIsVisible] = useState(false)
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext()
  const { triggerNotification } = useNotificationContext()
  const editor = useRef<any>()
  const error = get(errors, name)
  const preview = useWatch({ name })

  const onDrop = useCallback((acceptedFiles: any, rejectedFiles: any) => {
    if (acceptedFiles.length > 0) {
      setImage(acceptedFiles[0])
      setIsVisible(true)
    }

    if (rejectedFiles.length > 0) {
      triggerNotification('Upload failed', true)
    }
  }, [])

  useEffect(() => {
    if (image) {
      setType(image.type)
    }
  }, [image])

  const handleUpload = (blob: any) => {
    blob.name = image?.name
    setValue(name, blob, { shouldTouch: true, shouldDirty: true })
    setIsVisible(false)
  }
  const handleRemove = () => {
    setImage(null)
    setType(null)
    setIsVisible(false)
  }

  const handleDelete = () => {
    setValue(name, null, { shouldTouch: true, shouldDirty: true })
  }

  const handleSubmit = () => {
    if (editor.current) {
      const canvas = editor.current.getImageScaledToCanvas()
      canvas.toBlob(handleUpload, type)
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/png, image/jpg, image/jpeg',
    multiple: false,
  })

  const imageRatio = height && width ? (height * 100) / width : 56.25

  return (
    <div style={{ width: '100%', maxWidth: fullWidth ? '100%' : '360px' }}>
      <StyledLabel>
        <span>{`${title}${!required ? ' (optional)' : ''}`}</span>
      </StyledLabel>
      <Container
        error={!!error}
        disabled={disabled}
        isDragActive={isDragActive}
        {...getRootProps()}
        small={small}
        $fullWidth={fullWidth}
      >
        <input
          {...register(name, {
            required,
            deps,
          })}
          {...getInputProps()}
          disabled={disabled}
          data-testid={name}
        />
        <StyledUploadText $small={small}>
          {!small && preview ? (
            <PreviewWrapper>
              {preview && (
                <img
                  src={typeof preview === 'string' ? preview : URL.createObjectURL(preview)}
                  alt=''
                />
              )}
            </PreviewWrapper>
          ) : isDragActive ? (
            <StyledDragSpan>Drop image file here</StyledDragSpan>
          ) : small ? (
            <StyledDragSpan small={small}>
              <Setup size='medium' fillType='black.400' /> Drop file here or{' '}
              <span>upload file</span>
            </StyledDragSpan>
          ) : (
            <>
              <Image size='xl' fillType='black.400' />
              <StyledDragSpan>Drop image file here</StyledDragSpan>
              <StyledFilesSpan>or click to upload</StyledFilesSpan>
            </>
          )}
        </StyledUploadText>
      </Container>
      {helperText && <StyledHelperMessage>{helperText}</StyledHelperMessage>}
      {preview ? (
        <UploadWrapper>
          <div>{typeof preview === 'string' ? preview.split('/').slice(-1)[0] : preview?.name}</div>
          <Remove type='button' onClick={handleDelete} disabled={disabled}>
            <Close fillType='black.400' />
          </Remove>
        </UploadWrapper>
      ) : null}
      {isVisible && (
        <Modal
          setIsVisible={setIsVisible}
          title={`Add / edit your ${title}`}
          skipOutsideClick
          buttonChildren={
            <div style={{ display: 'flex', gap: '1rem' }}>
              <Button
                type='button'
                size='small'
                buttonStyle='text'
                onClick={() => handleRemove()}
                text='remove'
              />
              <Button
                type='button'
                size='small'
                intent='primary'
                onClick={() => handleSubmit()}
                text='done'
              />
            </div>
          }
        >
          <EditContainer>
            <OuterBox imageRatio={imageRatio}>
              <InnerBox>
                <ReactAvatarEditor
                  ref={editor}
                  scale={scale}
                  border={5}
                  width={width}
                  height={height}
                  image={image}
                />
              </InnerBox>
            </OuterBox>
            <input
              type='range'
              onChange={(e: any) => setScale(Number(e.currentTarget.value))}
              value={scale}
              min='1'
              max='10'
              step='.1'
            />
          </EditContainer>
        </Modal>
      )}
    </div>
  )
}
