import { rem } from 'polished'
import styled from 'styled-components'

import { Currency } from '@tiltify/ui'
import { baseTiltifyUrl } from '@tiltify/ui/utils'
import { TopThree } from './TopThree'

const Spacer = styled.div`
  padding-right: ${rem(24)};
  width: 100%;
  @media (max-width: 1020px) {
    &:nth-child(3n) {
      display: none;
    }
  }
  @media (max-width: 800px) {
    width: 100%;
    &:nth-child(2n) {
      display: none;
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledFlex = styled.div`
  display: flex;
  height: 460px;
  flex-flow: row nowrap;
  justify-content: left;
`

const Row = styled.div`
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${rem(10)} 0;

  &:last-child {
    border-bottom: 1px solid transparent;
  }
`
const Left = styled.div`
  text-align: left;
  word-break: break-all;
  font-weight: normal;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`
const Right = styled.div`
  text-align: right;
  font-weight: 700;
  font-size: 16px;
  line-height: 14px;
`

const Link = styled.a`
  color: #0a0607;
`

interface ILeaderboardInfo {
  amount: TiltifyCurrency
  amountRaised: string
  total: string
  avatar: TiltifyImage
  heat: number
  id: string
  name: string
  url: string
}

export const Board = ({
  currency,
  campaigns,
  linkName,
}: {
  currency: string
  campaigns?: ILeaderboardInfo[]
  linkName: boolean
}): JSX.Element | null => {
  if (!campaigns) return null
  const baseCampaigns = campaigns.slice(3, 30)
  const renderTopThree = () => {
    const content = []

    for (let x = 0; x < 3; x += 3) {
      content.push(campaigns.slice(x, x + 3))
    }

    if (!content) return null

    return content.map((c, col) => {
      return <TopThree key={col} c={c} currency={currency} linkName={linkName} />
    })
  }

  const renderCampaigns = () => {
    const content = []

    for (let x = 0; x < 27; x += 9) {
      content.push(baseCampaigns.slice(x, x + 9))
    }
    if (!content) return null
    let it = 4
    return content.map((c, col) => {
      const map = c.map((campaign: ILeaderboardInfo, i: number) => {
        const num = it++
        return (
          <Row key={i}>
            <Left style={{ fontWeight: num === 1 ? 'bold' : 'normal' }}>
              {num}.{' '}
              {linkName ? (
                <Link href={`${baseTiltifyUrl()}${campaign.url}`}>{campaign.name}</Link>
              ) : (
                campaign.name
              )}
            </Left>
            <Right>
              <Currency
                currency={currency || 'USD'}
                value={campaign.amountRaised || campaign.total}
              />
            </Right>
          </Row>
        )
      })
      return <Spacer key={col}>{map}</Spacer>
    })
  }

  return (
    <Wrapper>
      {campaigns && renderTopThree()}
      <StyledFlex>{campaigns && renderCampaigns()}</StyledFlex>
    </Wrapper>
  )
}
