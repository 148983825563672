import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useQuery } from '@apollo/client'

import { Leaderboard as LeaderboardUI } from './LeaderboardUI'
import { MaxWidthWrapper } from 'components/styles'
import { useRouteContext } from 'contexts/RouteContext'
import { get_fe_leaderboards } from '@tiltify/ui/gql/queries/leaderboards'
import { formatFELeaderboards } from '@tiltify/ui/utils/formatNewLeaderboards'

const MaxWidthWrapperAlt = styled(MaxWidthWrapper)`
  padding: 1rem;
  z-index: 1;
  margin-top: 2rem;
`

interface ILeaderboardInfo {
  amount: TiltifyCurrency
  amountRaised: string
  total: string
  avatar: TiltifyImage
  heat: number
  id: string
  name: string
  url: string
}

interface ICustomLeaderboards {
  individual?: ILeaderboardInfo[]
  team?: ILeaderboardInfo[]
  weekly?: ILeaderboardInfo[]
  donor?: ILeaderboardInfo[]
}

export const Leaderboard = (): JSX.Element | null => {
  const { cause, fundraisingEvent } = useRouteContext()
  const [leaderboards, updateLeaderboards] = useState<ICustomLeaderboards>()

  const leaderboardsData = useQuery(get_fe_leaderboards, {
    variables: { publicId: fundraisingEvent?.publicId },
    skip: !fundraisingEvent,
  })

  useEffect(() => {
    if (!leaderboardsData?.loading && leaderboardsData?.data)
      formatFELeaderboards(leaderboardsData, updateLeaderboards)
  }, [leaderboardsData])

  if (!leaderboards) return null

  const individualCampaigns = leaderboards?.individual && leaderboards?.individual.length > 0
  const teamCampaigns = leaderboards?.team && leaderboards?.team.length > 0
  const donorCampaigns = leaderboards?.donor && leaderboards?.donor.length > 0

  if (!individualCampaigns && !teamCampaigns && !donorCampaigns) {
    return null
  }

  return (
    <MaxWidthWrapperAlt>
      <LeaderboardUI
        leaderboards={leaderboards}
        currency={cause?.paymentMethods[0]?.currency ?? 'usd'}
      />
    </MaxWidthWrapperAlt>
  )
}
