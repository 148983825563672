import { rem } from 'polished'
import styled, { css } from 'styled-components/macro'

import { Currency } from '@tiltify/ui'

import pattern from 'assets/BlueBackground.png'
import hero from 'assets/HeroBackground.png'
import JingleJamPink from 'assets/JJ2023.png'
// import { RedButton } from 'components/styles'
import { useRouteContext } from 'contexts/RouteContext'

const Background = styled.div`
  background: url(${hero});
  background-size: cover;
  background-position: center;
  color: #fff;
`
const Pattern = styled.div`
  background: url(${pattern});
  height: 170px;
  clip-path: polygon(50% 50%, 100% 0, 100% 100%, 0 100%, 0 0);
`

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: ${rem(37)} auto;

  && img {
    width: 200px;
  }
`

const Total = styled.p`
  margin: 0;
  text-align: center;
  font-size: ${rem(14)};
  line-height: 16px;
`

const Heading = styled.h1`
  font-size: ${rem(32)};
  line-height: 40px;
  text-align: center;
  max-width: 750px;
  margin: 20px auto;
`

const Amount = styled.div<{ marginBottom: boolean }>`
  font-size: ${rem(32)};
  font-weight: bold;
  display: flex;
  justify-content: center;

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${rem(80)};
    `}
`

const ButtonContainer = styled.div`
  margin-top: ${rem(37)};
  margin-bottom: ${rem(80)};
  display: flex;
  justify-content: center;

  @media (max-width: 552px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: ${rem(40)};
  }
`

export const Hero = (): JSX.Element => {
  const { fundraisingEvent } = useRouteContext()

  return (
    <>
      <Background>
        <LogoWrapper>
          <img src={JingleJamPink} alt='Jingle Jam Logo' />
        </LogoWrapper>
        <Heading>
          Thank you to everybody who has supported this year&apos;s Jingle Jam! Donations are now
          closed. See you all next year!
        </Heading>
        <Total>TOTAL RAISED</Total>
        <Amount marginBottom={!fundraisingEvent?.visibility?.donate}>
          <Currency
            value={fundraisingEvent?.totalAmountRaised?.value}
            currency={fundraisingEvent?.totalAmountRaised?.currency}
          />
        </Amount>

        {fundraisingEvent?.visibility?.donate && (
          <ButtonContainer>
            {/* <RedButton
              as='a'
              href='https://donate.tiltify.com/74c7c3a8-d8a5-4039-89c0-bfc7883a6251/incentives?rewardPublicId=d9e2b8c4-bc17-42bc-a0ae-cd91e961f1d3'
            >
              Donate Now!
            </RedButton> */}
          </ButtonContainer>
        )}

        {/* <ButtonContainer>
          <RedButton
            forwardedAs='a'
            href='https://tiltify.com/jingle-jam/jingle-jam-2023/start?regionId=566'
            disabled={!fundraisingEvent?.supportable}
          >
            Register Now!
          </RedButton>
        </ButtonContainer> */}

        <Pattern />
      </Background>
    </>
  )
}
