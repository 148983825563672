import { useTheme } from 'styled-components'

import { useFillColor, useSize } from './IconHooks'

export const Popout = ({
  fill,
  fillType = 'black.50',
  size = 'small',
  height,
  width,
  title = 'Popout',
}: TiltifyIcon) => {
  const theme = useTheme()
  const sizeValues = useSize(size, height, width)
  const fillValue = useFillColor(theme, fillType, fill)

  return (
    <svg
      width={sizeValues.width}
      height={sizeValues.height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>{title}</title>
      <path
        d='M12 7.21333C11.8232 7.21333 11.6537 7.28357 11.5286 7.4086C11.4036 7.53362 11.3334 7.70319 11.3334 7.88V12.6667C11.3334 12.8435 11.2631 13.013 11.1381 13.1381C11.0131 13.2631 10.8435 13.3333 10.6667 13.3333H3.33337C3.15656 13.3333 2.98699 13.2631 2.86197 13.1381C2.73695 13.013 2.66671 12.8435 2.66671 12.6667V5.33333C2.66671 5.15652 2.73695 4.98695 2.86197 4.86193C2.98699 4.73691 3.15656 4.66667 3.33337 4.66667H8.12004C8.29685 4.66667 8.46642 4.59643 8.59145 4.47141C8.71647 4.34638 8.78671 4.17681 8.78671 4C8.78671 3.82319 8.71647 3.65362 8.59145 3.5286C8.46642 3.40357 8.29685 3.33333 8.12004 3.33333H3.33337C2.80294 3.33333 2.29423 3.54405 1.91916 3.91912C1.54409 4.29419 1.33337 4.8029 1.33337 5.33333V12.6667C1.33337 13.1971 1.54409 13.7058 1.91916 14.0809C2.29423 14.456 2.80294 14.6667 3.33337 14.6667H10.6667C11.1971 14.6667 11.7058 14.456 12.0809 14.0809C12.456 13.7058 12.6667 13.1971 12.6667 12.6667V7.88C12.6667 7.70319 12.5965 7.53362 12.4714 7.4086C12.3464 7.28357 12.1769 7.21333 12 7.21333ZM14.6134 1.74667C14.5457 1.58377 14.4163 1.45432 14.2534 1.38667C14.1732 1.35251 14.0872 1.33439 14 1.33333H10C9.82323 1.33333 9.65366 1.40357 9.52864 1.5286C9.40361 1.65362 9.33337 1.82319 9.33337 2C9.33337 2.17681 9.40361 2.34638 9.52864 2.47141C9.65366 2.59643 9.82323 2.66667 10 2.66667H12.3934L5.52671 9.52667C5.46422 9.58864 5.41463 9.66238 5.38078 9.74362C5.34693 9.82486 5.32951 9.91199 5.32951 10C5.32951 10.088 5.34693 10.1751 5.38078 10.2564C5.41463 10.3376 5.46422 10.4114 5.52671 10.4733C5.58868 10.5358 5.66242 10.5854 5.74366 10.6193C5.8249 10.6531 5.91203 10.6705 6.00004 10.6705C6.08805 10.6705 6.17519 10.6531 6.25643 10.6193C6.33767 10.5854 6.4114 10.5358 6.47337 10.4733L13.3334 3.60667V6C13.3334 6.17681 13.4036 6.34638 13.5286 6.47141C13.6537 6.59643 13.8232 6.66667 14 6.66667C14.1769 6.66667 14.3464 6.59643 14.4714 6.47141C14.5965 6.34638 14.6667 6.17681 14.6667 6V2C14.6657 1.91288 14.6475 1.82682 14.6134 1.74667Z'
        fill={fillValue}
      />
    </svg>
  )
}
