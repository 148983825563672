import { useApolloClient } from '@apollo/client'
import { forwardRef, useImperativeHandle, useRef } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { Button } from '@tiltify/ui/components/Button'
import { userMenuMessages } from '@tiltify/ui/translations'

import { useAuthenticationDispatch } from '../../../../../../contexts/AuthenticationContext'
import AuthenticationService from '../../../../../../services/ApiService/AuthenticationService'

const StyledButton = styled(Button)`
  display: flex;
  width: 100%;
  font-size: 0.8125rem !important;
  font-weight: normal !important;
  padding: 0.625rem 1.0625rem;
  color: ${({ theme }) => theme.textColor} !important;
`

export const SignOut = forwardRef(({ onSignOutClick }: any, ref) => {
  const localRef = useRef()
  const authenticationDispatch = useAuthenticationDispatch()
  const client = useApolloClient()
  useImperativeHandle(ref, () => localRef.current)

  const handleSignout = () => {
    const service = new AuthenticationService()
    service
      .signOutUser()
      .then(() => {
        authenticationDispatch({
          type: useAuthenticationDispatch.ACTION_TYPES.CLEAR_USER,
        })
        client.resetStore()
      })
      .catch((err: any) => {
        throw err
      })
  }
  const { formatMessage } = useIntl()

  return (
    <StyledButton onClick={onSignOutClick || handleSignout} ref={localRef}>
      {formatMessage(userMenuMessages.signOut)}
    </StyledButton>
  )
})

SignOut.displayName = 'SignOut'
