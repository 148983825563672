/**
 *
 * @param {string} url
 * example urls
 * https://tiltify.com/pages/terms-of-service
 * https://stjude.tiltify.com
 *
 */
export const baseTiltifyUrl = () => {
  return process.env.REACT_APP_TILTIFY_URL || 'http://tiltifydev.com:5001'
}

export const getSubdomainUrl = (url: string, subdomain: string) => {
  if (!url) return
  const newUrl = url.split('//')
  newUrl[1] = `${subdomain}.${newUrl[1]}`

  return newUrl.join('//')
}

export const subdomainExists = (): boolean | '' | null => {
  const domain = window.location.host.toLowerCase().split('.')[1]
    ? window.location.host.split('.')[0]
    : null
  return domain && (domain === 'savethechildren' || domain === 'stjude' || domain === 't4c')
}

export const getSubdomainSlug = (): string | null => {
  const domain = window.location.host.toLowerCase().split('.')[1]
    ? window.location.host.split('.')[0]
    : null

  let subdomainSlug = null

  if (domain) {
    if (domain === 'savethechildren') subdomainSlug = 'save-the-children'
    if (domain === 'stjude') subdomainSlug = 'st-jude-children-s-research-hospital'
    if (domain === 't4c') subdomainSlug = 't4c'
  }

  return subdomainSlug
}

export const getCampaignUrl = (
  campaign?:
    | Pick<TiltifyCampaign, 'user' | 'status' | 'slug'>
    | Pick<TiltifyTeamEvent, 'team' | 'status' | 'slug' | 'currentSlug'>
    | null,
  local = false,
  ignoreStatus = false
) => {
  if (!campaign) return ''
  let vanity = ''
  let preview = ''
  let slug = campaign.slug

  if ('user' in campaign) {
    vanity = `@${campaign?.user?.slug}`
  }

  if ('team' in campaign && campaign?.team && campaign?.team?.slug) {
    vanity = `+${campaign.team.slug}`
    slug = campaign.currentSlug
  }

  if (!ignoreStatus && (campaign?.status === 'active' || campaign?.status === 'unpublished')) {
    preview = '/preview'
  }

  if (local) return `/${vanity}/${slug}${preview}`
  return `${baseTiltifyUrl()}/${vanity}/${slug}${preview}`
}

export const getTeamEventUrl = (teamEvent?: TiltifyTeamEvent | null, local = false) => {
  if (!teamEvent) return ''

  const vanity = `+${teamEvent.team.slug}`

  if (local) return `/${vanity}/${teamEvent.currentSlug}`
  return `${baseTiltifyUrl()}/${vanity}/${teamEvent.currentSlug}`
}
