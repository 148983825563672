export const Tiktok = ({ isDark, title, ...rest }: any) => {
  return (
    <svg {...rest} viewBox='0 0 32 32' fill='none' stroke='none'>
      {title ? <title>{title}</title> : null}
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.5774 1.82133C21.4288 1.23906 21.3497 0.628797 21.3497 0H16.1139V10.2857V11.9805V20.8C16.1139 23.1985 14.1775 25.1429 11.7887 25.1429C9.39993 25.1429 7.46346 23.1985 7.46346 20.8C7.46346 18.4015 9.39993 16.4571 11.7887 16.4571C12.266 16.4571 12.7252 16.5348 13.1546 16.6781V11.0663C12.7082 11.0038 12.2522 10.9714 11.7887 10.9714C6.38255 10.9714 2 15.3718 2 20.8C2 26.2282 6.38255 30.6286 11.7887 30.6286C17.1188 30.6286 21.4564 26.3512 21.5774 21.0286V20.8V10.3858C23.5867 11.7649 26.0167 12.5714 28.6343 12.5714V7.31429C25.2374 7.31429 22.3835 4.9797 21.5774 1.82133Z'
        fill={!isDark ? '#25F4EE' : 'none'}
        stroke='none'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.9426 3.1934C22.794 2.61113 22.715 2.00087 22.715 1.37207H17.4792V11.6578V13.3526V22.1721C17.4792 24.5706 15.5427 26.5149 13.1539 26.5149C10.7652 26.5149 8.82869 24.5706 8.82869 22.1721C8.82869 19.7736 10.7652 17.8292 13.1539 17.8292C13.6312 17.8292 14.0905 17.9068 14.5198 18.0502V12.4384C14.0734 12.3758 13.6174 12.3435 13.1539 12.3435C7.74778 12.3435 3.36523 16.7439 3.36523 22.1721C3.36523 27.6002 7.74778 32.0006 13.1539 32.0006C18.484 32.0006 22.8217 27.7232 22.9426 22.4006V22.1721V11.7578C24.9519 13.1369 27.382 13.9435 29.9996 13.9435V8.68636C26.6027 8.68636 23.7488 6.35177 22.9426 3.1934Z'
        // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
        fill={!isDark ? 'black' : null}
        stroke='none'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.4779 1.37109C21.5066 1.52266 21.5399 1.67257 21.5777 1.82068C21.9889 3.43189 22.9331 4.82873 24.2104 5.81059C23.6259 5.04319 23.1892 4.15615 22.9432 3.19242C22.7946 2.61015 22.7156 1.99989 22.7156 1.37109H21.4779ZM28.6347 8.55708V12.5708C26.017 12.5708 23.587 11.7642 21.5777 10.3851V20.7993V21.0279C21.4567 26.3505 17.1191 30.6279 11.789 30.6279C9.43291 30.6279 7.27124 29.7921 5.58203 28.3997C7.37722 30.5975 10.1028 31.9997 13.1545 31.9997C18.4846 31.9997 22.8223 27.7223 22.9432 22.3997V22.1711V11.7568C24.9525 13.136 27.3826 13.9425 30.0002 13.9425V8.68538C29.5335 8.68538 29.077 8.64131 28.6347 8.55708ZM13.1549 12.3425V16.6775C12.7255 16.5341 12.2663 16.4565 11.789 16.4565C9.40023 16.4565 7.46376 18.4009 7.46376 20.7993C7.46376 22.3494 8.27252 23.7098 9.48945 24.4782C9.07119 23.8098 8.82929 23.0188 8.82929 22.1711C8.82929 19.7726 10.7658 17.8282 13.1545 17.8282C13.6318 17.8282 14.0911 17.9059 14.5204 18.0492V12.4374C14.0741 12.3749 13.6183 12.3425 13.1549 12.3425Z'
        fill={!isDark ? '#FE2C55' : 'none'}
        stroke='none'
      />
    </svg>
  )
}
