export const Facebook = ({ fill, height = 16, width = 16 }: TiltifyIcon) => {
  return fill ? (
    <svg
      width={width}
      height={height}
      viewBox='0 0 17 16'
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      stroke='none'
    >
      <title>Facebook</title>
      <path
        d='M16.3871 8.04883C16.3871 3.60355 12.8053 -6.10352e-05 8.38705 -6.10352e-05C3.96877 -6.10352e-05 0.387054 3.60355 0.387054 8.04883C0.387054 12.0662 3.31252 15.3961 7.13705 15.9999V10.3755H5.1058V8.04883H7.13705V6.27556C7.13705 4.25831 8.33143 3.14404 10.1588 3.14404C11.0341 3.14404 11.9496 3.30124 11.9496 3.30124V5.28202H10.9408C9.94705 5.28202 9.63705 5.90251 9.63705 6.53903V8.04883H11.8558L11.5011 10.3755H9.63705V15.9999C13.4616 15.3961 16.3871 12.0664 16.3871 8.04883Z'
        fill={fill}
      />
    </svg>
  ) : (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14222 14222'
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      stroke='none'
    >
      <title>Facebook</title>
      <circle cx='7111' cy='7112' r='7111' fill='#1877F2' />
      <path
        d='M9879 9168l315-2056H8222V5778c0-562 275-1111 1159-1111h897V2917s-814-139-1592-139c-1624 0-2686 984-2686 2767v1567H4194v2056h1806v4969c362 57 733 86 1111 86s749-30 1111-86V9168z'
        fill='#fff'
      />
    </svg>
  )
}
