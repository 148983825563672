import React, { ReactNode, useRef } from 'react'

import { Form } from '@tiltify/design/components/Forms/Form'

import { Recaptcha } from '../../components/Recaptcha'
import { useCaptchaValue } from './useCaptchaValue'

const RecaptchaContext = React.createContext({
  executeAsync: () => new Promise(() => {}),
  getCaptchaValue: () => {},
})

export const RecaptchaProvider = ({ children }: { children: ReactNode }) => {
  const captcha = useRef<any>(null)
  const [getCaptchaValue, setCaptchaValue] = useCaptchaValue()

  const executeInvisible = () => {
    return new Promise((resolve) => {
      if (captcha.current) {
        captcha.current.reset()
        captcha.current
          .executeAsync()
          .then((value: string) => setCaptchaValue(value))
          .then((value: string) => resolve(value))
      }
    })
  }

  const executeAsync = () => {
    return executeInvisible()
  }

  const providerState = {
    executeAsync,
    getCaptchaValue,
  }

  return (
    <RecaptchaContext.Provider value={providerState}>
      <Form onSubmit={() => {}} resetOnSubmit={false}>
        <Recaptcha setRef={captcha} invisible />
      </Form>
      {children}
    </RecaptchaContext.Provider>
  )
}

export const useRecaptchaContext = () => {
  const context = React.useContext(RecaptchaContext)

  if (context === undefined) {
    throw new Error('useRecaptchaContext must be used within an RecaptchaProvider')
  }

  return context
}
