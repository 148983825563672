import { useTheme } from 'styled-components'

import { useFillColor, useSize } from './IconHooks'

export const Envelope = ({
  fill,
  fillType = 'black.50',
  size = 'small',
  height,
  width,
  title = 'Envelope',
}: TiltifyIcon) => {
  const theme = useTheme()
  const sizeValues = useSize(size, height, width)
  const fillValue = useFillColor(theme, fillType, fill)

  return (
    <svg
      width={sizeValues.width}
      height={sizeValues.height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>{title}</title>
      <path
        d='M12.6667 2.66667H3.33337C2.80294 2.66667 2.29423 2.87738 1.91916 3.25245C1.54409 3.62753 1.33337 4.13623 1.33337 4.66667V11.3333C1.33337 11.8638 1.54409 12.3725 1.91916 12.7475C2.29423 13.1226 2.80294 13.3333 3.33337 13.3333H12.6667C13.1971 13.3333 13.7058 13.1226 14.0809 12.7475C14.456 12.3725 14.6667 11.8638 14.6667 11.3333V4.66667C14.6667 4.13623 14.456 3.62753 14.0809 3.25245C13.7058 2.87738 13.1971 2.66667 12.6667 2.66667ZM12.3934 4L8.47337 7.92C8.4114 7.98248 8.33767 8.03208 8.25643 8.06593C8.17519 8.09977 8.08805 8.1172 8.00004 8.1172C7.91203 8.1172 7.8249 8.09977 7.74366 8.06593C7.66242 8.03208 7.58868 7.98248 7.52671 7.92L3.60671 4H12.3934ZM13.3334 11.3333C13.3334 11.5101 13.2631 11.6797 13.1381 11.8047C13.0131 11.9298 12.8435 12 12.6667 12H3.33337C3.15656 12 2.98699 11.9298 2.86197 11.8047C2.73695 11.6797 2.66671 11.5101 2.66671 11.3333V4.94L6.58671 8.86C6.96171 9.23453 7.47004 9.44491 8.00004 9.44491C8.53004 9.44491 9.03837 9.23453 9.41337 8.86L13.3334 4.94V11.3333Z'
        fill={fillValue}
      />
    </svg>
  )
}
