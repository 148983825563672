export const Light = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16 8C16 3.59071 12.4183 0 8 0C3.59071 0 0 3.58172 0 8C0 12.4093 3.58172 16 8 16C12.4093 16 16 12.4183 16 8ZM8 14.8571C4.21621 14.8571 1.14286 11.7814 1.14286 8C1.14286 4.21621 4.21859 1.14286 8 1.14286C11.7838 1.14286 14.8571 4.21859 14.8571 8C14.8571 11.7838 11.7814 14.8571 8 14.8571Z'
        fill='#1F2124'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.2863 7.99998C10.2863 6.72881 9.26292 5.71426 8.00056 5.71426C6.72939 5.71426 5.71484 6.73761 5.71484 7.99998C5.71484 9.27114 6.73819 10.2857 8.00056 10.2857C9.27172 10.2857 10.2863 9.26234 10.2863 7.99998ZM8.00056 9.14284C7.36565 9.14284 6.8577 8.63624 6.8577 7.99998C6.8577 7.36507 7.3643 6.85712 8.00056 6.85712C8.63547 6.85712 9.14342 7.36372 9.14342 7.99998C9.14342 8.63489 8.63682 9.14284 8.00056 9.14284Z'
        fill='#1F2124'
      />
      <path
        d='M7.43877 3.99002L7.4288 4.56136C7.42329 4.8769 7.67463 5.13716 7.99017 5.14267C8.30571 5.14818 8.56598 4.89685 8.57148 4.5813L8.58146 4.00996C8.58696 3.69442 8.33563 3.43416 8.02009 3.42865C7.70454 3.42314 7.44428 3.67447 7.43877 3.99002Z'
        fill='#1F2124'
      />
      <path
        d='M11.4189 8.57133L11.9902 8.5813C12.3058 8.58681 12.566 8.33548 12.5715 8.01993C12.577 7.70439 12.3257 7.44413 12.0102 7.43862L11.4388 7.42865C11.1233 7.42314 10.863 7.67447 10.8575 7.99002C10.852 8.30556 11.1033 8.56582 11.4189 8.57133Z'
        fill='#1F2124'
      />
      <path
        d='M3.99017 8.57133L4.56151 8.5813C4.87705 8.58681 5.13732 8.33548 5.14282 8.01993C5.14833 7.70439 4.897 7.44413 4.58146 7.43862L4.01011 7.42865C3.69457 7.42314 3.43431 7.67447 3.4288 7.99002C3.42329 8.30556 3.67463 8.56582 3.99017 8.57133Z'
        fill='#1F2124'
      />
      <path
        d='M7.42871 11.4285V12C7.42871 12.3156 7.68455 12.5714 8.00014 12.5714C8.31573 12.5714 8.57157 12.3156 8.57157 12V11.4285C8.57157 11.113 8.31573 10.8571 8.00014 10.8571C7.68455 10.8571 7.42871 11.113 7.42871 11.4285Z'
        fill='#1F2124'
      />
      <path
        d='M4.76838 5.56147L5.16533 5.97252C5.38456 6.19953 5.74631 6.20585 5.97333 5.98662C6.20035 5.76739 6.20666 5.40564 5.98743 5.17862L5.59049 4.76757C5.37126 4.54055 5.0095 4.53424 4.78249 4.75347C4.55547 4.97269 4.54915 5.33445 4.76838 5.56147Z'
        fill='#1F2124'
      />
      <path
        d='M10.8223 5.98667L11.2333 5.58973C11.4603 5.3705 11.4666 5.00874 11.2474 4.78173C11.0282 4.55471 10.6664 4.5484 10.4394 4.76762L10.0284 5.16457C9.80135 5.3838 9.79503 5.74555 10.0143 5.97257C10.2335 6.19959 10.5952 6.2059 10.8223 5.98667Z'
        fill='#1F2124'
      />
      <path
        d='M5.56933 11.2395L5.98038 10.8425C6.2074 10.6233 6.21371 10.2616 5.99448 10.0345C5.77526 9.80752 5.4135 9.8012 5.18649 10.0204L4.77543 10.4174C4.54842 10.6366 4.5421 10.9984 4.76133 11.2254C4.98056 11.4524 5.34231 11.4587 5.56933 11.2395Z'
        fill='#1F2124'
      />
      <path
        d='M10.0212 10.8284L10.4253 11.2325C10.6485 11.4557 11.0103 11.4557 11.2334 11.2325C11.4566 11.0093 11.4566 10.6475 11.2334 10.4244L10.8294 10.0203C10.6062 9.79716 10.2444 9.79716 10.0212 10.0203C9.79809 10.2435 9.79809 10.6053 10.0212 10.8284Z'
        fill='#1F2124'
      />
      <path
        d='M3.42861 14.2857H12.5715C12.8871 14.2857 13.1429 14.0299 13.1429 13.7143C13.1429 13.3987 12.8871 13.1429 12.5715 13.1429H3.42861C3.11302 13.1429 2.85718 13.3987 2.85718 13.7143C2.85718 14.0299 3.11302 14.2857 3.42861 14.2857Z'
        fill='#1F2124'
      />
    </svg>
  )
}
