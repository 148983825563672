import { useTheme } from 'styled-components'

import { useFillColor, useSize } from './IconHooks'

export const Amplify = ({
  fill,
  fillType = 'black.50',
  size = 'small',
  height,
  width,
  title = 'Clock',
}: TiltifyIcon) => {
  const theme = useTheme()
  const sizeValues = useSize(size, height, width)
  const fillValue = useFillColor(theme, fillType, fill)

  return (
    <svg
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
      width={sizeValues.width}
      height={sizeValues.height}
      aria-hidden={false}
      focusable={false}
      fill='none'
    >
      <title>{title}</title>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 8.54545C8.30125 8.54545 8.54545 8.30125 8.54545 8C8.54545 7.69875 8.30125 7.45455 8 7.45455C7.69875 7.45455 7.45455 7.69875 7.45455 8C7.45455 8.30125 7.69875 8.54545 8 8.54545ZM8 9.63636C8.90374 9.63636 9.63636 8.90374 9.63636 8C9.63636 7.09626 8.90374 6.36364 8 6.36364C7.09626 6.36364 6.36364 7.09626 6.36364 8C6.36364 8.90374 7.09626 9.63636 8 9.63636Z'
        fill={fillValue}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.45455 4.72727C7.45455 4.42603 7.69875 4.18182 8 4.18182C9.04016 4.18182 9.9841 4.59847 10.6722 5.27275C11.3788 5.96513 11.8182 6.93174 11.8182 8C11.8182 8.30125 11.574 8.54545 11.2727 8.54545C10.9715 8.54545 10.7273 8.30125 10.7273 8C10.7273 7.23676 10.4145 6.54757 9.90867 6.05192C9.41622 5.56936 8.74329 5.27273 8 5.27273C7.69875 5.27273 7.45455 5.02852 7.45455 4.72727ZM4.72727 7.45455C5.02852 7.45455 5.27273 7.69875 5.27273 8C5.27273 8.76324 5.58553 9.45244 6.09133 9.94808C6.58379 10.4306 7.25671 10.7273 8 10.7273C8.30125 10.7273 8.54545 10.9715 8.54545 11.2727C8.54545 11.574 8.30125 11.8182 8 11.8182C6.95984 11.8182 6.0159 11.4015 5.3278 10.7272C4.62124 10.0349 4.18182 9.06826 4.18182 8C4.18182 7.69875 4.42603 7.45455 4.72727 7.45455Z'
        fill={fillValue}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.45455 2.54545C7.45455 2.24421 7.69875 2 8 2C9.63465 2 11.1175 2.65441 12.1992 3.71431C13.3098 4.80269 14 6.32124 14 8C14 8.30125 13.7558 8.54545 13.4545 8.54545C13.1533 8.54545 12.9091 8.30125 12.9091 8C12.9091 6.62626 12.3455 5.38513 11.4356 4.49348C10.5497 3.6253 9.33778 3.09091 8 3.09091C7.69875 3.09091 7.45455 2.8467 7.45455 2.54545ZM2.54545 7.45455C2.8467 7.45455 3.09091 7.69875 3.09091 8C3.09091 9.37374 3.65446 10.6149 4.56437 11.5065C5.45035 12.3747 6.66222 12.9091 8 12.9091C8.30125 12.9091 8.54545 13.1533 8.54545 13.4545C8.54545 13.7558 8.30125 14 8 14C6.36536 14 4.88246 13.3456 3.80085 12.2857C2.69017 11.1973 2 9.67876 2 8C2 7.69875 2.24421 7.45455 2.54545 7.45455Z'
        fill={fillValue}
      />
    </svg>
  )
}
