import { MediaIframeEmbed } from '../MediaIframeEmbed'

export const YoutubeLive = ({ channel, ...rest }: any) => {
  if (!channel) return null

  return (
    <MediaIframeEmbed
      src={`https://www.youtube.com/embed/live_stream?channel=${channel}`}
      {...rest}
    />
  )
}
