import { get } from 'lodash'
import { useEffect, useState } from 'react'
import { DefaultTheme } from 'styled-components'

type SizeType = 'small' | 'medium' | 'large' | 'xl'

const getSize = (size: SizeType, height?: number, width?: number) => {
  if (height && width) return { height, width }
  if (size === 'medium') return { height: 20, width: 20 }
  if (size === 'large') return { height: 24, width: 24 }
  if (size === 'xl') return { height: 48, width: 48 }
  return { height: 16, width: 16 }
}

export const useSize = (
  size: SizeType,
  height?: number,
  width?: number
): { height: number; width: number } => {
  const [sizeValues, setSizeValues] = useState(() => getSize(size, height, width))

  useEffect(() => {
    setSizeValues(getSize(size, height, width))
  }, [height, width, size])

  return sizeValues
}

type FillType =
  | 'black.50'
  | 'black.200'
  | 'blue.200'
  | 'blue.core'
  | 'black.400'
  | 'error.core'
  | 'warning.core'
  | 'warning.600'
  | 'green.200'
  | 'green.800'
  | 'white.core'

const colorList = [
  'black.50',
  'black.200',
  'blue.200',
  'blue.core',
  'black.400',
  'error.core',
  'warning.core',
  'warning.600',
  'green.200',
  'green.800',
  'white.core',
]

const getFillColor = (theme: DefaultTheme, fillType: FillType, fill?: string): string => {
  if (fill) return fill
  if (colorList.some((color) => color === fillType))
    return get(theme.colors, fillType) || theme.colors.black.core
  return theme.colors.white.core
}

export const useFillColor = (theme: DefaultTheme, fillType: FillType, fill?: string): string => {
  const [fillColor, setFillColor] = useState(() => getFillColor(theme, fillType, fill))

  useEffect(() => {
    setFillColor(getFillColor(theme, fillType, fill))
  }, [theme, fillType, fill])

  return fillColor
}
