import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'

import { Board } from './Board'
import { SlidingToggle } from 'components/SlidingToggle/index'

const TABS = {
  DONORS: 'donors',
  OVERALL: 'overall',
  TEAM: 'team',
}

const Container = styled.div`
  @media (max-width: 641px) {
    margin-bottom: 0;
  }
`

const MobileToggle = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${rem(64)};

  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    display: flex;
  }
`

const SingleToggle = styled.button<{ active: boolean }>`
  width: 200px;
  background: #fff;
  border: solid 1px #e21350;
  box-sizing: border-box;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  margin-bottom: 1rem;
  color: #0a0607;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  cursor: pointer;

  ${({ active }) =>
    active &&
    `
    color: #fff;
    background: #E21350;
    `};
`

const ToggleWrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    display: none;
  }
`

interface ILeaderboardInfo {
  amount: TiltifyCurrency
  amountRaised: string
  total: string
  avatar: TiltifyImage
  heat: number
  id: string
  name: string
  url: string
}

interface ICustomLeaderboards {
  individual?: ILeaderboardInfo[]
  team?: ILeaderboardInfo[]
  weekly?: ILeaderboardInfo[]
  donor?: ILeaderboardInfo[]
}

export const Leaderboard = ({
  currency,
  leaderboards,
}: {
  currency: string
  leaderboards?: ICustomLeaderboards
}): JSX.Element | null => {
  const [tab, setTab] = useState(TABS.OVERALL)

  const individualCampaigns =
    leaderboards && leaderboards.individual && leaderboards?.individual?.length > 0
  const teamCampaigns = leaderboards && leaderboards.team && leaderboards?.team?.length > 0
  const donorCampaigns = leaderboards && leaderboards.donor && leaderboards?.donor?.length > 0

  useEffect(() => {
    let defaultTab = TABS.OVERALL
    if (!individualCampaigns && donorCampaigns) {
      defaultTab = TABS.DONORS
    }
    if (!donorCampaigns && !individualCampaigns && teamCampaigns) {
      defaultTab = TABS.TEAM
    }

    setTab(defaultTab)
  }, [individualCampaigns, teamCampaigns, donorCampaigns])

  if (!leaderboards) return null
  if (!individualCampaigns && !teamCampaigns && !donorCampaigns) {
    return null
  }

  const renderLeaderboard = () => {
    switch (tab) {
      case TABS.OVERALL:
        return leaderboards.individual
      case TABS.TEAM:
        return leaderboards.team
      case TABS.DONORS:
        return leaderboards?.donor
      default:
        return []
    }
  }

  const renderTabs = () => {
    const names = []
    if (individualCampaigns) {
      names.push({ key: TABS.OVERALL, title: 'Top fundraisers' })
    }
    if (donorCampaigns) {
      names.push({ key: TABS.DONORS, title: 'Top donors' })
    }
    if (teamCampaigns) {
      names.push({ key: TABS.TEAM, title: 'Top teams' })
    }

    return names
  }

  return (
    <Container>
      <MobileToggle>
        {renderTabs().map((name) => (
          <SingleToggle key={name.key} onClick={() => setTab(name.key)} active={tab === name.key}>
            {name.title}
          </SingleToggle>
        ))}
      </MobileToggle>
      <ToggleWrapper>
        <SlidingToggle current={tab} setCurrent={setTab} names={renderTabs()} toggleWidth={200} />
      </ToggleWrapper>
      <Board currency={currency} campaigns={renderLeaderboard()} linkName={tab !== TABS.DONORS} />
    </Container>
  )
}
