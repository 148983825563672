import { rem } from 'polished'
import styled, { css } from 'styled-components'

export const StyledInputWrapper = styled.div<{ fixedWidth: boolean }>`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  display: inline-block;
  position: relative;
  width: 100%;

  ${({ fixedWidth }) =>
    fixedWidth &&
    css`
      max-width: 360px;
    `}

  textarea {
    resize: none;
    vertical-align: top;
  }
`

export const StyledLabel = styled.label`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2px;

  font-size: ${rem(13)};
  line-height: ${rem(20)};
  letter-spacing: 0.25px;
  color: ${({ theme }) => (theme.isDark ? '#ffffff' : theme.colors.black[600])};
`

export const StyledError = styled.p<{ errorState: boolean; size?: 'small' | 'large' }>`
  width: 100%;
  border: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0px 10px 0px 10px;
  color: ${({ theme }) => theme.colors.error.core};
  border-radius: 0px 0px 4px 4px;
  min-height: 0;
  max-height: 0;
  font-size: ${({ size }) => (size === 'small' ? rem(13) : rem(14))};
  line-height: ${({ size }) => (size === 'small' ? rem(14) : rem(20))};
  letter-spacing: 0.25px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  transition: all 0.2s ease-in-out;
  overflow: hidden;

  ${({ errorState, theme, size }) =>
    errorState
      ? css`
          min-height: ${size === 'small' ? '32px' : '44px'};
          padding-block: 12px;
          box-shadow:
            inset -2px 0px 0px ${theme.colors.error.core},
            inset 2px 0px 0px ${theme.colors.error.core},
            inset 0px -2px 0px ${theme.colors.error.core};
          background: ${({ theme }) => theme.colors.error[50]};
        `
      : ''}
`

export const StyledHelperMessage = styled.p`
  margin-block: ${rem(8)} 0;
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  color: ${({ theme }) => theme.colors.black[500]};
  text-align: left;
  word-break: break-word;
`
