import { ApolloClient, InMemoryCache, gql, useMutation } from '@apollo/client'
import { createLink } from 'apollo-absinthe-upload-link'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

import { Form } from '@tiltify/design/components/Forms/Form'
import { Heading } from '@tiltify/design/components/Heading'
import { Paragraph } from '@tiltify/design/components/Paragraph'
import { authenticationMessages, statusMessages } from '@tiltify/translations'

import { useAuthenticationDispatch } from '../../../contexts/AuthenticationContext'
import { useNotificationContext } from '../../../contexts/NotificationContextProvider'
import { Inputs } from './Inputs'

export const become_fundraiser = gql`
  mutation become_fundraiser(
    $id: ID!
    $firstName: String!
    $lastName: String!
    $username: String
    $avatar: Upload
    $bio: String
    $social: SocialInput
  ) {
    becomeFundraiser(
      id: $id
      avatar: $avatar
      bio: $bio
      social: $social
      lastName: $lastName
      firstName: $firstName
      username: $username
    ) {
      id
      firstName
      lastName
      username
      roles {
        donor
        fundraiser
      }
      profileComplete
      avatar {
        alt
        height
        width
        src
      }
      bio
      social {
        twitter
        discord
        facebook
        website
        snapchat
        instagram
        youtube
        tiktok
        twitch
      }
    }
  }
`

const StyledHeading = styled(Heading)`
  color: ${({ theme }) => theme.colors.black[700]};
`

const CopyWrapper = styled.div`
  margin-bottom: 2rem;
  max-width: 390px;
`

export const Upgrade = ({ user }: { user: AuthenticatedUser | null }) => {
  const { formatMessage } = useIntl()
  const dispatch = useAuthenticationDispatch()
  const { triggerNotification } = useNotificationContext()
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const [redirect_url] = useState(params.get('redirect_url') || '')

  const link = createLink({
    uri: `${process.env.REACT_APP_DASHBOARD_API_URL}/gql`,
    credentials: 'include',
  })

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link,
  })

  const [becomeFundraiser, { loading: mutationLoading }] = useMutation(become_fundraiser, {
    onCompleted(data) {
      dispatch({
        type: useAuthenticationDispatch.ACTION_TYPES.UPDATE_USER,
        item: {
          ...data.becomeFundraiser,
          roles: { fundraiser: true, donor: true },
          profileComplete: true,
        },
      })
      triggerNotification('User updated')
      if (redirect_url) {
        window.location.replace(redirect_url)
      } else {
        navigate('/')
      }
    },
    onError() {
      triggerNotification(formatMessage(statusMessages.error), true)
    },
    client,
  })

  const submit = ({
    bio,
    avatar,
    firstName,
    lastName,
    username,
  }: {
    bio: string
    avatar: string | Blob
    firstName: string
    lastName: string
    username: string
  }) => {
    const variables: {
      id?: string
      bio: string
      avatar?: string | Blob
      firstName: string
      lastName: string
      username: string
    } = {
      id: user?.id,
      bio: bio.trim(),
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      username: username.trim(),
    }

    if (typeof avatar !== 'string') variables.avatar = avatar
    becomeFundraiser({
      variables,
    })
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <StyledHeading type='2'>{formatMessage(authenticationMessages.upgradeHeading)}</StyledHeading>
      <CopyWrapper>
        <Paragraph>{formatMessage(authenticationMessages.upgradePOne)}</Paragraph>
        <Paragraph>{formatMessage(authenticationMessages.upgradePTwo)}</Paragraph>
      </CopyWrapper>
      <Form
        initialValues={{
          avatar: '',
          bio: formatMessage(authenticationMessages.defaultBio),
          username: user?.username,
          firstName: user?.firstName,
          lastName: user?.lastName,
        }}
        onSubmit={submit}
        resetOnSubmit={false}
      >
        <Inputs loading={mutationLoading} />
      </Form>
    </div>
  )
}
