import { forwardRef } from 'react'
import styled from 'styled-components'

import { Button } from '@tiltify/ui/components/Button'

import { useAuthenticationState } from '../../../../../../contexts/AuthenticationContext'
import { FreshdeskWidget } from '../../../../../Freshdesk'

const StyledFreshdeskWidget = styled(FreshdeskWidget)`
  padding: 0.625rem 1.0625rem;
  font-weight: normal !important;
  background: transparent;
  border: none;
  display: flex;
  width: 100%;
  height: 100%;

  color: ${({ theme }) => theme.textColor} !important;

  &:hover {
    color: ${({ theme }) => theme.textColor} !important;
    cursor: pointer;
  }
`

const StyledButton = styled(Button)`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0;

  div,
  span {
    display: flex;
    width: 100%;
    height: 100%;
  }
  font-size: 0.8125rem !important;
`

export const Freshdesk = forwardRef(({ toggleMenu, campaign, withContext }: any, ref) => {
  const { authenticatedUser: user } = useAuthenticationState()
  const autofill = { email: user?.email || '', custom_fields: { cf_campaign: campaign?.name } }

  if (withContext) {
    return <FreshdeskWithContext toggleMenu={toggleMenu} autofill={autofill} ref={ref} />
  }
  return (
    <StyledButton as='div'>
      <StyledFreshdeskWidget autofill={autofill} ref={ref}>
        help
      </StyledFreshdeskWidget>
    </StyledButton>
  )
})

export const FreshdeskWithContext = forwardRef(({ toggleMenu, autofill }: any, ref) => {
  return (
    <StyledButton as='div'>
      <StyledFreshdeskWidget close={() => toggleMenu()} autofill={autofill} ref={ref}>
        help{' '}
      </StyledFreshdeskWidget>
    </StyledButton>
  )
})

Freshdesk.displayName = 'Freshdesk'
FreshdeskWithContext.displayName = 'FreshdeskWithContext'

Freshdesk.defaultProps = {
  withContext: false,
}
