import styled from 'styled-components'

import { Form } from '@tiltify/design/components/Forms/Form'
import { Heading } from '@tiltify/design/components/Heading'

import { FormFields } from './FormFields'

const StyledHeading = styled(Heading)`
  color: ${({ theme }) => theme.colors.black[700]};
  margin-bottom: 24px;
`

interface ICompleteProfile {
  user: AuthenticatedUser | null
  onComplete: (values: {
    email: string
    firstName: string
    lastName: string
    username: string
  }) => void
  errors: any
}

export const CompleteProfile = ({ onComplete, errors, user }: ICompleteProfile) => {
  const onSubmit = (values: {
    email: string
    firstName: string
    lastName: string
    username: string
  }) => {
    onComplete(values)
  }

  return (
    <>
      <StyledHeading type='2'>Just a last few items...</StyledHeading>
      <Form
        initialValues={{
          email: user?.email || '',
          firstName: user?.firstName || '',
          lastName: user?.lastName || '',
          username: user?.username || '',
        }}
        onSubmit={onSubmit}
        resetOnSubmit={false}
      >
        <FormFields errors={errors} />
      </Form>
    </>
  )
}
