import { rem } from 'polished'
import styled, { css } from 'styled-components'

import { Tiltify } from '@tiltify/design/icons/Tiltify'
import { baseTiltifyUrl, getSubdomainUrl } from '@tiltify/ui/utils/tiltifyUrl'

import { AuthOptions } from './AuthOptions'
import { NavItem } from './NavItem'
import { UserMenu } from './UserMenu'

const StyledNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-block: ${rem(5)};
  box-sizing: border-box;
  min-height: 56px;
  max-width: ${({ theme }) => theme.contentMaxWidth};
  margin: auto;

  ${({ theme }) => theme.isDark && `background: ${theme.colors.black[800]};`} & > * {
    min-width: 100px;
  }

  @media (max-width: 1142px) {
    padding-inline: 1rem;
  }
`

const StyledLogoLink = styled.a<{ hasLogo: boolean }>`
  display: flex;
  padding-left: 0.75rem;

  ${({ hasLogo, theme }) =>
    !hasLogo &&
    css`
      & > svg path {
        fill: ${theme.isDark ? theme.colors.white.core : theme.colors.black.core};
      }
    `}
`

const StyledNavChildren = styled.nav`
  display: flex;

  @media ${({ theme }) => `(max-width: ${theme.breakpoints[1]})`} {
    display: none;
  }
`

const StyledSubMenu = styled.nav`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const StyledMainNav = styled.ul`
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;

  & > li:nth-of-type(2) {
    margin: 0 2rem;

    @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
      margin: 0 1rem;
    }
  }
`

const url = baseTiltifyUrl()
const causeUrl = `${getSubdomainUrl(`${url}`, 'causes')}`

const renderNavItems = () => {
  return (
    <>
      <NavItem text='Explore' url={`${url}/explore`} />
      <NavItem text='Support Center' url='https://info.tiltify.com/' />
      <NavItem text='Tiltify for Causes' url={causeUrl} />
    </>
  )
}

export const Nav = ({
  withNavItems = true,
  withAuthentication = true,
  logo,
  className,
}: {
  withNavItems?: boolean
  withAuthentication?: boolean
  logo?: any
  className?: string
}): JSX.Element => {
  return (
    <header id='gtm_header' className={className}>
      <StyledNav>
        <StyledLogoLink hasLogo={!!logo} href={url} aria-label='Home'>
          {logo ? logo : <Tiltify title='Home' height={24} />}
        </StyledLogoLink>
        {withNavItems && (
          <StyledNavChildren>
            <StyledMainNav>{renderNavItems()}</StyledMainNav>
          </StyledNavChildren>
        )}
        {withAuthentication && (
          <StyledSubMenu id='gtm_nav'>
            <UserMenu
              childrenLeft={renderNavItems()}
              authButtons={<AuthOptions />}
              navType='frontend'
            />
          </StyledSubMenu>
        )}
      </StyledNav>
    </header>
  )
}
