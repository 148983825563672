import styled from 'styled-components'
import { rem } from 'polished'

const Line = styled.hr`
  all: unset;
  display: block;
  border-top: 1px solid ${({ theme }) => theme.colors.black[100]};
  height: 1px;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: ${rem(36)};
  margin-top: 0;
`

export const Divider = ({ className }: { className?: string }) => {
  return <Line className={className} />
}
