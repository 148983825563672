import { rem } from 'polished'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { Button } from '@tiltify/design/components/Button'
import { Divider } from '@tiltify/design/components/Divider'
import { EmailInput } from '@tiltify/design/components/Forms/EmailInput'
import { Form } from '@tiltify/design/components/Forms/Form'
import { Heading } from '@tiltify/design/components/Heading'
import { Paragraph } from '@tiltify/design/components/Paragraph'
import { inputMessages, statusMessages } from '@tiltify/translations'

import { baseTiltifyUrl } from '../../../utils/tiltifyUrl'
import { Social } from '../Social'

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.blue.core};
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(18)};
  align-items: flex-start;

  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    align-items: center;
  }
`

const StyledHeading = styled(Heading)<{ withMargin?: boolean }>`
  margin-block: 24px;
  color: ${({ theme }) => theme.colors.black[700]};
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const StyledDivider = styled(Divider)`
  margin-top: 36px;
  margin-bottom: 24px;
`

interface IUserSignin {
  sendOTP: ({ email }: { email: string }) => void
  submitting: boolean
  handleRedirect: () => { type: string; value: string }
  openChild: (url: string) => void
  hideHeading: boolean
}

export const SigninForm = ({
  sendOTP,
  submitting,
  handleRedirect,
  openChild,
  hideHeading,
}: IUserSignin) => {
  const { formatMessage } = useIntl()

  return (
    <div id='gtm_login'>
      {!hideHeading && (
        <div style={{ marginBottom: '24px' }}>
          <Heading type='2'>Sign in or create an account</Heading>
          <Paragraph>Causes, fundraisers, and donors, get started here!</Paragraph>
        </div>
      )}
      <Form onSubmit={sendOTP} initialValues={{ email: '' }} resetOnSubmit={true}>
        <InputWrapper>
          <EmailInput
            required
            name='email'
            label={formatMessage(inputMessages.email)}
            placeholder={formatMessage(inputMessages.emailPlaceholder)}
            focusOnLoad
          />
        </InputWrapper>

        <StyledHeading type='3'>or sign in with</StyledHeading>
        <Social token={handleRedirect} openChild={openChild} />
        <Paragraph size='small'>
          By continuing, you agree to our{' '}
          <StyledLink href={`${baseTiltifyUrl()}/pages/terms-of-service`} target='_blank'>
            Terms of Service
          </StyledLink>{' '}
          and{' '}
          <StyledLink href={`${baseTiltifyUrl()}/pages/privacy-policy`} target='_blank'>
            Privacy Policy
          </StyledLink>
        </Paragraph>
        <StyledDivider />
        <ButtonWrapper>
          <Button
            text={submitting ? formatMessage(statusMessages.submitting) : 'Continue'}
            type='submit'
            size='medium'
            disabled={submitting}
            icon={submitting ? undefined : 'arrow-right'}
          />
        </ButtonWrapper>
      </Form>
    </div>
  )
}
