import styled from 'styled-components'

const StyledIframe = styled.iframe`
  width: 100%;
`

export const IframeEmbed = ({ src, height, ...rest }: any) => {
  return (
    <StyledIframe
      src={src}
      height={height}
      allowFullScreen
      frameBorder='0'
      scrolling='no'
      {...rest}
    />
  )
}
