import { useTheme } from 'styled-components'

import { useFillColor, useSize } from '../IconHooks'

export const Bank = ({
  fill,
  fillType = 'black.50',
  size = 'small',
  height,
  width,
  title,
}: TiltifyIcon) => {
  const theme = useTheme()
  const sizeValues = useSize(size, height, width)
  const fillValue = useFillColor(theme, fillType, fill)
  return (
    <svg
      width={sizeValues.width}
      height={sizeValues.height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>{title}</title>
      <path
        d='M13.9112 6.28584C13.9925 6.28586 14.073 6.26986 14.1482 6.23876C14.2233 6.20766 14.2915 6.16206 14.349 6.10457C14.4065 6.04708 14.4521 5.97883 14.4832 5.90371C14.5143 5.82859 14.5303 5.74808 14.5303 5.66678V3.80959C14.5303 3.6797 14.4895 3.55309 14.4135 3.44772C14.3376 3.34235 14.2304 3.26358 14.1071 3.22257L8.53555 1.36539C8.40839 1.32307 8.27095 1.32307 8.14379 1.36539L2.57223 3.22257C2.44898 3.26358 2.34177 3.34235 2.26581 3.44772C2.18985 3.55309 2.14899 3.6797 2.14905 3.80959V5.66678C2.14903 5.74808 2.16502 5.82859 2.19613 5.90371C2.22723 5.97883 2.27283 6.04708 2.33032 6.10457C2.38781 6.16206 2.45606 6.20766 2.53118 6.23876C2.6063 6.26986 2.68681 6.28586 2.76811 6.28584H3.38717V10.7334C3.0261 10.8605 2.71324 11.0964 2.49155 11.4085C2.26987 11.7206 2.15023 12.0936 2.14905 12.4765V13.7146C2.14903 13.7959 2.16502 13.8764 2.19613 13.9515C2.22723 14.0266 2.27283 14.0949 2.33032 14.1524C2.38781 14.2099 2.45606 14.2555 2.53118 14.2866C2.6063 14.3177 2.68681 14.3337 2.76811 14.3336H13.9112C13.9925 14.3337 14.073 14.3177 14.1482 14.2866C14.2233 14.2555 14.2915 14.2099 14.349 14.1524C14.4065 14.0949 14.4521 14.0266 14.4832 13.9515C14.5143 13.8764 14.5303 13.7959 14.5303 13.7146V12.4765C14.5291 12.0936 14.4095 11.7206 14.1878 11.4085C13.9661 11.0964 13.6532 10.8605 13.2922 10.7334V6.28584H13.9112ZM13.2922 13.0955H3.38717V12.4765C3.38734 12.3123 3.45261 12.155 3.56867 12.0389C3.68473 11.9228 3.8421 11.8576 4.00623 11.8574H12.6731C12.8372 11.8576 12.9946 11.9228 13.1107 12.0389C13.2267 12.155 13.292 12.3123 13.2922 12.4765V13.0955ZM4.6253 10.6193V6.28584H5.86342V10.6193H4.6253ZM7.10154 10.6193V6.28584H9.57779V10.6193H7.10154ZM10.8159 10.6193V6.28584H12.054V10.6193H10.8159ZM3.38717 5.04772V4.25575L8.33967 2.60471L13.2922 4.25575V5.04772H3.38717Z'
        fill={fillValue}
      />
    </svg>
  )
}
