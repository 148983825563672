import { rem } from 'polished'
import styled from 'styled-components'

import border from 'assets/greyborder.png'

// import { useRouteContext } from 'contexts/RouteContext'
import {
  AccentBar,
  /* BlackButton, */
  Heading,
  MaxWidthWrapper,
  RedButton,
} from '../styles'

const StyledHeading = styled(Heading)`
  text-align: left;
  margin-top: 3rem;
`

const StyledAccentBar = styled(AccentBar)`
  margin: 0 0 ${rem(19)} 0;
`

const SubHeading = styled.p`
  font-size: ${rem(20)};
  line-height: 32px;
  color: #333;
  max-width: 830px;
  margin: 0 auto;
  margin-bottom: 2rem;
`

const BackgroundWrapper = styled.div`
  background: #fff;
`

const BorderImage = styled.div`
  background: url(${border});
  height: 25px;
  width: 100%;
  background-position: center;
`
export const ButtonContainer = styled.div`
  margin-bottom: ${rem(46)};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  button,
  a {
    min-width: 272px;
    box-sizing: border-box;
  }

  @media (max-width: 644px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const PinkButton = styled(RedButton)`
  background: transparent;
  color: ${({ theme }) => theme.pink};
  border: 2px solid ${({ theme }) => theme.pink};
  margin-right: ${rem(24)};
  box-sizing: border-box;
  text-align: center;

  @media (max-width: 644px) {
    margin-right: 0;
    margin-bottom: 1rem;
  }
`

const SolidPinkButton = styled(PinkButton)`
  background: ${({ theme }) => theme.pink};
  color: #fff;
`

const FAQ = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${rem(64 - 16)};
`

const FAQLink = styled.a`
  color: ${({ theme }) => theme.pink};
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 1px;
  text-decoration-line: underline;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  && div:nth-child(1) {
    margin-right: ${rem(33)};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    flex-direction: column;
    && div:nth-child(1) {
      margin-right: 0;
    }
  }
`

export const StartFundraising = (): JSX.Element => {
  // const { fundraisingEvent } = useRouteContext()
  return (
    <>
      <BackgroundWrapper>
        <BorderImage />
        <MaxWidthWrapper>
          <StyledHeading>Jingle Jam - Get involved with fundraising!</StyledHeading>
          <StyledAccentBar />
          <Wrapper>
            <div>
              <SubHeading>
                Get ready for Jingle Jam 2023! Jingle Jam is the world&apos;s biggest charity gaming
                event, set to unite the entire gaming community for an epic experience. From
                December 1st to December 14th, fundraise for 12 fantastic charities by streaming,
                creating content, and beyond.
              </SubHeading>

              <SubHeading>
                This year, everyone has the opportunity to become a Jingle Jam host. Create your own
                page, customize it with your content, choose one of our charity partners (or raise
                money for them all simultaneously), and include the exclusive Jingle Jam 2023 Games
                Collection as a reward for contributors.
              </SubHeading>
            </div>
            <div>
              <SubHeading>
                Those who donate at least £35 to your page and select the reward will be eligible to
                receive the Games Collection. The 2023 collection is nothing short of amazing—and
                until December 1st, it&apos;s a closely guarded secret! It will include a vast
                number of full games, with a combined value of about £1,000 (approximately $1,200).
              </SubHeading>
              <SubHeading>
                Once again, Jingle Jam 2023 is an extraordinary event that brings together the
                entire gaming community each year. Don&apos;t miss out on this incredible
                opportunity to get involved by watching, donating, or becoming a Jingle Jam host and
                taking part in the excitement!
              </SubHeading>
            </div>
          </Wrapper>
          <ButtonContainer>
            {/* {fundraisingEvent?.supportable && (
              <BlackButton
                forwardedAs='a'
                href='https://tiltify.com/jingle-jam/jingle-jam-2023/start'
                style={{ margin: 0, display: 'flex', justifyContent: 'center' }}
                disabled={!fundraisingEvent.supportable}
              >
                START FUNDRAISING
              </BlackButton>
            )} */}
            <PinkButton
              as='a'
              href='https://www.jinglejam.co.uk/toolkit-download'
              target='blank'
              rel='noopener noreferrer'
              style={{ margin: 0 }}
            >
              DOWNLOAD THE TOOLKIT
            </PinkButton>
            <SolidPinkButton
              as='a'
              href='https://discord.gg/dsCsJJcvAx'
              target='blank'
              rel='noopener noreferrer'
              style={{ margin: 0 }}
            >
              JOIN THE DISCORD SERVER
            </SolidPinkButton>
          </ButtonContainer>
          <FAQ>
            <FAQLink
              href='https://info.tiltify.com/support/solutions/articles/43000646877-jingle-jam-faqs'
              target='_blank'
              rel='noopener noreferrer'
            >
              JINGLE JAM FAQ
            </FAQLink>
          </FAQ>
        </MaxWidthWrapper>
        <BorderImage />
      </BackgroundWrapper>
    </>
  )
}
