import { readableColor } from 'polished'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'

import { Button } from '@tiltify/ui/components/Button'
import { LanguageSelect } from '@tiltify/ui/components/LanguageSelect'
import { ThemeToggle } from '@tiltify/ui/components/ThemeToggle'
import { Close } from '@tiltify/ui/icons/Close'

import { AuthOptions } from '../AuthOptions'
import {
  FreshdeskWithContext,
  FrontendUserDashboard,
  FrontendUserEditCampaign,
  FrontendUserProfile,
  SignOut,
} from './UserMenuActions'
import { UserMenuItem } from './UserMenuItem'

const StyledUserMenu = styled.div`
  position: absolute;
  z-index: 999;
  top: 43px;
  right: 0;

  background-color: ${({ theme }) => theme.backgroundAccent};
  color: ${({ theme }) => readableColor(theme.backgroundAccent)};
  box-shadow: 0px 4px 12px #171718;
  border-radius: 5px;
  width: 178px;

  @media ${({ theme }) => `(max-width: ${theme.breakpoints[1]})`} {
    display: none;
  }
`

const StyledList = styled.ul`
  list-style-type: none;
  padding: 0;
  -webkit-margin-after: 0;
  -webkit-margin-before: 0;
  -webkit-padding-start: 0;
`

const StyledMobileWrapper = styled.div`
  position: fixed;
  overflow-y: auto;
  height: 100vh;
  background-color: ${({ theme }) => theme.backgroundAccent};
  top: 4px;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  padding-top: 1rem;

  @media ${({ theme }) => `(min-width: ${theme.breakpoints[1]})`} {
    display: none;
  }
`

const StyledTopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 0.875rem;
  padding-right: 0.875rem;
  height: 50px;
`

const StyledHamburgerWrapper = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 24px;
    width: 24px;
    g {
      stroke: ${({ theme }) => readableColor(theme.backgroundAccent)};
    }
  }
`

const StyledMobileChildren = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.5rem;

  & button {
    width: fit-content;
  }
`

const StyledThemeToggle = styled(ThemeToggle)`
  border: none;
  background-color: transparent;
  margin-left: 0.25rem;
  font-size: 0.8125rem;
  width: 100%;

  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 140px;
    height: 1px;
    background: ${({ theme }) => theme.textColor};
    top: 0;
  }
`

const StyledLanguageSelect = styled(LanguageSelect)<{ showTheme: boolean }>`
  margin-left: 1rem;

  ${({ showTheme, theme }) =>
    !showTheme
      ? `
    &::before {
    content: '';
    position: absolute;
    width: 140px;
    height: 1px;
    background: ${theme.textColor};
    top: 0;
  }
  `
      : ''}
`

export const UserMenuList = ({
  childrenLeft,
  childrenRight,
  authButtons,
  loginMenu,
  navType,
  toggleMenu,
  user,
  userMenuData,
}: any) => {
  const ref = useRef()
  const [activeIndex, setActiveIndex] = useState(0)

  const RENDER_MENU = useRef({
    ['frontend']: {
      nodes: [
        {
          component: FrontendUserProfile,
          isVisible: (user: any) => !!user?.roles?.fundraiser,
        },
        { component: FrontendUserDashboard, isVisible: () => true },
        {
          component: FrontendUserEditCampaign,
          isVisible: (user: any, userMenuData: any) =>
            user && userMenuData?.campaign && userMenuData?.campaign?.user?.id === user?.id,
        },
        { component: FreshdeskWithContext, isVisible: () => true },
        { component: SignOut, isVisible: () => true },
        {
          component: StyledThemeToggle,
          isVisible: (user: any, userMenuData: any) => !!userMenuData?.showTheme,
        },
        {
          component: StyledLanguageSelect,
          isVisible: (user: any, userMenuData: any) => !!userMenuData?.showLanguage,
        },
      ],
    },
    ['cause']: {
      nodes: [{ component: SignOut, isVisible: () => true }],
    },
    ['admin']: {
      nodes: [{ component: SignOut, isVisible: () => true }],
    },
  })
  // @ts-expect-error ignore old crap
  const renderList = RENDER_MENU.current[navType].nodes.filter((node) =>
    node.isVisible(user, userMenuData)
  )

  const handleEvent = (event: any) => {
    if (event.key === 'Escape') {
      toggleMenu()
    } else if (event.key === 'ArrowDown') {
      event.preventDefault()
      const nextIndex = activeIndex + 1
      if (nextIndex < renderList.length) {
        setActiveIndex(nextIndex)
      }
    } else if (event.key === 'ArrowUp') {
      event.preventDefault()
      const nextIndex = activeIndex - 1
      if (nextIndex !== -1) {
        setActiveIndex(nextIndex)
      }
    } else if (event.key === 'Tab') {
      event.preventDefault()
      const nextIndex = activeIndex + 1
      if (nextIndex < renderList.length) {
        setActiveIndex(nextIndex)
      }
    }
  }

  return (
    <>
      {user ? (
        // @ts-expect-error TS(2769): No overload matches this call.
        <StyledUserMenu ref={ref}>
          <StyledList onKeyDown={handleEvent}>
            {renderList.map((node: any, index: any) => (
              <UserMenuItem
                key={`user-menu-full-${navType}-${index}`}
                active={activeIndex === index}
                index={index}
                node={node.component}
                setActiveIndex={setActiveIndex}
                userMenuData={userMenuData}
              />
            ))}
          </StyledList>
        </StyledUserMenu>
      ) : null}
      <StyledMobileWrapper>
        <nav>
          <StyledTopBar>
            <StyledHamburgerWrapper onClick={toggleMenu}>
              <Close stroke={({ theme }: any) => readableColor(theme.backgroundColor)} />
            </StyledHamburgerWrapper>
            {!user && loginMenu ? authButtons || <AuthOptions /> : null}
          </StyledTopBar>
          <StyledMobileChildren>
            {childrenLeft}
            {childrenRight}
            {user
              ? renderList.map((node: any, index: any) =>
                  React.createElement(node.component, {
                    key: `user-menu-mobile-${navType}-${index}`,
                    ...userMenuData,
                  })
                )
              : null}
          </StyledMobileChildren>
        </nav>
      </StyledMobileWrapper>
    </>
  )
}
