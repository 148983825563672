// import { BlackButton as ScheduleButton } from 'components/styles'

export const Schedule = (): JSX.Element | null => {
  return null
  // return (
  //   <ScheduleButton
  //     forwardedAs='a'
  //     as='a'
  //     // href='https://www.jinglejam.co.uk/'
  //     href='https://tiltify.com/jingle-jam/jingle-jam-2023/start?regionId=566'
  //     target='_blank'
  //     // @ts-expect-error ignore
  //     size='medium'
  //     rel='noopener noreferrer'
  //   >
  //     START FUNDRAISING
  //   </ScheduleButton>
  // )
}
