import styled from 'styled-components'

const SocialLink = styled.button`
  background: transparent;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  border: solid ${({ theme }) => theme.colors.black[200]} 1px;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.black[700]};
  height: 48px;
  width: 48px;
  cursor: pointer;
  ${({ theme }) => theme.transition[0]}

  &:hover {
    box-shadow: ${({ theme }) => theme.depth[4]};
  }
`
export const SocialButton = ({
  link,
  openChild,
  icon,
}: {
  link: string
  openChild: (link: string) => void
  icon: JSX.Element
}) => {
  return (
    <SocialLink type='button' onClick={() => (openChild ? openChild(link) : null)}>
      {icon}
    </SocialLink>
  )
}
