import { useTheme } from 'styled-components'

import { useFillColor, useSize } from './IconHooks'

export const CaretLeft = ({
  fill,
  fillType = 'black.50',
  size = 'small',
  height,
  width,
  title = 'Left',
}: TiltifyIcon) => {
  const theme = useTheme()
  const sizeValues = useSize(size, height, width)
  const fillValue = useFillColor(theme, fillType, fill)

  return (
    <svg
      width={sizeValues.width}
      height={sizeValues.height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>{title}</title>
      <path
        d='M7.4158 8.2458L10.9558 4.7058C11.142 4.51844 11.2466 4.26498 11.2466 4.0008C11.2466 3.73661 11.142 3.48316 10.9558 3.2958C10.8628 3.20207 10.7522 3.12768 10.6304 3.07691C10.5085 3.02614 10.3778 3 10.2458 3C10.1138 3 9.98308 3.02614 9.86122 3.07691C9.73936 3.12768 9.62876 3.20207 9.5358 3.2958L5.2958 7.5358C5.20207 7.62876 5.12768 7.73936 5.07691 7.86122C5.02614 7.98308 5 8.11379 5 8.2458C5 8.37781 5.02614 8.50852 5.07691 8.63037C5.12768 8.75223 5.20207 8.86283 5.2958 8.9558L9.5358 13.2458C9.62924 13.3385 9.74005 13.4118 9.86189 13.4616C9.98373 13.5113 10.1142 13.5366 10.2458 13.5358C10.3774 13.5366 10.5079 13.5113 10.6297 13.4616C10.7515 13.4118 10.8624 13.3385 10.9558 13.2458C11.142 13.0584 11.2466 12.805 11.2466 12.5408C11.2466 12.2766 11.142 12.0232 10.9558 11.8358L7.4158 8.2458Z'
        fill={fillValue}
      />
    </svg>
  )
}
