import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import styled, { css } from 'styled-components'

type SizeType = 'small' | 'default' | 'info' | 'link'

const StyledParagraph = styled.p<{ $size: SizeType }>`
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  margin: 0;
  color: ${({ theme }) => (theme.isDark ? theme.colors.black[200] : theme.colors.black[600])};
  max-width: 65ch;

  ${({ $size }) => {
    switch ($size) {
      case 'small':
        return css`
          font-size: 0.875rem;
          letter-spacing: 0px;
          line-height: 1.25rem;
        `
      case 'link':
        return css`
          font-size: 0.875rem;
          letter-spacing: 0px;
          line-height: 1.25rem;
          color: ${({ theme }) => theme.colors.blue.core};
          &:visited {
            color: ${({ theme }) => theme.colors.blue.core};
          }
        `
      case 'info':
        return css`
          font-size: 11px;
          line-height: 16px;
          letter-spacing: 0.25px;
        `
      default:
        return css`
          font-size: 1rem;
          letter-spacing: 0.25px;
          line-height: 1.5rem;
        `
    }
  }}
`

interface ParagraphProps {
  children?: React.ReactNode
  size?: SizeType
  className?: string
  dangerouslySetInnerHTML?: { __html: string }
  title?: string
}

export const Paragraph = forwardRef(
  (
    { children, size = 'default', className, dangerouslySetInnerHTML, title }: ParagraphProps,
    ref
  ) => {
    const localRef = useRef(null)
    useImperativeHandle(ref, () => localRef.current)

    return (
      <StyledParagraph
        dangerouslySetInnerHTML={dangerouslySetInnerHTML}
        className={className}
        title={title}
        ref={localRef}
        $size={size}
      >
        {children}
      </StyledParagraph>
    )
  }
)

Paragraph.displayName = 'Paragraph'
