import { Helmet as ReactHelmet, HelmetProvider } from 'react-helmet-async'

import { tiltifyUrl } from '../../utils/tiltifyUrl'

const DOMAIN = 'https://tiltify.com'
export function Helmet({
  title = 'Tiltify - Made for Fundraisers',
  description = 'The #1 platform for livestream and digital fundraising',
  image,
}: any) {
  const url = tiltifyUrl(`${DOMAIN}${window.location.pathname}`)

  return (
    <HelmetProvider>
      <ReactHelmet>
        <html lang='en' />
        <title>{title}</title>
        <meta name='description' content={description} />
        <link rel='canonical' href={url} />
        <meta
          name='keywords'
          content='Charity,Livestreaming,Tiltify,fundraising,twitch,video games'
        />

        <meta property='og:title' content={title} />
        <meta property='og:description' content={description} />
        <meta property='og:url' content={url} />
        <meta property='og:site_name' content='Tiltify' />
        <meta property='og:local' content='en_US' />
        <meta property='og:image' content={image || `${process.env.REACT_APP_SHARE_URL}/default`} />

        <meta property='twitter:title' content={title} />
        <meta property='twitter:description' content={description} />
        <meta property='twitter:card' content='summary_large_image' />
        <meta property='twitter:site' content='@Tiltify' />
        <meta property='twitter:site:id' content='396852588' />
        <meta property='twitter:domain' content={DOMAIN} />
        <meta
          property='twitter:image'
          content={image || `${process.env.REACT_APP_SHARE_URL}/default`}
        />

        <link
          rel='shortcut icon'
          href='https://assets.tiltify.com/favicon.ico'
          type='image/x-icon'
        />
        <link
          rel='apple-touch-icon-precomposed'
          sizes='57x57'
          href='https://assets.tiltify.com/favicons/apple-touch-icon-57x57.png'
        />
        <link
          rel='apple-touch-icon-precomposed'
          sizes='114x114'
          href='https://assets.tiltify.com/favicons/apple-touch-icon-114x114.png'
        />
        <link
          rel='apple-touch-icon-precomposed'
          sizes='72x72'
          href='https://assets.tiltify.com/favicons/apple-touch-icon-72x72.png'
        />
        <link
          rel='apple-touch-icon-precomposed'
          sizes='144x144'
          href='https://assets.tiltify.com/favicons/apple-touch-icon-144x144.png'
        />
        <link
          rel='apple-touch-icon-precomposed'
          sizes='60x60'
          href='https://assets.tiltify.com/favicons/apple-touch-icon-60x60.png'
        />
        <link
          rel='apple-touch-icon-precomposed'
          sizes='120x120'
          href='https://assets.tiltify.com/favicons/apple-touch-icon-120x120.png'
        />
        <link
          rel='apple-touch-icon-precomposed'
          sizes='76x76'
          href='https://assets.tiltify.com/favicons/apple-touch-icon-76x76.png'
        />
        <link
          rel='apple-touch-icon-precomposed'
          sizes='152x152'
          href='https://assets.tiltify.com/favicons/apple-touch-icon-152x152.png'
        />
        <link
          rel='apple-touch-icon-precomposed'
          sizes='120x120'
          href='https://assets.tiltify.com/favicons/apple-touch-icon-180x180.png'
        />
        <link
          rel='apple-touch-icon-precomposed'
          href='https://assets.tiltify.com/favicons/apple-touch-icon.png'
        />

        <link
          rel='icon'
          type='image/png'
          href='https://assets.tiltify.com/favicons/favicon-256x256.png'
          sizes='256x256'
        />
        <link
          rel='icon'
          type='image/png'
          href='https://assets.tiltify.com/favicons/favicon-128x128.png'
          sizes='128x128'
        />
        <link
          rel='icon'
          type='image/png'
          href='https://assets.tiltify.com/favicons/favicon-96x96.png'
          sizes='96x96'
        />
        <link
          rel='icon'
          type='image/png'
          href='https://assets.tiltify.com/favicons/favicon-64x64.png'
          sizes='64x64'
        />
        <link
          rel='icon'
          type='image/png'
          href='https://assets.tiltify.com/favicons/favicon-48x48.png'
          sizes='48x48'
        />
        <link
          rel='icon'
          type='image/png'
          href='https://assets.tiltify.com/favicons/favicon-32x32.png'
          sizes='32x32'
        />
        <link
          rel='icon'
          type='image/png'
          href='https://assets.tiltify.com/favicons/favicon-16x16.png'
          sizes='16x16'
        />

        <link rel='manifest' href='https://assets.tiltify.com/site.webmanifest' />
        <link
          rel='mask-icon'
          href='https://assets.tiltify.com/safari-pinned-tab.svg'
          color='#5bbad5'
        />

        <meta name='msapplication-config' content='/iconx/browserconfig.xml' />
      </ReactHelmet>
    </HelmetProvider>
  )
}
