import { useEffect } from 'react'

import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
`

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  span,
  iframe {
    max-height: 100%;
  }
`

export const Facebook = ({ channel }: any) => {
  useEffect(() => {
    // @ts-expect-error TS(2339): Property 'FB' does not exist on type 'Window & typ... Remove this comment to see the full error message
    if (window.FB) {
      // @ts-expect-error TS(2339): Property 'FB' does not exist on type 'Window & typ... Remove this comment to see the full error message
      window.FB.XFBML.parse()
    }
    // @ts-expect-error TS(2339): Property 'FB' does not exist on type 'Window & typ... Remove this comment to see the full error message
  }, [window.FB])

  useEffect(() => {
    const el = document.createElement('script')
    el.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2'
    document.body.prepend(el)
  }, [])

  if (!channel) return null

  return (
    <Container data-testid='facebook'>
      <Wrapper>
        <div id='fb-root' />
        <div
          className='fb-video'
          data-href={channel}
          data-allowfullscreen='true'
          data-width='auto'
        />
      </Wrapper>
    </Container>
  )
}
