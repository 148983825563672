import { useTheme } from 'styled-components'

import { useFillColor, useSize } from './IconHooks'

export const Upload = ({
  fill,
  fillType = 'black.50',
  size = 'small',
  height,
  width,
  title = 'Upload',
}: TiltifyIcon) => {
  const theme = useTheme()
  const sizeValues = useSize(size, height, width)
  const fillValue = useFillColor(theme, fillType, fill)

  return (
    <svg
      width={sizeValues.width}
      height={sizeValues.height}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 15 14'
    >
      <title>{title}</title>
      <path
        fill={fillValue}
        fillRule='evenodd'
        d='M3.695 4.805a.667.667 0 0 1 0-.943L7.03.529c.26-.26.682-.26.942 0l3.334 3.333a.667.667 0 1 1-.943.943L7.5 1.943 4.638 4.805a.667.667 0 0 1-.943 0Z'
        clipRule='evenodd'
      />
      <path
        fill={fillValue}
        fillRule='evenodd'
        d='M7.5 9.667A.667.667 0 0 1 6.833 9V1a.667.667 0 0 1 1.334 0v8a.667.667 0 0 1-.667.667Z'
        clipRule='evenodd'
      />
      <path
        fill={fillValue}
        fillRule='evenodd'
        d='M2.167 11.667a.667.667 0 0 0 .666.666h9.334a.667.667 0 0 0 .666-.666V9a.667.667 0 0 1 1.334 0v2.667a2 2 0 0 1-2 2H2.833a2 2 0 0 1-2-2V9a.667.667 0 1 1 1.334 0v2.667Z'
        clipRule='evenodd'
      />
    </svg>
  )
}
