import { gql } from '@apollo/client'

export const get_campaigns_by_fundraising_event_id = gql`
  query get_campaigns_by_fundraising_event_id(
    $publicId: String!
    $limit: Int!
    $query: String
    $offset: Int
    $regionId: Int
  ) {
    fundraisingEvent(publicId: $publicId) {
      publishedCampaigns(limit: $limit, offset: $offset, query: $query, regionId: $regionId) {
        pagination {
          hasNextPage
          limit
          offset
          total
        }
        edges {
          cursor
          node {
            ... on Campaign {
              publicId
              name
              slug
              live
              cause {
                name
                avatar {
                  src
                }
              }
              user {
                id
                username
                slug
                avatar {
                  src
                  alt
                }
              }
              avatar {
                src
                alt
              }
              totalAmountRaised {
                value
                currency
              }
              goal {
                value
                currency
              }
              cardImage {
                src
                alt
              }
            }
            ... on TeamEvent {
              publicId
              name
              slug
              currentSlug
              live
              cause {
                name
                avatar {
                  src
                }
              }
              team {
                id
                name
                slug
                avatar {
                  src
                  alt
                }
              }
              avatar {
                src
                alt
              }
              totalAmountRaised {
                value
                currency
              }
              goal {
                value
                currency
              }
              cardImage {
                src
                alt
              }
            }
          }
        }
      }
    }
  }
`
