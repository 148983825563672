import { FormattedNumber } from 'react-intl'

const modifyProps = (props: {
  currency?: string
  minimumFractionDigits?: number
  maximumFractionDigits?: number
  style?: 'decimal' | 'percent' | 'currency' | 'unit'
  value: number
}) => {
  switch (props.currency) {
    case 'JPY':
      return { ...props, ...{ minimumFractionDigits: 0, maximumFractionDigits: 0 } }
    default:
      return props
  }
}

interface ICurrency {
  currency?: string
  minimumFractionDigits?: number
  maximumFractionDigits?: number
  style?: 'decimal' | 'percent' | 'currency' | 'unit'
  value: string
}

export const Currency = ({
  currency = 'USD',
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
  style = 'currency',
  value = '0',
}: ICurrency): JSX.Element => {
  return (
    <FormattedNumber
      {...modifyProps({
        currency,
        minimumFractionDigits,
        maximumFractionDigits,
        style,
        value: Number(value),
      })}
    />
  )
}
