import styled, { useTheme } from 'styled-components'

import { useFillColor, useSize } from './IconHooks'

const Svg = styled.svg<{ $iconAnimate?: boolean }>`
  & > path {
    transform-origin: center;
    animation: ${({ $iconAnimate }) =>
      $iconAnimate ? 'colorRotateIcon 0.8s infinite linear' : 'none'};
  }

  @keyframes colorRotateIcon {
    from {
      transform: rotate(90deg);
    }
    to {
      transform: rotate(-270deg);
    }
  }
`

export const Refresh = ({
  fill,
  fillType = 'black.50',
  size = 'small',
  height,
  width,
  title = 'Refresh',
  iconAnimate = false,
}: TiltifyIcon) => {
  const theme = useTheme()
  const sizeValues = useSize(size, height, width)
  const fillValue = useFillColor(theme, fillType, fill)
  return (
    <Svg
      width={sizeValues.width}
      height={sizeValues.height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      $iconAnimate={iconAnimate}
    >
      <title>{title}</title>
      <path
        d='M8.00016 1.33333C6.29109 1.33821 4.64916 1.99928 3.4135 3.18V2C3.4135 1.82319 3.34326 1.65362 3.21823 1.5286C3.09321 1.40357 2.92364 1.33333 2.74683 1.33333C2.57002 1.33333 2.40045 1.40357 2.27543 1.5286C2.1504 1.65362 2.08016 1.82319 2.08016 2V5C2.08016 5.17681 2.1504 5.34638 2.27543 5.47141C2.40045 5.59643 2.57002 5.66667 2.74683 5.66667H5.74683C5.92364 5.66667 6.09321 5.59643 6.21823 5.47141C6.34326 5.34638 6.4135 5.17681 6.4135 5C6.4135 4.82319 6.34326 4.65362 6.21823 4.5286C6.09321 4.40357 5.92364 4.33333 5.74683 4.33333H4.14683C5.00401 3.43757 6.1465 2.86844 7.37785 2.72378C8.60921 2.57912 9.85249 2.86798 10.8939 3.5407C11.9354 4.21341 12.7099 5.22794 13.0844 6.40986C13.4588 7.59178 13.4098 8.86723 12.9458 10.0169C12.4817 11.1666 11.6316 12.1187 10.5416 12.7095C9.4516 13.3003 8.18982 13.4929 6.9732 13.2542C5.75658 13.0155 4.66114 12.3604 3.87522 11.4015C3.08931 10.4426 2.66202 9.23981 2.66683 8C2.66683 7.82319 2.59659 7.65362 2.47157 7.5286C2.34654 7.40357 2.17697 7.33333 2.00016 7.33333C1.82335 7.33333 1.65378 7.40357 1.52876 7.5286C1.40373 7.65362 1.3335 7.82319 1.3335 8C1.3335 9.31854 1.72449 10.6075 2.45703 11.7038C3.18957 12.8001 4.23077 13.6546 5.44894 14.1592C6.66711 14.6638 8.00756 14.7958 9.30077 14.5386C10.594 14.2813 11.7819 13.6464 12.7142 12.714C13.6466 11.7817 14.2815 10.5938 14.5387 9.3006C14.796 8.0074 14.6639 6.66695 14.1594 5.44878C13.6548 4.2306 12.8003 3.18941 11.704 2.45687C10.6076 1.72433 9.31871 1.33333 8.00016 1.33333Z'
        fill={fillValue}
      />
    </Svg>
  )
}
