import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { Button } from '@tiltify/design/components/Button'
import { Divider } from '@tiltify/design/components/Divider'
import { Paragraph } from '@tiltify/design/components/Paragraph'

import { CodeInput } from './CodeInput'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  max-width: 60%;
  margin: 0 auto;
  font-weight: 500;

  a {
    color: ${({ theme }) => theme.colors.blue.core};
    text-decoration: underline;
    cursor: pointer;
  }
`

const CodeContainer = styled.div`
  & div[data-lastpass-icon-root] {
    display: none !important;
  }

  text-align: center;
`

const StyledDivider = styled(Divider)`
  margin-top: 1rem;
  margin-bottom: 24px;
`

interface CodeProps {
  setShowCode: (showCode: boolean) => void
  onSubmit: ({ code }: { code: string }) => void
  email: string
  submitting: boolean
  error: boolean
  handleResend: () => void
}

export const Code = ({
  setShowCode,
  onSubmit,
  email,
  submitting,
  error,
  handleResend,
}: CodeProps) => {
  const [disabled, setDisabled] = useState(true)
  const timeoutRef = useRef<NodeJS.Timeout>()

  useEffect(() => {
    if (disabled) {
      timeoutRef.current = setTimeout(() => {
        setDisabled(false)
      }, 60000)
    }

    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [disabled])

  return (
    <CodeContainer>
      <Wrapper>
        <Paragraph>
          We&apos;ve sent a confirmation code to: <strong>{email}</strong>
        </Paragraph>
        <Paragraph>
          Wrong email?
          <br /> <a onClick={() => setShowCode(false)}>Re-enter your address</a>
        </Paragraph>
      </Wrapper>
      <CodeInput onSubmit={onSubmit} disabled={submitting} error={error} />
      <StyledDivider />
      <Paragraph>Didn’t receive a code? Check your spam folder. </Paragraph>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 24 }}>
        <Button
          text='Resend'
          type='button'
          icon='refresh'
          size='small'
          disabled={disabled}
          onClick={() => {
            setDisabled(true)
            handleResend()
          }}
        />
      </div>
    </CodeContainer>
  )
}
