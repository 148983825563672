import styled from 'styled-components/macro'

import { Button } from '@tiltify/ui'
import { rem } from 'polished'

export const MaxWidthWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  box-sizing: border-box;
`

export const StyledButton = styled(Button)`
  padding: 1rem 2rem;
  border-radius: 4px;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 14px;
  text-transform: uppercase;
  span {
    font-weight: bold;
  }
`
export const BlackButton = styled(Button)`
  background: #000;
  border-radius: 100px;
  margin: auto;
  color: #fff;
  font-size: ${rem(14)};
  margin-top: 60px;
  padding: ${rem(16)} ${rem(24)};
  text-decoration: none;
  font-weight: bold;
`

export const Heading = styled.h2`
  font-weight: bold;
  font-size: ${rem(24)};
  line-height: 36px;
  letter-spacing: 1px;
  color: #000000;
  margin-bottom: 0.5rem;
  text-align: center;
  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    text-align: center;
    margin-bottom: 1rem;
  }
`

export const LightBlueButton = styled(StyledButton)`
  background: ${({ theme }) => theme.lightBlue};
  color: #fff;
  margin-right: ${rem(36)};
  text-decoration: none;

  @media (max-width: 552px) {
    margin-right: 0;
    margin-bottom: 1rem;
  }
`

export const RedButton = styled(StyledButton)`
  background: ${({ theme }) => theme.pink};
  border-color: ${({ theme }) => theme.pink};
  color: #fff;
  text-decoration: none;
  border-radius: 100px;
`

export const AccentBar = styled.div`
  background: ${({ theme }) => theme.pink};
  height: 6px;
  width: 71px;
  margin: 0 auto;
  margin-bottom: ${rem(34)};

  @media (max-width: 552px) {
    width: 10%;
  }
`
