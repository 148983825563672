import { ApolloError } from '@apollo/client'

interface IAuthenticationContext {
  authenticatedUser?: AuthenticatedUser
  loading: boolean
  error?: ApolloError | string
  showAuthentication?: boolean
  scope: UserScopeType
  shouldSetScope: boolean
}

export enum ACTION_TYPES {
  INIT_CONTEXT = 'INIT_CONTEXT',
  UPDATE_USER = 'UPDATE_USER',
  CREATE_USER = 'CREATE_USER',
  CLEAR_USER = 'CLEAR_USER',
  SHOW_AUTHENTICATION = 'SHOW_AUTHENTICATION',
  SET_USER_SCOPE = 'SET_USER_SCOPE',
  SHOULD_SET_USER_SCOPE = 'SHOULD_SET_USER_SCOPE',
}

interface INIT_CONTEXT {
  type: ACTION_TYPES.INIT_CONTEXT
  item: any
}

interface UPDATE_USER {
  type: ACTION_TYPES.UPDATE_USER
  item: Partial<AuthenticatedUser>
}

interface CREATE_USER {
  type: ACTION_TYPES.CREATE_USER
  item: AuthenticatedUser
}

interface CLEAR_USER {
  type: ACTION_TYPES.CLEAR_USER
}

interface SHOW_AUTHENTICATION {
  type: ACTION_TYPES.SHOW_AUTHENTICATION
  item: boolean
}

interface SET_USER_SCOPE {
  type: ACTION_TYPES.SET_USER_SCOPE
  item: UserScopeType
}

interface SHOULD_SET_USER_SCOPE {
  type: ACTION_TYPES.SHOULD_SET_USER_SCOPE
  item: boolean
}

export type Actions =
  | INIT_CONTEXT
  | UPDATE_USER
  | CREATE_USER
  | CLEAR_USER
  | SHOW_AUTHENTICATION
  | SET_USER_SCOPE
  | SHOULD_SET_USER_SCOPE

export const authenticationReducer = (state: IAuthenticationContext, action: Actions) => {
  switch (action.type) {
    case ACTION_TYPES.INIT_CONTEXT: {
      return { ...state, ...action.item }
    }
    case ACTION_TYPES.UPDATE_USER: {
      return { ...state, authenticatedUser: { ...state.authenticatedUser, ...action.item } }
    }
    case ACTION_TYPES.CLEAR_USER: {
      return { ...state, authenticatedUser: null }
    }
    case ACTION_TYPES.SHOW_AUTHENTICATION: {
      return { ...state, showAuthentication: action.item }
    }
    case ACTION_TYPES.SET_USER_SCOPE: {
      return { ...state, scope: action.item }
    }
    case ACTION_TYPES.SHOULD_SET_USER_SCOPE: {
      return { ...state, shouldSetScope: action.item }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}
